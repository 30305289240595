import { ToastContainer, Slide } from 'react-toastify'
import './ToastManager.module.scss'
import 'react-toastify/dist/ReactToastify.css'
import { FC } from 'react'

export const ToastManager: FC = () => (
  <ToastContainer
    autoClose={3000}
    closeOnClick={false}
    position="bottom-center"
    hideProgressBar
    theme="dark"
    transition={Slide}
  />
)
