import { FC, PropsWithChildren } from 'react'
import Navbar from 'common/core/page-fragments/Navbar/Navbar'
import WebBottomAd from 'common/core/ads/components/WebBottomAd/WebBottomAd'
import s from './BottomLayout.module.scss'

interface Props {
  withNavbar?: boolean
  allowWebBottomAd?: boolean
}

const BottomLayout: FC<PropsWithChildren<Props>> = ({
  children,
  withNavbar = true,
  allowWebBottomAd = true,
}) => (
  <>
    {children}
    <div className={s.bottomSection}>
      {withNavbar && <Navbar />}
      {allowWebBottomAd && <WebBottomAd />}
    </div>
  </>
)

export default BottomLayout
