interface StoredPayload {
  lastQuery: string | undefined
}

export default class SearchHistoryStore {
  constructor(private localStorageKey: string) {}

  public setLastQuery(query: string | undefined): void {
    const payload = SearchHistoryStore.convertQueryToPayload(query)
    localStorage.setItem(this.localStorageKey, JSON.stringify(payload))
  }

  public getLastQuery(): string | undefined {
    const dataStr = localStorage.getItem(this.localStorageKey)
    if (dataStr) {
      return SearchHistoryStore.convertPayloadToQuery(JSON.parse(dataStr))
    }
    return undefined
  }

  private static convertQueryToPayload(query: string | undefined) {
    return { lastQuery: query }
  }

  private static convertPayloadToQuery(
    payload: StoredPayload,
  ): string | undefined {
    return payload.lastQuery
  }
}
