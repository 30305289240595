export interface RawAppEntitlement {
  userId: string
  gplaySubsPurchase?: FsEntitlementGplaySubsPurchase
  gplayOtpPurchase?: FsEntitlementGplayOtpPurchase
}

export interface FsEntitlementGplaySubsPurchase {
  basePlanId: string
  endDate: string
  subscriptionId: string
  subscriptionState: SubscriptionState
  latestOrderId: string
  purchaseToken: string
}

export enum SubscriptionState {
  SUBSCRIPTION_STATE_UNSPECIFIED = 'SUBSCRIPTION_STATE_UNSPECIFIED',
  SUBSCRIPTION_STATE_PENDING = 'SUBSCRIPTION_STATE_PENDING',
  SUBSCRIPTION_STATE_ACTIVE = 'SUBSCRIPTION_STATE_ACTIVE',
  SUBSCRIPTION_STATE_PAUSED = 'SUBSCRIPTION_STATE_PAUSED',
  SUBSCRIPTION_STATE_IN_GRACE_PERIOD = 'SUBSCRIPTION_STATE_IN_GRACE_PERIOD',
  SUBSCRIPTION_STATE_ON_HOLD = 'SUBSCRIPTION_STATE_ON_HOLD',
  SUBSCRIPTION_STATE_CANCELED = 'SUBSCRIPTION_STATE_CANCELED',
  SUBSCRIPTION_STATE_EXPIRED = 'SUBSCRIPTION_STATE_EXPIRED',
  SUBSCRIPTION_STATE_PENDING_PURCHASE_CANCELED = 'SUBSCRIPTION_STATE_PENDING_PURCHASE_CANCELED',
}

export class EntitlementWrapper {
  public static create(doc: RawAppEntitlement | null) {
    return doc ? new EntitlementWrapper(doc) : null
  }

  constructor(private raw: RawAppEntitlement) {}

  public getGplaySubsPurchase() {
    return this.raw.gplaySubsPurchase
  }

  public getGplayOtpPurchase() {
    return this.raw.gplayOtpPurchase
  }
}

export interface FsEntitlementGplayOtpPurchase {
  productId: string
  purchaseState: OtpPurchaseState
  orderId: string | null
  purchaseToken: string
}

export enum OtpPurchaseState {
  Purchased = 0,
  Canceled = 1,
  Pending = 2,
}
