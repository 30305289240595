import { useQuery } from '@tanstack/react-query'
import { User } from 'firebase/auth'
import { QueryPersistenceConfig } from 'common/shared/react-query/models'
import { DIContainer } from 'common/shared/deps-injection/DepsContainer'
import { PremiumDepsToken } from '../deps'
import { EntitlementWrapper } from './models'

const MAIN_KEY = 'entitlement'

export const retrieveUserEntitlementQueryPersistenceConfig: QueryPersistenceConfig =
  {
    mainQueryKey: MAIN_KEY,
    shouldPersist: () => true,
  }

export function useRetrieveUserEntitlement(user: User) {
  const { firestoreEntitlementsClient } = DIContainer.get(PremiumDepsToken)

  return useQuery({
    queryKey: [MAIN_KEY, user.uid],
    queryFn: () => {
      return firestoreEntitlementsClient.getEntitlement(user)
    },
    select(data) {
      return EntitlementWrapper.create(data)
    },
    networkMode: 'online', // Could be 'offlineFirst' if we want to use a HTTP cache
    refetchOnWindowFocus: false, // Using false in order to decrease the number of requests
    refetchOnReconnect: false, // Using false in order to decrease the number of requests
    staleTime: 0, // Could be greater than 0 if we want to decrease the number of requests
    gcTime: 1000 * 60 * 60 * 24 * 7, // Should be equal or greater than persistence maxAge
  })
}
