export const commonMessagesInEnglish = {
  share_app_text: 'Check out "{appName}" app',

  home_intro_one: 'Select a stop to check',
  home_intro_two_train: 'when your train arrives there',
  home_intro_two_bus: 'when your bus arrives there',
  home_intro_two_bus_or_train: 'when your bus or train arrives there',
  home_stop_search_button_one: 'Search stop',
  home_stop_search_button_two: 'by number or name',
  home_map_search_button_one: 'Search stop',
  home_map_search_button_two: 'on the map',
  home_popular_stops: 'Popular stops',

  navbar_settings: 'Settings',
  settings_language: 'Language',

  ads_advertisement_title: 'Advertisement',
  settings_ads: 'Advertisement settings',
  ads_interstitial_ad_alert_title: 'An ad will soon appear',
  ads_interstitial_ad_alert_button: 'Watch now',
  ads_interstital_ad_alert_setting_title: 'Show ad alert',
  ads_interstital_ad_alert_setting_text:
    "<p>If you have this option enabled, we will show you a button to view the pending full-screen ad.</p><p>This way, you can watch the ad whenever you want and avoid it appearing automatically.</p><p>Please note that if you don't use the button to view the ad, it will appear automatically after 20 minutes.</p>",
  ads_interstital_ads_title: 'Full-Screen ads',
  common_hide: 'Hide',

  accept_button: 'OK',

  stops_favs_title: 'Saved stops',
  stops_no_favorites: `You have no saved stops.
    When viewing a stop, press the "Save" button
    so they appear here.`,
  stop_favs_error:
    'An error occurred while retrieving saved stops. Please, try again.',
  stops_favs_sort_start: 'Sort',
  stops_favs_sort_finish: 'Finish',

  chat_card_secondary: '¿Do you need help?',
  chat_card_primary: 'Write us by chat',

  contact_card_secondary: '¿Do you need help?',
  contact_card_primary: 'Contact us',

  share_app_modal_title: 'Share the app',
  share_modal_text_one: 'Send this link to your friends:',
  share_modal_whatsapp: 'Send by WhatsApp',
  share_modal_telegram: 'Send by Telegram',

  install_card_secondary_one: 'Our app is easier to use ',
  install_card_secondary_two: "and it's really lightweight",
  install_card_primary: 'Download for free',

  rate_app_card_secondary: 'Help us to improve',
  rate_app_card_primary: 'Rate the app',

  sync_reminder_secondary: 'Save your stops in the cloud',
  sync_reminder_primary: 'Sign in',

  action_bar_install_app: 'Install app',
  modal_close_btn: 'Close',

  no_internet_error: "It looks like you don't have an internet connection",

  search_tab_panel_by_text: 'By number, name or line',
  search_tab_panel_on_map: 'On the map',

  stop_search_action_bar_title: 'Search stop',
  stop_search_loading: 'Loading',
  stop_search_error: `An error occurred while retrieving the list of stops.
    Please, try again.`,
  stop_search_by_code: 'Search stop by code',
  search_found_stops_title: 'Found stops',
  search_found_lines_title: 'Found lines',
  line_search_show_more: 'Show {numberOfItems} more lines',
  search_show_more_stops: 'Show {numberOfItems} more stops',
  search_stops_not_found: 'No stops found with query "{searchQuery}"',
  search_stop_by_unknown_code: 'Go to {stopCode} stop',
  search_box_delete_text: 'Erase',

  recently_viewed_stops: 'Recently viewed stops',
  recently_viewed_stops_delete_all: 'Delete',

  stop_map_action_bar_title: 'Search stop',
  stop_map_loading: 'Loading',
  stop_map_error:
    'An error occurred while retrieving the map data. Please, try again.',
  map_search_places_field_placeholder: 'Find places on the map',
  stop_map_infowindow_lines: 'Lines: ',
  stop_map_infowindow_go_to_stop: 'Go to this stop',

  stop_action_bar_title: 'Stop',
  stop_action_bar_search_link_title: 'Search stop',
  stop_refresh_button: 'Refresh',
  stop_loading: 'Loading',
  stop_error:
    'An error occurred while getting the stop data. Please, try again.',
  stop_fav_arrivals_hint:
    'TIP: To make a line always appear on top, use its button "Pin line"',
  stop_error_arrivals_sources_btn: 'Why is there no data?',
  stop_zone: 'Zone',
  stop_has_wheelchair_boarding: 'Accessible',
  stop_actions_remove_fav: 'Saved',
  stop_actions_add_fav: 'Save',
  stop_actions_rename: 'Rename',
  stop_location: 'Stop location',
  stop_actions_show_on_map: 'Show stop on map',
  stop_actions_map_secondary: 'Find other stops nearby',
  stop_departure_direction: 'To',
  stop_departure_platform: 'Platform {platform}',
  stop_departure_distance: '{distance} m',
  stop_departure_no_data: 'currently no data',
  stop_departure_actions_fav_arrival_add: 'Pin line',
  stop_departure_actions_fav_arrival_remove: 'Unpin line',
  stop_departure_actions_go_to_line: 'Show line details',
  stop_departure_actions_show_on_map: 'Show line on the map',
  arrivals_sources_modal_title: 'How we obtain the data',
  got_it_button: 'Got it',
  stop_fav_modal_title: 'Save stop',
  stop_fav_modal_select_name: 'Enter an alias:',
  stop_fav_modal_cancel: 'Cancel',
  stop_fav_modal_save: 'Save',
  stop_fav_rename_modal_title: 'Rename stop',
  stop_fav_rename_modal_select_name: 'Enter a new alias:',
  stop_fav_rename_modal_cancel: 'Cancel',
  stop_fav_rename_modal_save: 'Save',
  stop_remove_fav_modal_title: 'Delete saved stop',
  stop_remove_fav_modal_text:
    'Are you sure to delete this stop from saved stops?',
  stop_remove_fav_cancel: 'Cancel',
  stop_remove_fav_ok: 'Delete',
  stop_share_title: 'Share this stop',
  stop_share_text: 'Check the arrivals at the stop "{stopName}"',
  stop_message_stop_saved: 'The stop has been saved',
  stop_message_stop_removed: 'The stop has been deleted',
  stop_message_stop_renamed: 'The stop has been renamed',

  line_search_action_bar_title: 'Lines',
  line_search_loading: 'Loading',
  line_search_error:
    'An error occurred while retrieving the list of lines. Please, try again.',
  line_search_field_placeholder: 'Search line by number or name',

  line_action_bar_title: 'Line',
  line_action_bar_search_link_title: 'Search line',
  line_loading: 'Loading',
  line_error:
    'An error occurred while getting the line data. Please, try again.',
  line_show_on_map_btn: 'Show line on the map',
  line_schedule_btn: 'Timetable to {destination}',
  line_stops_title: 'Line stops',
  line_schedule_modal_title: 'Timetable',
  line_tab: 'To {destination}',

  posters_action_bar_title: 'Pocket Maps',
  posters_available_offline: 'Available offline',
  posters_not_available_offline: 'Not available offline',

  settings_action_bar_title: 'Settings',
  settings_theme: 'Theme',
  settings_theme_use_system: 'Use system theme',
  settings_more_stuff: 'More stuff',
  secondary_actions_rate_app: 'Rate the app',
  secondary_actions_use_chat: 'Write us by chat',
  secondary_actions_share_app: 'Share the app',
  secondary_actions_contact: 'Contact us',
  settings_our_projects: 'Our other apps',
  settings_ui: 'User Interface',
  settings_privacy: 'Privacy',
  settings_consents: 'Consent of personal data',
  settings_show_popular_stops: 'Show popular stops',

  contact_modal_title: 'Contact us',
  contact_modal_text: 'Write to this email for any questions or suggestions:',

  retry: 'Retry',
  new: 'New',
  common_copy_clipboard: 'Copy to clipboard',
  common_copy_clipboard_success: 'Copied',
  exclusive_app_feature_modal_title: 'Only in our app',
  exclusive_app_feature_modal_text:
    'This feature is only available in our app. Download it for free now and enjoy this and many more benefits.',
  exclusive_app_feature_modal_install: 'Install app for free',

  auth_user_account: 'User account',
  auth_logged_out_intro:
    'Sign in to save your favorite stops in the cloud. This way you will not lose them if you change your device.',
  auth_login_with_google: 'Sign in with Google',
  auth_logged_in_with: 'Signed in user: ',
  auth_logged_in_description:
    'Your favorite stops are safely stored in the cloud.',
  auth_logout: 'Sign out',
  auth_logout_modal_title: 'Sign out',
  auth_logout_modal_text: 'Are you sure you want to sign out?',
  auth_logout_modal_accept: 'Sign out',
  auth_logout_modal_cancel: 'Cancel',
  auth_message_session_closed: 'Signed out successfully',
  auth_login_with_code: 'Log in with code',
  auth_error_no_id_token: 'An unexpected error has occurred. Please try again.',
  auth_token_presenter_title: 'Copy code',
  auth_token_presenter_text_1: 'Copy the following code to the clipboard:',
  auth_token_presenter_text_2:
    'Return to the app and go to the settings section and press the "Log in with code" button',
  auth_login_with_code_error:
    'An error occurred while logging in with the code. Please try again.',
  auth_login_with_code_paste: 'Paste the code into the text field:',
  auth_login_with_code_submit: 'Log in',

  sync_title: 'Sync favorites',
  sync_intro:
    'You have stops saved both on your user account and locally on your device. What do you want to do with the stops saved on your device?',
  sync_btn_upload_local_stop_favs:
    'Add the stops from the device to my user account',
  sync_btn_delete_local_stop_favs:
    'Remove the stops from the device and use only those from my user account',
}
