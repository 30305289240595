import { FC, PropsWithChildren, StrictMode } from 'react'
import { BrandConfig } from 'common/core/brand-config/brand-config.models'
import { TranslationMessagesGroups } from 'common/core/i18n/models/trans-manager-models'
import { IntlShape } from 'react-intl'
import I18nModal from 'common/core/i18n/components/I18nModal/I18nModal'
import { PremiumManager } from 'common/core/premium/premium-manager/PremiumManager'
import { AdsSystem } from 'common/core/ads/ads-system/AdsSystem'
import { BrandConfigContext } from 'common/core/brand-config/useBrandConfigContext'
import AppThemeManager from 'common/core/theme/AppThemeManager'
import AppUpgradeProvider from 'common/core/app-upgrade/AppUpgradeProvider'
import TransManager from 'common/core/i18n/components/TransManager'
import { UiPrefsManager } from 'common/core/ui-prefs/UiPrefsManager'
import { ContactModalProvider } from 'common/core/page-fragments/contact-modal/ContactModalProvider'
import { RouteObject } from 'react-router-dom'
import { AuthenticatedRoutesManager } from '../AuthenticatedRoutesManager/AuthenticatedRoutesManager'
import AuthStarter from '../../core/auth/components/AuthStarter/AuthStarter'
import { ToastManager } from '../ToastManager/ToastManager'
import { AppReactQueryProvider } from '../AppReactQueryProvider'

interface Props {
  brandConfig: BrandConfig
  translationGroups: TranslationMessagesGroups
  allowToShowLocaleSelectionModal: boolean
  getAuthenticatedRoutesConfig: (intl: IntlShape) => RouteObject[]
  AddtionalWrapperComponent: FC<PropsWithChildren> | undefined
}

const App: FC<Props> = ({
  brandConfig,
  translationGroups,
  allowToShowLocaleSelectionModal,
  getAuthenticatedRoutesConfig,
  AddtionalWrapperComponent,
}) => {
  return (
    <StrictMode>
      <BrandConfigContext.Provider value={brandConfig}>
        <AppReactQueryProvider>
          <UiPrefsManager>
            <AppThemeManager>
              <TransManager
                translationGroups={translationGroups}
                allowToShowLocaleSelectionModal={
                  allowToShowLocaleSelectionModal
                }
              >
                <AppUpgradeProvider>
                  <ContactModalProvider>
                    <I18nModal />
                    <AuthStarter>
                      <PremiumManager>
                        <AdsSystem>
                          <AuthenticatedRoutesManager
                            getAuthenticatedRoutesConfig={
                              getAuthenticatedRoutesConfig
                            }
                            AddtionalWrapperComponent={
                              AddtionalWrapperComponent
                            }
                          />
                        </AdsSystem>
                      </PremiumManager>
                    </AuthStarter>
                  </ContactModalProvider>
                  <ToastManager />
                </AppUpgradeProvider>
                <I18nModal />
              </TransManager>
            </AppThemeManager>
          </UiPrefsManager>
        </AppReactQueryProvider>
      </BrandConfigContext.Provider>
    </StrictMode>
  )
}

export default App
