import {
  LineTheme,
  LineThemeBuilder,
  LineThemeParams,
} from 'common/domain/lines/models/line-style-models'
import { TransportTypeNumCode } from 'common-mad/stops/models/stop-models'

const cercaniasThemes: { [lineNumber: string]: LineTheme } = {
  C1: {
    primary: '#5fb2dc',
  },
  C2: {
    primary: '#008a22',
  },
  C3: {
    primary: '#8a009a',
  },
  C3A: {
    primary: '#da6bab',
  },
  C4: {
    primary: '#002597',
  },
  C4A: {
    primary: '#002597',
  },
  C4B: {
    primary: '#002597',
  },
  C5: {
    primary: '#f3b800',
    secondary: 'rgba(0,0,0,0.78)',
  },
  C7: {
    primary: '#e92d26',
  },
  C8: {
    primary: '#6c6c6c',
  },
  C9: {
    primary: '#fa5b00',
  },
  C10: {
    primary: '#85b310',
  },
}

const metroThemes: { [lineNumber: string]: LineTheme } = {
  1: {
    primary: '#31a8de',
  },
  2: {
    primary: '#e61712',
  },
  3: {
    primary: '#fee000',
    secondary: '#044b99',
  },
  4: {
    primary: '#96572e',
  },
  5: {
    primary: '#74b34a',
  },
  6: {
    primary: '#707e81',
  },
  7: {
    primary: '#ed9530',
  },
  8: {
    primary: '#d271a7',
  },
  9: {
    primary: '#ae328b',
  },
  10: {
    primary: '#004ea8',
  },
  11: {
    primary: '#179454',
  },
  12: {
    primary: '#919008',
  },
  R: {
    primary: '#00498c',
    secondary: 'white',
    outlined: true,
  },
}

const lightMetroThemes: { [lineNumber: string]: LineTheme } = {
  ML1: {
    primary: '#4885bd',
    outlined: true,
  },
  ML2: {
    primary: '#943384',
    outlined: true,
  },
  ML3: {
    primary: '#ec3d4f',
    outlined: true,
  },
  ML4: {
    primary: '#74bf49',
    outlined: true,
  },
}

const busThemes: { [key: string]: LineTheme } = {
  interurban: {
    primary: '#66b215',
  },
  interurbanNocturnal: {
    primary: '#4d4d4d',
    secondary: '#a7ef90',
  },
  urban: {
    primary: '#ff2828',
  },
  urbanNocturnal: {
    primary: '#4d4d4d',
    secondary: '#ffb2b2',
  },
  emt: {
    primary: '#388ce0',
  },
  emtNocturnal: {
    primary: '#4d4d4d',
    secondary: '#ffe41c',
  },
}

const defaultTheme: LineTheme = {
  primary: 'gray',
}

const buildEmtBusLineTheme = ({ isNocturnal }: LineThemeParams) =>
  isNocturnal ? busThemes.emtNocturnal : busThemes.emt

const buildInterurbanBusLineTheme = ({ isNocturnal }: LineThemeParams) =>
  isNocturnal ? busThemes.interurbanNocturnal : busThemes.interurban

const buildUrbanBusLineTheme = ({ isNocturnal }: LineThemeParams) =>
  isNocturnal ? busThemes.urbanNocturnal : busThemes.urban

const buildMetroLineTheme = ({ lineNumber }: LineThemeParams) => {
  const foundTheme = metroThemes[lineNumber]
  return foundTheme || defaultTheme
}

const buildLightMetroLineTheme = ({ lineNumber }: LineThemeParams) => {
  const foundTheme = lightMetroThemes[lineNumber]
  return foundTheme || defaultTheme
}

const buildCercaniasLineTheme = ({ lineNumber }: LineThemeParams) => {
  const foundTheme = cercaniasThemes[lineNumber]
  return foundTheme || defaultTheme
}

const buildThemeFnMapping: {
  [type in TransportTypeNumCode]: LineThemeBuilder
} = {
  [TransportTypeNumCode.EMT]: buildEmtBusLineTheme,
  [TransportTypeNumCode.INTERURBAN]: buildInterurbanBusLineTheme,
  [TransportTypeNumCode.URBAN]: buildUrbanBusLineTheme,
  [TransportTypeNumCode.METRO]: buildMetroLineTheme,
  [TransportTypeNumCode.LIGHT_METRO]: buildLightMetroLineTheme,
  [TransportTypeNumCode.CERCANIAS]: buildCercaniasLineTheme,
}

export const buildLineTheme: LineThemeBuilder = (params) => {
  const foundBuilder =
    buildThemeFnMapping[params.transportTypeCode as TransportTypeNumCode]
  if (foundBuilder) {
    return foundBuilder(params)
  }
  return defaultTheme
}
