import { createContext, useContext } from 'react'
import { AppThemeContextModel } from './app-theme-models'

function createAppThemeContext() {
  const Context = createContext<AppThemeContextModel | null>(null)
  Context.displayName = 'AppThemeContext'
  return Context
}

export const AppThemeContext = createAppThemeContext()

export default function useAppThemeContext() {
  const context = useContext(AppThemeContext)
  if (!context) {
    throw new Error('AppThemeContext is not provided.')
  }
  return context
}
