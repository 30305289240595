import axios from 'axios'
import { LineListItem } from 'common/domain/lines/models/line-list-models'

export class LineListApiClient {
  constructor(
    private apiGateway: string,
    private allowedTransportTypeCodes?: string[],
  ) {}

  public async getLineList(): Promise<LineListItem[]> {
    const url = this.apiGateway
    const response = await axios.get<LineListItem[]>(url, {
      timeout: 15 * 1000,
    })
    // eslint-disable-next-line prefer-destructuring
    const allowedTransportTypeCodes = this.allowedTransportTypeCodes
    if (allowedTransportTypeCodes) {
      return response.data.filter((item) => {
        const stopTypeNumCode = item.transportTypeCode
        return allowedTransportTypeCodes.includes(stopTypeNumCode)
      })
    }
    return response.data
  }
}
