export enum ClientType {
  Web = 'web',
  Android = 'android',
  IOS = 'ios',
}

export interface ParamAppConfig {
  clientType: ClientType
  enableGA: boolean
  androidClientVersionCode: number | undefined
  iosClientVersion: string | undefined
  googlePlayReferrerString: string | undefined
}

export function isClientWithNfcSupport(appParamConfig: ParamAppConfig) {
  return (
    appParamConfig.clientType === ClientType.Android &&
    appParamConfig.androidClientVersionCode &&
    appParamConfig.androidClientVersionCode >= 27
  )
}

export class ClientAppInfo {
  constructor(private paramAppConfig: ParamAppConfig) {}

  public getClientType() {
    return this.paramAppConfig.clientType
  }

  public areInterstitialAdsSupported() {
    return this.paramAppConfig.clientType !== ClientType.Web
  }

  public isWebClient() {
    return this.paramAppConfig.clientType === ClientType.Web
  }

  public isPremiumPurchaseSupported() {
    return (
      this.areGplayBillingSubsSupported() && this.areGplayBillingOtpSupported()
    )
  }

  public areGplayBillingSubsSupported() {
    const { clientType, androidClientVersionCode } = this.paramAppConfig
    return (
      clientType === ClientType.Android &&
      androidClientVersionCode &&
      androidClientVersionCode >= 29
    )
  }

  public areGplayBillingOtpSupported() {
    const { clientType, androidClientVersionCode } = this.paramAppConfig
    return (
      clientType === ClientType.Android &&
      androidClientVersionCode &&
      androidClientVersionCode >= 30
    )
  }
}
