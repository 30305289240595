import { FC, PropsWithChildren, useState } from 'react'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { ClientType } from 'common/core/param-config/param-app-config-models'
import { getProjectById } from 'common/core/projects/projects-data'
import { isAndroid, isAndroidOrIos, isIos } from 'common/shared/user-agent'
import ExclusiveAppFeatureModal from './ExclusiveAppFeatureModal'
import { AppUpgradeContext } from './useAppUpgradeContext'
import { RequestAppOnlyFeatureType } from './models'

const AppUpgradeProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    common: {
      projectId,
      services: { paramAppConfig, ga4Service },
    },
  } = useBrandConfigContext()

  const [exclusiveAppFeatureModalOpen, setExclusiveAppFeatureModalOpen] =
    useState(false)
  const [featureId, setFeatureId] = useState<string>()

  function getAppUrlByUserAgent(utmSource: string) {
    const project = getProjectById(projectId)
    if (isAndroid()) {
      return project.androidPlayStoreUrl(utmSource)
    }
    if (isIos()) {
      return project.iosStoreUrl
    }
    return project.universalUrl
  }

  function redirectToAppStore(place: string) {
    ga4Service.sendEvent('installAppLinkClick', { place })
    window.open(getAppUrlByUserAgent('otLanding'), '_blank')
  }

  function canUpgradeToApp() {
    return paramAppConfig.clientType === ClientType.Web && isAndroidOrIos()
  }

  const requestAppOnlyFeature: RequestAppOnlyFeatureType = (
    requestedFeatureId,
    sucessCallback,
  ) => {
    if (canUpgradeToApp()) {
      setFeatureId(requestedFeatureId)
      setExclusiveAppFeatureModalOpen(true)
    } else {
      sucessCallback()
    }
  }

  function handleModalWindowInstallClick() {
    redirectToAppStore(`exclusiveAppFeatureModal:${featureId}`)
  }

  function handleModalWindowCloseClick() {
    ga4Service.sendEvent('exclusiveAppFeatureModal:close', { place: featureId })
    setExclusiveAppFeatureModalOpen(false)
    setFeatureId(undefined)
  }

  return (
    <AppUpgradeContext.Provider
      value={{
        requestAppOnlyFeature,
        canUpgradeToApp,
        redirectToAppStore,
        getAppUrlByUserAgent,
      }}
    >
      {children}
      <ExclusiveAppFeatureModal
        isOpen={exclusiveAppFeatureModalOpen}
        onRequestClose={handleModalWindowCloseClick}
        onInstallClick={handleModalWindowInstallClick}
      />
    </AppUpgradeContext.Provider>
  )
}

export default AppUpgradeProvider
