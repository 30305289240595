/* eslint-disable max-classes-per-file */

export class DIToken<T> {
  constructor(
    public readonly name: string,
    // Declared only to avoid TS 'type never used' error. Don't use it!
    private readonly dontUseThisParam?: T,
  ) {}
}

export class DIContainer {
  private static deps: Map<string, unknown> = new Map()

  public static set<T>(token: DIToken<T>, value: T) {
    if (DIContainer.deps.get(token.name)) {
      throw new Error(`DI value for '${token.name}' token is already set`)
    }
    DIContainer.deps.set(token.name, value)
  }

  public static get<T>(token: DIToken<T>) {
    const value = DIContainer.deps.get(token.name) as T
    if (!value) {
      throw new Error(`No DI value found for '${token.name}' token`)
    }
    return value
  }
}
