import { Stop } from 'common/domain/stops/models/stop-models'
import { FormattedMessage } from 'react-intl'
import { ParamAppConfig } from 'common/core/param-config/param-app-config-models'
import { BuildtimeConfig } from 'common/core/buildtime-config/buildtime-config-models'
import { stopMapIcons } from 'common-mad/stop-map-config'
import MadStopIcon from 'common-mad/stops/components/MadStopIcon/MadStopIcon'
import { PageId } from 'common-mad/core/models/app-models'
import {
  StopTypeNumCode,
  TransportTypeNumCode,
} from 'common-mad/stops/models/stop-models'
import MadStopCodeWrapperFactory from 'common-mad/stops/services/MadStopCodeWrapperFactory'
import MadBigStopIcon from 'common-mad/stops/components/MadBigStopIcon'
import RelatedContent from 'common-mad/stops/components/RelatedContent/RelatedContent'
import MadArrivalsSourcesModalContent from 'common-mad/stops/components/MadArrivalsSourcesModalContent/MadArrivalsSourcesModalContent'
import StopLoadingContent from 'common-mad/stops/components/StopLoadingContent/StopLoadingContent'
import { buildLineTheme } from 'common-mad/lines/helpers/line-theme'
import UserFirestoreClientWithCache from 'common/core/firebase/user-firestore-client-with-cache/UserFirestoreClientWithCache'
import {
  StopServicesInitConfig,
  StopServicesBuilder,
} from 'common/domain/stops/deps/stop-services-builder'
import { StopDeps } from 'common/domain/stops/deps/stop-deps'
import { StopsRouteManager } from 'common/core/router/StopsRouteManager'
import { ReactNode } from 'react'

const searchStopTypeCodesOrder: StopTypeNumCode[] = [
  StopTypeNumCode.CERCANIAS,
  StopTypeNumCode.METRO,
  StopTypeNumCode.LIGHT_METRO,
  StopTypeNumCode.INTERURBAN,
  StopTypeNumCode.EMT,
]

const stopTypeCodesThatShouldGenerateFakeSearchableLines: StopTypeNumCode[] = []

const transportTypeCodesWithRealLines: TransportTypeNumCode[] = [
  TransportTypeNumCode.INTERURBAN,
  TransportTypeNumCode.URBAN,
  TransportTypeNumCode.EMT,
  TransportTypeNumCode.LIGHT_METRO,
  TransportTypeNumCode.METRO,
  TransportTypeNumCode.CERCANIAS,
]

function getTransportTypeCodeFromStopTypeCode(stopTypeCode: string) {
  const map: { [key in StopTypeNumCode]: TransportTypeNumCode } = {
    [StopTypeNumCode.CERCANIAS]: TransportTypeNumCode.CERCANIAS,
    [StopTypeNumCode.METRO]: TransportTypeNumCode.METRO,
    [StopTypeNumCode.LIGHT_METRO]: TransportTypeNumCode.LIGHT_METRO,
    [StopTypeNumCode.EMT]: TransportTypeNumCode.EMT,
    [StopTypeNumCode.INTERURBAN]: TransportTypeNumCode.INTERURBAN,
  }
  return map[stopTypeCode as StopTypeNumCode] || TransportTypeNumCode.INTERURBAN
}

const transportTypeCodeToStopTypeCode: {
  [key in TransportTypeNumCode]: StopTypeNumCode
} = {
  [TransportTypeNumCode.METRO]: StopTypeNumCode.METRO,
  [TransportTypeNumCode.LIGHT_METRO]: StopTypeNumCode.LIGHT_METRO,
  [TransportTypeNumCode.CERCANIAS]: StopTypeNumCode.CERCANIAS,
  [TransportTypeNumCode.EMT]: StopTypeNumCode.EMT,
  [TransportTypeNumCode.INTERURBAN]: StopTypeNumCode.INTERURBAN,
  [TransportTypeNumCode.URBAN]: StopTypeNumCode.INTERURBAN,
}

function getStopTypeCodeFromTransportTypeCode(
  transportTypeCode: string,
): StopTypeNumCode {
  return transportTypeCodeToStopTypeCode[
    transportTypeCode as TransportTypeNumCode
  ]
}

const trainStopTypes = [
  StopTypeNumCode.CERCANIAS,
  StopTypeNumCode.METRO,
  StopTypeNumCode.LIGHT_METRO,
]

const busStopTypes = [StopTypeNumCode.EMT, StopTypeNumCode.INTERURBAN]

function isBusStopType(stopTypeNumCode: string) {
  return busStopTypes.includes(stopTypeNumCode as StopTypeNumCode)
}

function getArrrivalsTitleText(stop: Stop) {
  if (trainStopTypes.includes(stop.stopTypeCode as StopTypeNumCode)) {
    return <FormattedMessage id="next_arrivals_title_trains" />
  }
  return <FormattedMessage id="next_arrivals_title_buses" />
}

const stopTypeCodesWithoutFavDeparturesAlert: StopTypeNumCode[] = [
  StopTypeNumCode.CERCANIAS,
]

export interface MadStopDepsInput {
  buildtimeConfig: BuildtimeConfig
  appParamConfig: ParamAppConfig
  allowedStopTypeCodes: StopServicesInitConfig['allowedStopTypeCodes']
  allowedTransportTypeCodes: StopServicesInitConfig['allowedTransportTypeCodes']
  localStoragePrefix: string
  stopListFileUrl: string
  lineListFileUrl: string
  popularStops: StopDeps['helpers']['popularStops']
  stopSeacrhFieldPlaceholder: StopDeps['helpers']['stopSeacrhFieldPlaceholder']
  composeSelectStopSecondaryText: StopDeps['helpers']['composeSelectStopSecondaryText']
  userFirestoreClientWithCache: UserFirestoreClientWithCache
}

export function buildMadStopDeps(input: MadStopDepsInput): StopDeps {
  const services = new StopServicesBuilder({
    localStoragePrefix: input.localStoragePrefix,
    apiGatewayUrl: 'https://api.oktransportemadrid.com',
    stopListFileUrl: input.stopListFileUrl,
    lineListFileUrl: input.lineListFileUrl,
    allowedStopTypeCodes: input.allowedStopTypeCodes,
    allowedTransportTypeCodes: input.allowedTransportTypeCodes,
    stopTypeCodesThatShouldGenerateFakeSearchableLines,
    searchStopTypeCodesOrder,
    stopCodeWrapperFactory: new MadStopCodeWrapperFactory(),
    userFirestoreClientWithCache: input.userFirestoreClientWithCache,
  }).buildServices()
  return {
    urls: {
      getStopUrl: StopsRouteManager.getStopUrl,
      getStopSearchUrl: StopsRouteManager.getStopsSearchUrl,
      getStopsMapUrl: StopsRouteManager.getStopsMapUrl,
      getLinesUrl: StopsRouteManager.getLinesUrl,
      getLineUrl: StopsRouteManager.getLineUrl,
    },
    services,
    helpers: {
      popularStops: input.popularStops,
      getStopLinesDescriptionInSearch: (stopTypeNumCode: string) => {
        if (
          stopTypeNumCode === StopTypeNumCode.INTERURBAN ||
          stopTypeNumCode === StopTypeNumCode.EMT
        ) {
          return <FormattedMessage id="search_stop_lines_desc_bus" />
        }
        return <FormattedMessage id="search_stop_lines_desc_other" />
      },
      stopTypeCodesWithoutFavDeparturesAlert,
      getArrrivalsTitleText,
      composeLineDescriptionInSearchResults: (
        lineNumber: string,
        transportTypeCode: string,
      ) => {
        type LineDescriptionMap = {
          [key in TransportTypeNumCode]: (line: string) => ReactNode
        }
        const lineDescriptionMap: LineDescriptionMap = {
          [TransportTypeNumCode.CERCANIAS]: (lineNum) => (
            <FormattedMessage
              id="found_line_description_cercanias"
              values={{ lineNumber: lineNum }}
            />
          ),
          [TransportTypeNumCode.METRO]: (lineNum) => (
            <FormattedMessage
              id="found_line_description_metro"
              values={{ lineNumber: lineNum }}
            />
          ),
          [TransportTypeNumCode.LIGHT_METRO]: (lineNum) => (
            <FormattedMessage
              id="found_line_description_light_metro"
              values={{ lineNumber: lineNum }}
            />
          ),
          [TransportTypeNumCode.EMT]: (lineNum) => (
            <FormattedMessage
              id="found_line_description_emt"
              values={{ lineNumber: lineNum }}
            />
          ),
          [TransportTypeNumCode.INTERURBAN]: (lineNum) => (
            <FormattedMessage
              id="found_line_description_interurban"
              values={{ lineNumber: lineNum }}
            />
          ),
          [TransportTypeNumCode.URBAN]: (lineNum) => (
            <FormattedMessage
              id="found_line_description_urban"
              values={{ lineNumber: lineNum }}
            />
          ),
        }
        const messageFn =
          lineDescriptionMap[transportTypeCode as TransportTypeNumCode]
        return messageFn ? (
          messageFn(lineNumber)
        ) : (
          <FormattedMessage
            id="found_line_description_other"
            values={{ lineNumber }}
          />
        )
      },
      composeLineDescription: (transportTypeCode: string) => {
        const lineDescriptionMap: {
          [key in TransportTypeNumCode]: () => ReactNode
        } = {
          [TransportTypeNumCode.CERCANIAS]: () => (
            <FormattedMessage id="line_description_cercanias" />
          ),
          [TransportTypeNumCode.METRO]: () => (
            <FormattedMessage id="line_description_metro" />
          ),
          [TransportTypeNumCode.LIGHT_METRO]: () => (
            <FormattedMessage id="line_description_light_metro" />
          ),
          [TransportTypeNumCode.EMT]: () => (
            <FormattedMessage id="line_description_emt" />
          ),
          [TransportTypeNumCode.INTERURBAN]: () => (
            <FormattedMessage id="line_description_interurban" />
          ),
          [TransportTypeNumCode.URBAN]: () => (
            <FormattedMessage id="line_description_urban" />
          ),
        }
        const messageFn =
          lineDescriptionMap[transportTypeCode as TransportTypeNumCode]
        return messageFn ? (
          messageFn()
        ) : (
          <FormattedMessage id="line_description_other" />
        )
      },
      getTransportTypeCodeFromStopTypeCode,
      getStopTypeCodeFromTransportTypeCode,
      getFakeSearchableLineTransportTypeCode: (line) => {
        if (line.stopTypeNumCode === StopTypeNumCode.INTERURBAN) {
          const parsedNumber = parseInt(line.lineNumber, 10)
          if (Number.isInteger(parsedNumber) && parsedNumber < 30) {
            return TransportTypeNumCode.URBAN
          }
          return TransportTypeNumCode.INTERURBAN
        }
        return getTransportTypeCodeFromStopTypeCode(line.stopTypeNumCode)
      },
      transportTypeCodesWithRealLines,
      stopSeacrhFieldPlaceholder: input.stopSeacrhFieldPlaceholder,
      stopMapIcons,
      composeStopDescription: (stopTypeCode: string) => {
        const stopDescriptions: {
          [stopTypeCode in StopTypeNumCode]: ReactNode
        } = {
          [StopTypeNumCode.INTERURBAN]: (
            <FormattedMessage id="stop_description_interurban_bus" />
          ),
          [StopTypeNumCode.EMT]: (
            <FormattedMessage id="stop_description_emt_bus" />
          ),
          [StopTypeNumCode.CERCANIAS]: (
            <FormattedMessage id="stop_description_cercanias" />
          ),
          [StopTypeNumCode.METRO]: (
            <FormattedMessage id="stop_description_metro" />
          ),
          [StopTypeNumCode.LIGHT_METRO]: (
            <FormattedMessage id="stop_description_light_metro" />
          ),
        }
        return (
          stopDescriptions[stopTypeCode as StopTypeNumCode] || (
            <FormattedMessage id="stop_description_other" />
          )
        )
      },
      defaultMapCenter: {
        lat: 40.4191184,
        lng: -3.7078691,
      },
      autocompleteMapBounds: {
        south: 39.14329908173079,
        west: -5.340771157699448,
        north: 41.62820159274022,
        east: -2.0558590483244488,
      },
      shouldShowRateAppButtonOnStopPage: (stop) =>
        stop.stopTypeCode !== StopTypeNumCode.INTERURBAN,
      buildLineTheme,
      isBusStopType,
      composeSelectStopSecondaryText: input.composeSelectStopSecondaryText,
      allowRateDialogAfterAddingStopFav(stop) {
        return stop.stopTypeCode !== StopTypeNumCode.INTERURBAN
      },
    },
    pageIds: {
      home: PageId.HomePage,
      stopSearch: PageId.SearchPage,
      stopMap: PageId.StopMapPage,
      stop: PageId.StopPage,
      lines: PageId.LinesPage,
      line: PageId.LinePage,
    },
    components: {
      stopIcon: MadStopIcon,
      bigStopIcon: MadBigStopIcon,
      stopRelatedContent: RelatedContent,
      arrivalsSourcesModalContent: MadArrivalsSourcesModalContent,
      stopLoadingContent: StopLoadingContent,
    },
  }
}
