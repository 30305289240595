import { Navigate, RouteObject } from 'react-router-dom'
import { IntlShape } from 'react-intl'
import { stopRoutesConfig } from 'common/bootstrap/stop-routes'
import { coreRoutesConfig } from 'common/bootstrap/core-routes'
import { CoreRouteManager } from 'common/core/router/CoreRouteManager'
import { StopsRouteManager } from 'common/core/router/StopsRouteManager'
import { getMadInfoRoutesConfig } from './mad-info-routes'
import { AppRouteManager } from '../services/route-manager/AppRouteManager'

export const getAppRoutesConfig = (intl: IntlShape) =>
  [
    {
      path: AppRouteManager.getAppDashboardUrl(),
      element: <Navigate to={StopsRouteManager.getStopsUrl()} replace />,
    },
    {
      path: AppRouteManager.getRootUrl(),
      element: <Navigate to={StopsRouteManager.getStopsUrl()} replace />,
    },
    {
      path: AppRouteManager.getNfcTransportCardUrl(),
      async lazy() {
        const { NfcTranportCardPage } = await import(
          'common/domain/nfc-transport-card/components/NfcTranportCardPage/NfcTranportCardPage'
        )
        return { Component: NfcTranportCardPage }
      },
    },
    {
      path: AppRouteManager.getCardUrl(),
      async lazy() {
        const { CardPage } = await import(
          'common/domain/mad-card-by-code/CardPage/CardPage'
        )
        return { Component: CardPage }
      },
    },
    {
      path: '/chamartin',
      element: (
        <Navigate
          to={CoreRouteManager.getAppUniversalPageUrl('chamartin')}
          replace
        />
      ),
    },
    ...getMadInfoRoutesConfig(intl),
    ...stopRoutesConfig,
    ...coreRoutesConfig,
  ] as RouteObject[]
