import { FC, PropsWithChildren, useMemo } from 'react'
import { ServerTimeManager } from 'common/core/server-time/models'
import { PremiumManagerContext } from './context'
import { EntitlementWrapper } from '../firestore-entitlements-client/models'
import { PremiumManagerContextModel } from './models'
import { EntitlementCalculator } from '../entitlement-calculator'

interface Props {
  entitlement: EntitlementWrapper | null
  onPremiumPurchase: () => void
  serverTime: ServerTimeManager
}

export const EnabledPremiumContextProvider: FC<PropsWithChildren<Props>> = ({
  children,
  entitlement,
  onPremiumPurchase,
  serverTime,
}) => {
  const entitlementCalculator = useMemo(() => {
    return entitlement
      ? new EntitlementCalculator(entitlement, serverTime)
      : null
  }, [entitlement, serverTime])

  const contextValue = useMemo<PremiumManagerContextModel>(() => {
    return {
      enabled: true,
      entitlement,
      serverTime,
      entitlementCalculator,
      handlePremiumPurchase: onPremiumPurchase,
    }
  }, [entitlement, serverTime, entitlementCalculator, onPremiumPurchase])

  return (
    <PremiumManagerContext.Provider value={contextValue}>
      {children}
    </PremiumManagerContext.Provider>
  )
}
