import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ElementType,
  forwardRef,
  PropsWithChildren,
  useRef,
} from 'react'
import classNames from 'classnames'
import s from './InstantlyActive.module.scss'

type AsProp<C extends ElementType> = {
  as?: C
}

type PropsToOmit<C extends ElementType, P> = keyof (AsProp<C> & P)

type PolymorphicComponentProp<
  C extends ElementType,
  Props = {},
> = PropsWithChildren<Props & AsProp<C>> &
  Omit<ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>

type PolymorphicComponentPropWithRef<
  C extends ElementType,
  Props = {},
> = PolymorphicComponentProp<C, Props> & { ref?: PolymorphicRef<C> }

type PolymorphicRef<C extends ElementType> = ComponentPropsWithRef<C>['ref']

type InstantlyActiveProps<C extends ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      highContrastActive?: boolean
    }
  >

export const InstantlyActive = forwardRef(
  <C extends ElementType = 'span'>(
    {
      as,
      highContrastActive,
      className,
      children,
      ...newProps
    }: InstantlyActiveProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const Component = as || 'span'

    const coverElRef = useRef<HTMLDivElement>(null)

    function composeCoverClassNames(isActive: boolean) {
      return classNames({
        [s.cover]: true,
        [s.active]: isActive,
        [s.highContrastActive]: isActive && highContrastActive,
      })
    }

    function changeCoverActiveClassName(isActive: boolean) {
      if (coverElRef.current) {
        coverElRef.current.className = composeCoverClassNames(isActive)
      }
    }

    function makeActive() {
      changeCoverActiveClassName(true)
      setTimeout(() => {
        changeCoverActiveClassName(false)
      }, 0)
    }

    return (
      <>
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...newProps}
          className={classNames({
            [className || '']: true,
            [s.root]: true,
          })}
          // onMouseDown={makeActive}
          // onTouchStartCapture={makeActive}
          onPointerDown={makeActive}
          // onMouseUp={makeInactive}
          // onTouchEndCapture={makeInactive}
          // onTouchCancelCapture={makeInactive}
          ref={ref}
        >
          <span className={s.cover} ref={coverElRef} />
          {children}
        </Component>
      </>
    )
  },
)
