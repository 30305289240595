export enum StopListItemIndex {
  StopCode = 0,
  StopName = 1,
  Lat = 2,
  Long = 3,
  LineNumbers = 4,
  IsNocturnal = 5,
  StopTypeNumCode = 6,
}

export type StopListItem = [
  string,
  string,
  number,
  number,
  string[],
  0 | 1,
  number,
]
