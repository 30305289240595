import { createRoot } from 'react-dom/client'
import ReactModal from 'react-modal'
import AppParamConfigCreator from 'common/core/param-config/app-param-config-creator/AppParamConfigCreator'
import {
  ClientType,
  ParamAppConfig,
} from 'common/core/param-config/param-app-config-models'
import 'common/core/styles/index.module.scss'
import { BrandConfig } from 'common/core/brand-config/brand-config.models'
import AppParamConfigStore from 'common/core/param-config/app-param-config-store/AppParamConfigStore'
import BuildtimeConfigBuilder from 'common/core/buildtime-config/BuildtimeConfigBuilder'
import { TranslationMessagesGroups } from 'common/core/i18n/models/trans-manager-models'
import { BuildtimeConfig } from 'common/core/buildtime-config/buildtime-config-models'
import { IntlShape } from 'react-intl'
import { AndroidUMPManager } from 'common/core/native-app/AndroidUMPManager'
import { loadAdsenseScript } from 'common/core/ads/helpers/adsense-helpers'
import UAParser from 'ua-parser-js'
import { FC, PropsWithChildren } from 'react'
import { RouteObject } from 'react-router-dom'
import App from './App/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

export function buildAppParamConfig(servicesPrefix: string): ParamAppConfig {
  const appParamConfigStore = new AppParamConfigStore(
    `${servicesPrefix}:paramAppConfig`,
  )
  const appParamConfigCreator = new AppParamConfigCreator(
    new URLSearchParams(window.location.search),
  )

  const appParamConfig = appParamConfigCreator.getConfig()
  const storedAppParamConfig = appParamConfigStore.getConfig()
  const defaultAppParamConfig: ParamAppConfig = {
    clientType: ClientType.Web,
    enableGA: true,
    androidClientVersionCode: undefined,
    iosClientVersion: undefined,
    googlePlayReferrerString: undefined,
  }
  const finalAppParamConfig: ParamAppConfig = {
    ...defaultAppParamConfig,
    ...storedAppParamConfig,
    ...appParamConfig,
  }
  appParamConfigStore.setConfig(finalAppParamConfig)
  return finalAppParamConfig
}

export function startGenericApp(
  buildBrandConfig: (
    buildtimeConfig: BuildtimeConfig,
    appParamConfig: ParamAppConfig,
    localStoragePrefix: string,
  ) => BrandConfig,
  translationGroups: TranslationMessagesGroups,
  getAuthenticatedRoutesConfig: (intl: IntlShape) => RouteObject[],
  localStoragePrefix: string,
  allowToShowLocaleSelectionModal: boolean,
  shouldInitAdsense: (appParamConfig: ParamAppConfig) => boolean,
  AddtionalWrapperComponent: FC<PropsWithChildren> | undefined,
) {
  const buildtimeConfig = new BuildtimeConfigBuilder().getBuildtimeConfig()

  const appParamConfig = buildAppParamConfig(localStoragePrefix)
  const brandConfig = buildBrandConfig(
    buildtimeConfig,
    appParamConfig,
    localStoragePrefix,
  )
  const {
    common: {
      services: { appUsageTracker, ga4Service },
    },
  } = brandConfig

  const uaResult = new UAParser().getResult()
  const browserValue = `${uaResult.browser.name} ${uaResult.browser.major}`
  const osValue = `${uaResult.os.name} ${uaResult.os.version}`
  ga4Service.setUserProperties({
    ot_browser: browserValue,
    ot_os: osValue,
  })
  ga4Service.sendEvent('app_start', {
    ot_browser: browserValue,
    ot_os: osValue,
  })

  ReactModal.setAppElement('#root')

  const { $crisp } = window as any
  $crisp.push(['do', 'chat:hide'])

  if (shouldInitAdsense(appParamConfig)) {
    loadAdsenseScript()
  }

  AndroidUMPManager.getInstance().initAdmobWithConsents()

  if (!appUsageTracker.getFirstAppLaunchTimestamp()) {
    appUsageTracker.setFirstAppLaunchTimestamp(Date.now())
  }

  const observer = new MutationObserver((_mutationList, obs) => {
    const el = document.querySelector('.google-revocation-link-placeholder')
    if (el) {
      el.remove()
      obs.disconnect()
    }
  })
  observer.observe(document.body, {
    childList: true,
    subtree: false,
  })

  // Remove the original meta description tag hardcoded in the html,
  // since Google only shows the contents of the first tag
  const description = document.querySelector('meta[name="description"]')
  if (description) {
    description.remove()
  }

  const container = document.getElementById('root')
  const root = createRoot(container!)
  root.render(
    <App
      brandConfig={brandConfig}
      translationGroups={translationGroups}
      allowToShowLocaleSelectionModal={allowToShowLocaleSelectionModal}
      getAuthenticatedRoutesConfig={getAuthenticatedRoutesConfig}
      AddtionalWrapperComponent={AddtionalWrapperComponent}
    />,
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorkerRegistration.register({
    // Use the latest app version if it'available without
    // waiting the user to close the browser
    onUpdate: (registration: ServiceWorkerRegistration) => {
      const waitingServiceWorker = registration.waiting
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', (event) => {
          const targetServiceWorker = event?.target as ServiceWorker
          if (targetServiceWorker.state === 'activated') {
            window.location.reload()
          }
        })
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
      }
    },
  })

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}
