/* eslint-disable no-restricted-syntax */
import { FC, PropsWithChildren } from 'react'

import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { retrieveServerTimeQueryPersistenceConfig } from 'common/core/server-time/state-hook'
import { QueryPersistenceConfig } from 'common/shared/react-query/models'
import { retrieveUserEntitlementQueryPersistenceConfig } from 'common/core/premium/firestore-entitlements-client/state-hook'

const queryClient = new QueryClient()

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

export const AppReactQueryProvider: FC<PropsWithChildren> = ({ children }) => (
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{
      persister,
      maxAge: 1000 * 60 * 60 * 24 * 7, // Just the time we want let the user to avoid seeing the loading spinner and request errors
      dehydrateOptions: {
        shouldDehydrateQuery: (query) => {
          const mainKey = query.queryKey[0]
          const persistenceConfigItems: QueryPersistenceConfig[] = [
            retrieveServerTimeQueryPersistenceConfig,
            retrieveUserEntitlementQueryPersistenceConfig,
          ]
          for (const configItem of persistenceConfigItems) {
            if (mainKey === configItem.mainQueryKey) {
              return configItem.shouldPersist()
            }
          }
          return false
        },
      },
    }}
  >
    {/* <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" /> */}
    {children}
  </PersistQueryClientProvider>
)
