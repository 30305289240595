import { AppUsageTracker } from 'common/core/app-usage/AppUsageTracker'
import {
  ClientAppInfo,
  ClientType,
} from 'common/core/param-config/param-app-config-models'
import { OtAndroidBridge } from 'common/core/native-app/ot-android-bridge'
import { Listenable } from 'common/shared/Listenable'
import { OtIosBridge } from 'common/core/native-app/ot-ios-bridge'
import { GA4Service } from 'common/core/ga4/GA4Service'
import { AdsUsagePersistor } from './AdsUsagePersistor'

export class InterstitialAdsManager {
  private static readonly STOP_AD_INTERVAL_IN_MILLESECONDS = 20 * 60 * 1000
  // private static readonly STOP_AD_INTERVAL_IN_MILLESECONDS = 20 * 1000

  private static readonly TRIAL_PERIOD_IN_MILLESECONDS = 1 * 24 * 60 * 60 * 1000
  // private static readonly TRIAL_PERIOD_IN_MILLESECONDS = 15 * 1000

  public onStateChange = new Listenable()

  constructor(
    private adsUsagePersistor: AdsUsagePersistor,
    private appUsageTracker: AppUsageTracker,
    private clientAppInfo: ClientAppInfo,
    private ga4Service: GA4Service,
    private allowInterstitialAds: (clientType: ClientType) => boolean = () =>
      true,
  ) {}

  public isPeriodAdPending() {
    const periodStartTimestamp =
      this.adsUsagePersistor.getPeriodStartTimestamp()
    const wasPeriodInterstitialAdShown =
      this.adsUsagePersistor.wasPeriodInterstitialAdShown()
    return periodStartTimestamp && !wasPeriodInterstitialAdShown
  }

  public handleAdTriggeringAction(place: string) {
    if (!this.clientAppInfo.areInterstitialAdsSupported()) {
      return
    }
    if (!this.allowInterstitialAds(this.clientAppInfo.getClientType())) {
      return
    }
    if (this.isTrialPeriodAvailableForClient() && this.isTrialPeriodActive()) {
      return
    }

    const periodStartTimestamp =
      this.adsUsagePersistor.getPeriodStartTimestamp()
    const wasPeriodInterstitialAdShown =
      this.adsUsagePersistor.wasPeriodInterstitialAdShown()
    if (periodStartTimestamp) {
      const hasPeriodFinished =
        Date.now() - periodStartTimestamp >
        InterstitialAdsManager.STOP_AD_INTERVAL_IN_MILLESECONDS
      if (hasPeriodFinished) {
        if (!wasPeriodInterstitialAdShown) {
          this.ga4Service.sendEvent('interstitialAdImpression', { place })
          this.showInterstitialAd()
        }
        this.startNewAdPeriod()
      }
    } else {
      this.startNewAdPeriod()
    }
  }

  private startNewAdPeriod() {
    this.adsUsagePersistor.setPeriodStartTimestamp(Date.now())
    this.adsUsagePersistor.setPeriodInterstitialAdShown(false)
    this.onStateChange.notifyListeners()
  }

  public showInterstitialAdForcibly() {
    // ga4.sendEvent('interstitialAdImpression', { place: 'force' })
    this.showInterstitialAd()
  }

  private showInterstitialAd() {
    this.adsUsagePersistor.setPeriodInterstitialAdShown(true)
    this.onStateChange.notifyListeners()
    OtAndroidBridge.requestInterstitialAd()
    OtIosBridge.requestInterstitialAd()
  }

  // private requestInterstitialAdIfNeeded(page: string): void {
  //   if (this.shouldShowInterstitialAd()) {
  //     this.requestInterstitialAd(page)
  //   }
  // }

  // private shouldShowInterstitialAd() {
  //   if (!this.allowInterstitialAds(this.clientAppInfo.getClientType())) {
  //     return false
  //   }
  //   if (this.isTrialPeriodAvailableForClient() && this.isTrialPeriodActive()) {
  //     return false
  //   }
  //   if (this.isPeriodBetweenAdsActive()) {
  //     return false
  //   }
  //   return true
  // }

  private isTrialPeriodAvailableForClient() {
    return [ClientType.Android, ClientType.IOS].includes(
      this.clientAppInfo.getClientType(),
    )
  }

  private isTrialPeriodActive() {
    const firstAppUsageTimestamp =
      this.appUsageTracker.getFirstAppLaunchTimestamp()
    if (!firstAppUsageTimestamp) {
      return true
    }
    return (
      Date.now() - firstAppUsageTimestamp <=
      InterstitialAdsManager.TRIAL_PERIOD_IN_MILLESECONDS
    )
  }

  // private isPeriodBetweenAdsActive() {
  //   const timestamp = this.adsUsagePersistor.getLastInterstitialAdImpressionTimestamp()
  //   if (!timestamp) {
  //     return false
  //   }
  //   return Date.now() - timestamp <= InterstitialAdsManager.STOP_AD_INTERVAL_IN_MILLESECONDS
  // }

  // private requestInterstitialAd(place: string) {
  //   this.ga4Service.sendEvent('interstitialAdImpression', { place })
  //   this.adsUsagePersistor.setLastInterstitialAdImpressionTimestamp(Date.now())
  //   OtAndroidBridge.requestInterstitialAd()
  //   OtIosBridge.requestInterstitialAd()
  // }
}
