import { AppThemeName } from 'common/core/theme/app-theme-models'

export interface UiPrefs {
  isFavDeparturesHintDismissed?: boolean
  isSyncReminderDismissed?: boolean
  allowPopularStops?: boolean
  preferredThemeName?: AppThemeName
  allowInterstitialAdAlert?: boolean
}

export const defaultValues: UiPrefs = {
  isFavDeparturesHintDismissed: false,
  isSyncReminderDismissed: false,
  allowPopularStops: true,
  // preferredThemeName: AppThemeName.LightBlue,
  allowInterstitialAdAlert: true,
}
