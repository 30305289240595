import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'
import s from './Container.module.scss'

interface Props {
  bottomMargin?: boolean
}

const Container: FC<PropsWithChildren<Props>> = ({
  children,
  bottomMargin = false,
}) => (
  <div
    className={classNames({ [s.root]: true, [s.bottomMargin]: bottomMargin })}
  >
    {children}
  </div>
)

export default Container
