import { BuildtimeConfig } from 'common/core/buildtime-config/buildtime-config-models'
import { AppUsageTracker } from 'common/core/app-usage/AppUsageTracker'
import {
  ClientAppInfo,
  ClientType,
  ParamAppConfig,
} from 'common/core/param-config/param-app-config-models'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { initializeFirestore } from 'firebase/firestore/lite'
import {
  GA4ServiceBuilder,
  GaIdMapping,
} from 'common/core/ga4/GA4ServiceBuilder'
import { UiPrefsStore } from 'common/core/ui-prefs/UiPrefsStore'
import { UserFirestoreClient } from 'common/core/firebase/user-firestore-client/UserFirestoreClient'
import UserFirestoreClientWithCache from 'common/core/firebase/user-firestore-client-with-cache/UserFirestoreClientWithCache'
import { CityId } from '../firebase/firestore-models'
import { AppServices } from './app-services-models'
import { I18nPersistor } from '../i18n/services/I18nPersistor'
import { InterstitialAdsManager } from '../ads/services/InterstitialAdsManager'
import { AdsUsagePersistor } from '../ads/services/AdsUsagePersistor'
import { CachedUserStore } from '../auth/CachedUserStore'

export interface InitializationConfig {
  localStoragePrefix: string
  cityId: CityId
  allowInterstitialAds: (clientType: ClientType) => boolean
  firebaseOptions: FirebaseOptions
  gaIdMapping: GaIdMapping
}

export class AppServicesBuilder {
  constructor(
    private buildtimeConfig: BuildtimeConfig,
    private paramAppConfig: ParamAppConfig,
    private config: InitializationConfig,
  ) {}

  private composeLSKey(mainName: string): string {
    return `${this.config.localStoragePrefix}:${mainName}`
  }

  public buildAppServices(): AppServices {
    const uiPreferencesStore = new UiPrefsStore(
      this.composeLSKey('uiPreferences'),
    )
    const appUsageTracker = new AppUsageTracker(this.composeLSKey('appUsage'))
    const adsUsagePersistor = new AdsUsagePersistor(this.composeLSKey('ads'))
    const firebaseApp = initializeApp(this.config.firebaseOptions)
    const firestore = initializeFirestore(firebaseApp, {})
    const userFirestoreClient = new UserFirestoreClient(
      firestore,
      this.config.cityId,
    )
    const userFirestoreClientWithCache = new UserFirestoreClientWithCache(
      userFirestoreClient,
    )
    const ga4Service = new GA4ServiceBuilder(
      this.buildtimeConfig,
      this.paramAppConfig,
      this.config.gaIdMapping,
    ).build()
    const clientAppInfo = new ClientAppInfo(this.paramAppConfig)
    return {
      buildtimeConfig: this.buildtimeConfig,
      paramAppConfig: this.paramAppConfig,
      firestore,
      clientAppInfo,
      uiPreferencesStore,
      adsUsagePersistor,
      interstitialAdsManager: new InterstitialAdsManager(
        adsUsagePersistor,
        appUsageTracker,
        clientAppInfo,
        ga4Service,
        this.config.allowInterstitialAds,
      ),
      appUsageTracker,
      i18nPersistor: new I18nPersistor(this.composeLSKey('i18n')),
      firebaseApp,
      userFirestoreClientWithCache,
      ga4Service,
      cachedUserStore: new CachedUserStore(this.composeLSKey('userCache')),
    }
  }
}
