import StopApiClient from 'common/domain/stops/services/stop-api-client/StopApiClient'
import StopMapStateStore from 'common/domain/maps/services/stop-map-state-store/StopMapStateStore'
import FavoriteArrivalsStore from 'common/domain/stops/services/favorite-arrivals-store/FavoriteArrivalsStore'
import SearchHistoryStore from 'common/domain/search/services/search-history-store/SearchHistoryStore'
import VisitedStopsStore from 'common/domain/stops/services/visited-stops-store/VisitedStopsStore'
import LineApiClient from 'common/domain/lines/services/line-api-client/LineApiClient'
import { StopListApiClient } from 'common/domain/stops/services/stop-list-api-client/StopListApiClient'
import StopFavsStore from 'common/domain/stops/services/stop-favs-store/StopFavsStore'
import { LineListApiClient } from 'common/domain/lines/services/line-list-api-client/LineListApiClient'
import StopFavsService from 'common/domain/stops/services/stop-favs-service/StopFavsService'
import UserFirestoreClientWithCache from 'common/core/firebase/user-firestore-client-with-cache/UserFirestoreClientWithCache'
import { SearchableStopsBuilder } from 'common/domain/search/services/SearchableStopsBuilder'
import { FakeSearchableLinesBuilder } from 'common/domain/search/services/FakeSearchableLinesBuilder'
import StopCodeWrapperFactoryInterface from '../services/StopCodeWrapperFactoryInterface'
import { StopServices } from './stop-services'

export interface StopServicesInitConfig {
  localStoragePrefix: string
  apiGatewayUrl: string

  // These files should be inside '/cached' dir, since
  // all files there will be cached by Nginx.
  // Also, these file names are used in index.html and sitemap generator script.
  stopListFileUrl: string
  lineListFileUrl: string

  allowedStopTypeCodes: string[] | undefined
  allowedTransportTypeCodes: string[] | undefined
  stopTypeCodesThatShouldGenerateFakeSearchableLines: string[]
  searchStopTypeCodesOrder: string[]
  stopCodeWrapperFactory: StopCodeWrapperFactoryInterface
  userFirestoreClientWithCache: UserFirestoreClientWithCache
}

export class StopServicesBuilder {
  constructor(private config: StopServicesInitConfig) {}

  private composeLSKey(mainName: string): string {
    return `${this.config.localStoragePrefix}:${mainName}`
  }

  public buildServices(): StopServices {
    const stopFavsStore = new StopFavsStore(this.composeLSKey('savedStops'))
    return {
      stopApiClient: new StopApiClient(this.config.apiGatewayUrl),
      lineApiClient: new LineApiClient(this.config.apiGatewayUrl),
      stopFavsStore,
      stopMapStateStore: new StopMapStateStore(
        this.composeLSKey('stopMapState'),
      ),
      favoriteArrivalsStore: new FavoriteArrivalsStore(
        this.composeLSKey('savedDepartures'),
      ),
      searchHistoryStore: new SearchHistoryStore(this.composeLSKey('search')),
      visitedStopsStore: new VisitedStopsStore(
        this.composeLSKey('visitedStops'),
      ),
      stopListApiClient: new StopListApiClient(
        this.config.stopListFileUrl,
        this.config.allowedStopTypeCodes,
      ),
      lineListApiClient: new LineListApiClient(
        this.config.lineListFileUrl,
        this.config.allowedTransportTypeCodes,
      ),
      searchableStopsBuilder: new SearchableStopsBuilder(
        this.config.stopTypeCodesThatShouldGenerateFakeSearchableLines,
        this.config.searchStopTypeCodesOrder,
      ),
      fakeSearchableLinesBuilder: new FakeSearchableLinesBuilder(
        this.config.stopTypeCodesThatShouldGenerateFakeSearchableLines,
      ),
      stopFavsService: new StopFavsService(
        stopFavsStore,
        this.config.userFirestoreClientWithCache,
      ),
      stopCodeWrapperFactory: this.config.stopCodeWrapperFactory,
    }
  }
}
