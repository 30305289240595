import { FormattedMessage } from 'react-intl'
import { FC } from 'react'
import { StopTypeNumCode } from '../../models/stop-models'
import { StopCodeWrapper } from '../../helpers/stop-code-wrapper'
import s from './StopLoadingContent.module.scss'

interface Props {
  stopCode: string
}

const StopLoadingContent: FC<Props> = ({ stopCode }) => {
  function renderLargeTimeoutAlert() {
    return (
      <p className={s.alert}>
        <FormattedMessage id="stop_loading_crtm_alert" />
      </p>
    )
  }

  const stopTypeCode = new StopCodeWrapper(stopCode).getStopTypeCode()

  return (
    <>
      {(stopTypeCode === StopTypeNumCode.INTERURBAN ||
        stopTypeCode === StopTypeNumCode.EMT) &&
        renderLargeTimeoutAlert()}
    </>
  )
}

export default StopLoadingContent
