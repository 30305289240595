import { Link, useNavigate, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as ArrowBackIcon } from 'common/core/images/arrow_back-24px.svg'
import { ReactComponent as SantaHatImg } from 'common/core/images/santa-hat.svg'
import { InstantlyActive } from 'common/core/ui/components/InstantlyActive/InstantlyActive'
import useAppUpgradeContext from 'common/core/app-upgrade/useAppUpgradeContext'
import { FC, ReactNode } from 'react'
import s from './ActionBar.module.scss'

interface ActionBarProps {
  title?: ReactNode
  backLinkPath?: string
  rightSection?: ReactNode
  withSantaHat?: boolean
  allowDownloadAppButton?: boolean
}

const ActionBar: FC<ActionBarProps> = ({
  allowDownloadAppButton = true,
  withSantaHat,
  backLinkPath,
  title,
  rightSection,
}) => {
  const { canUpgradeToApp, redirectToAppStore } = useAppUpgradeContext()
  const navigate = useNavigate()
  const location = useLocation()

  function renderUpLink(upLinkPath: string) {
    return (
      <InstantlyActive
        as={Link}
        to={upLinkPath}
        className={s.backArrowLink}
        highContrastActive
      >
        <ArrowBackIcon />
      </InstantlyActive>
    )
  }

  function renderBackButton() {
    return (
      <InstantlyActive
        as="button"
        type="button"
        onClick={() => navigate(-1)}
        className={s.backArrowLink}
        highContrastActive
      >
        <ArrowBackIcon />
      </InstantlyActive>
    )
  }

  const renderBackLink = (upLinkPath: string) => {
    if (location.key) {
      return renderBackButton()
    }
    return renderUpLink(upLinkPath)
  }

  function renderBackNav(upLinkPath: string) {
    return <div className={s.backNavBox}>{renderBackLink(upLinkPath)}</div>
  }

  const renderTitle = () => (
    <h1 className={s.title}>
      {title}
      {withSantaHat && <SantaHatImg className={s.santaHat} />}
    </h1>
  )

  function handleDownloadAppButtonClick() {
    redirectToAppStore('actionBar')
  }

  function renderDownloadAppButton() {
    return (
      <InstantlyActive
        as="button"
        type="button"
        onClick={handleDownloadAppButtonClick}
        className={s.app}
      >
        <FormattedMessage id="action_bar_install_app" />
      </InstantlyActive>
    )
  }

  return (
    <div className={s.header}>
      <div className={s.headerContainer}>
        {backLinkPath ? renderBackNav(backLinkPath) : undefined}
        {renderTitle()}
        <div className={s.rightSection}>
          {rightSection}
          {allowDownloadAppButton &&
            canUpgradeToApp() &&
            renderDownloadAppButton()}
        </div>
      </div>
    </div>
  )
}

export default ActionBar
