import { FC, PropsWithChildren, useState } from 'react'
import useUserContext from 'common/core/auth/hooks/useUserContext'
import { DIContainer } from 'common/shared/deps-injection/DepsContainer'
import { StopDepsToken } from 'common/domain/stops/deps/stop-deps'
import { StopSyncExecutor } from '../StopSyncExecutor/StopSyncExecutor'

export const StopSyncManager: FC<PropsWithChildren> = ({ children }) => {
  const currentUser = useUserContext()

  const {
    services: { stopFavsStore },
  } = DIContainer.get(StopDepsToken)

  const [syncFinished, setSyncFinished] = useState<boolean>(false)

  function handleSyncEnd() {
    stopFavsStore.deleteAllStopFavs()
    setSyncFinished(true)
  }

  function isSyncNeeded() {
    return stopFavsStore.getStopFavs().length > 0
  }

  return (
    <>
      {!!currentUser && isSyncNeeded() && !syncFinished ? (
        <StopSyncExecutor
          user={currentUser}
          localStopFavs={stopFavsStore.getStopFavs()}
          onSyncEnd={handleSyncEnd}
        />
      ) : (
        children
      )}
    </>
  )
}
