import { User } from 'firebase/auth'
import {
  FirestoreStopFav,
  UserDocWrapper,
} from 'common/core/firebase/firestore-models'
import { UserFirestoreClient } from '../user-firestore-client/UserFirestoreClient'

export default class UserFirestoreClientWithCache {
  private cachedUserDocWrapper: UserDocWrapper | undefined

  constructor(private userFirestoreClient: UserFirestoreClient) {}

  public resetCache() {
    this.cachedUserDocWrapper = undefined
  }

  public async getUserDocWrapper(user: User) {
    if (this.cachedUserDocWrapper) {
      return this.cachedUserDocWrapper
    }
    const userDocWrapper =
      await this.userFirestoreClient.getUserDocWrapper(user)
    this.cachedUserDocWrapper = userDocWrapper
    return userDocWrapper
  }

  public async setStopFavs(user: User, stopFavs: FirestoreStopFav[]) {
    await this.userFirestoreClient.setStopFavs(user, stopFavs)
    if (this.cachedUserDocWrapper) {
      this.cachedUserDocWrapper = new UserDocWrapper({
        ...this.cachedUserDocWrapper?.getUserDoc(),
        stopFavs,
      })
    }
  }
}
