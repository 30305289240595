import {
  ClientType,
  ParamAppConfig,
} from 'common/core/param-config/param-app-config-models'

enum ParamName {
  ClientType = 'client', // eslint-disable-line
  IsGAEnabled = 'ga',
  AndroidClientVersionCode = 'androidClientVersionCode',
  IosClientVersion = 'iosClientVersion',
  GooglePlayReferrer = 'googlePlayReferrer',
}

export default class AppParamConfigCreator {
  private static removeUndefinedFields<T>(obj: { [key: string]: T }): {
    [key: string]: T
  } {
    const ret: { [key: string]: T } = {}
    Object.keys(obj)
      .filter((key) => obj[key] !== undefined)
      .forEach((key) => {
        ret[key] = obj[key]
      })
    return ret
  }

  constructor(private paramas: URLSearchParams) {}

  private getParamValue(paramName: string): string | undefined {
    return this.paramas.get(paramName) || undefined
  }

  private getClientType(): ClientType | undefined {
    const paramName = ParamName.ClientType
    const paramValue = this.getParamValue(paramName)
    if (paramValue === undefined) {
      return undefined
    }
    if (paramValue === 'web') {
      return ClientType.Web
    }
    if (paramValue === 'android') {
      return ClientType.Android
    }
    if (paramValue === 'ios') {
      return ClientType.IOS
    }
    throw new Error(`
      Cannot interpret the '${paramName}' param with '${paramValue}' value
    `)
  }

  private getGooglePlayReferrer() {
    return this.getParamValue(ParamName.GooglePlayReferrer)
  }

  private getIsGAEnabled(): boolean | undefined {
    const paramName = ParamName.IsGAEnabled
    const paramValue = this.getParamValue(paramName)
    if (paramValue === undefined) {
      return undefined
    }
    if (paramValue === '0') {
      return false
    }
    if (paramValue === '1') {
      return true
    }
    throw new Error(`
      Cannot interpret the '${paramName}' param with '${paramValue}' value
    `)
  }

  private getAndroidClientVersionCode(): number | undefined {
    const paramName = ParamName.AndroidClientVersionCode
    const paramValue = this.getParamValue(paramName)
    if (paramValue === undefined) {
      return undefined
    }
    const parsedValue = parseInt(paramValue, 10)
    if (typeof parsedValue === 'number') {
      return parsedValue
    }
    throw new Error(`
      Cannot interpret the '${paramName}' param with '${paramValue}' value
    `)
  }

  private getIosClientVersion(): string | undefined {
    const paramName = ParamName.IosClientVersion
    return this.getParamValue(paramName)
  }

  getConfig(): Partial<ParamAppConfig> {
    const configWithUndefinedValues = {
      clientType: this.getClientType(),
      enableGA: this.getIsGAEnabled(),
      androidClientVersionCode: this.getAndroidClientVersionCode(),
      iosClientVersion: this.getIosClientVersion(),
      googlePlayReferrerString: this.getGooglePlayReferrer(),
    }
    return AppParamConfigCreator.removeUndefinedFields(
      configWithUndefinedValues,
    )
  }
}
