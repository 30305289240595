import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import usePremiumManagerContext from 'common/core/premium/premium-manager/context'

export const useShouldSuggestPremium = () => {
  const {
    common: {
      isGplayBillingAllowed,
      services: { clientAppInfo },
    },
  } = useBrandConfigContext()
  const premiumContext = usePremiumManagerContext()

  const isEntitled =
    premiumContext.enabled && premiumContext.entitlementCalculator?.isEntitled()

  const shouldSuggestPremium =
    isGplayBillingAllowed() &&
    clientAppInfo.areGplayBillingSubsSupported() &&
    !isEntitled

  return shouldSuggestPremium
}
