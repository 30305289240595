import { ReactComponent as InterurbanIcon } from 'common-mad/stops/images/stop-interurban.svg'
import { ReactComponent as EmtIcon } from 'common-mad/stops/images/stop-emt.svg'
import { ReactComponent as CercaniasIcon } from 'common-mad/stops/images/stop-cercanias.svg'
import { ReactComponent as MetroIcon } from 'common-mad/stops/images/stop-metro.svg'
import { ReactComponent as LightMetroIcon } from 'common-mad/stops/images/stop-light-metro.svg'
import { StopTypeNumCode } from 'common-mad/stops/models/stop-models'
import { FC, SVGProps } from 'react'

interface StopIconProps {
  stopTypeCode: string
}

const mapping: {
  [key in StopTypeNumCode]: FC<SVGProps<SVGSVGElement>>
} = {
  [StopTypeNumCode.INTERURBAN]: InterurbanIcon,
  [StopTypeNumCode.EMT]: EmtIcon,
  [StopTypeNumCode.CERCANIAS]: CercaniasIcon,
  [StopTypeNumCode.METRO]: MetroIcon,
  [StopTypeNumCode.LIGHT_METRO]: LightMetroIcon,
}

const MadStopIcon: FC<StopIconProps> = ({ stopTypeCode }) => {
  const IconComponent = mapping[stopTypeCode as StopTypeNumCode]
  if (IconComponent) {
    return <IconComponent />
  }
  return <></>
}

export default MadStopIcon
