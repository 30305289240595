import { addNewAdToShow } from 'common/core/ads/helpers/adsense-helpers'
import { FC, useEffect } from 'react'

interface Props {
  adsenseSlot: string
  className?: string
}

const AdsenseBannerDisplayAd: FC<Props> = ({ adsenseSlot, className }) => {
  useEffect(() => {
    addNewAdToShow()
  }, [adsenseSlot])

  return (
    <div className={className || ''}>
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          height: '60px',
        }}
        data-ad-client="ca-pub-4759034046917334"
        data-ad-slot={adsenseSlot}
        data-ad-format="fluid"
      />
    </div>
  )
}

export default AdsenseBannerDisplayAd
