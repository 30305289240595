/* eslint-disable no-alert */
import { OtAndroidBridge } from 'common/core/native-app/ot-android-bridge'
import { GenericCommunicator } from './GenericCommunicator'
import { AndroidAppToWebviewBridge } from './android-app-to-webview-bridge'

export class AndroidUMPManager {
  private static instance: AndroidUMPManager | undefined

  private constructor() {
    // const androidAppToWebviewBridge = AndroidAppToWebviewBridge.getInstance()
    // androidAppToWebviewBridge.onUMPPrivacyOptionsFormDismissedSuccess.addCallback(() => {
    //   alert('onUMPPrivacyOptionsFormDismissedSuccess')
    // })
    // androidAppToWebviewBridge.onUMPPrivacyOptionsFormDismissedError.addCallback(() => {
    //   alert('onUMPPrivacyOptionsFormDismissedError')
    // })
    // androidAppToWebviewBridge.onUMPConsentFormDismissedSuccess.addCallback(() => {
    //   alert('onUMPConsentFormDismissedSuccess')
    // })
    // androidAppToWebviewBridge.onUMPConsentFormDismissedError.addCallback(() => {
    //   alert('onUMPConsentFormDismissedError')
    // })
  }

  private communicators = {
    resetConsentInformation: new GenericCommunicator<undefined, undefined>(
      (_param, messageId) =>
        OtAndroidBridge.resetUMPConsentInformation(messageId),
      (callback) =>
        AndroidAppToWebviewBridge.getInstance().onResetUMPConsentInformationSuccess.addCallback(
          callback,
        ),
    ),
    getUMPConsentInformationJSON: new GenericCommunicator<undefined, string>(
      (_param, messageId) =>
        OtAndroidBridge.getUMPConsentInformationJSON(messageId),
      (callback) =>
        AndroidAppToWebviewBridge.getInstance().onGetUMPConsentInformationJSONSuccess.addCallback(
          callback,
        ),
    ),
  }

  public static getInstance() {
    if (!AndroidUMPManager.instance) {
      AndroidUMPManager.instance = new AndroidUMPManager()
    }
    return AndroidUMPManager.instance
  }

  public initAdmobWithConsents() {
    return OtAndroidBridge.initAdmobWithConsents()
  }

  public initAdmobWithoutConsents() {
    return OtAndroidBridge.initAdmobWithoutConsents()
  }

  public resetConsentInformation() {
    return this.communicators.resetConsentInformation.execute(undefined)
  }

  public showPrivacyOptionsForm() {
    OtAndroidBridge.showUMPPrivacyOptionsForm('')
  }

  public getConsentInformationJSON() {
    return this.communicators.getUMPConsentInformationJSON.execute(undefined)
  }
}
