interface StoredPayload {
  visitedStops: StoredItem[]
}

interface StoredItem {
  stopCode: string
}

export default class VisitedStopsStore {
  private cachedItems: StoredItem[] | undefined

  constructor(private localStorageKey: string) {}

  public recordVisit(stopCode: string) {
    const items = this.getStoredItems()
    const newItems = items.filter((item) => item.stopCode !== stopCode)
    newItems.push({ stopCode })
    this.setStoredItems(newItems)
  }

  public getVisitedStopCodes(): string[] {
    return this.getStoredItems().map((item) => item.stopCode)
  }

  public deleteAllStops() {
    this.setStoredItems([])
  }

  private setStoredItems(items: StoredItem[]): void {
    this.cachedItems = items
    const payload = { visitedStops: items }
    localStorage.setItem(this.localStorageKey, JSON.stringify(payload))
  }

  private getStoredItems(): StoredItem[] {
    if (!this.cachedItems) {
      this.cachedItems = this.getStoredItemsFromStorage()
    }
    return this.cachedItems
  }

  private getStoredItemsFromStorage(): StoredItem[] {
    const dataStr = localStorage.getItem(this.localStorageKey)
    if (dataStr) {
      return (JSON.parse(dataStr) as StoredPayload).visitedStops
    }
    return []
  }
}
