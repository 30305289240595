import {
  LinePageMetadata,
  LinePageMetadataOptions,
  PageMetadata,
  StopPageMetadata,
  StopPageMetadataOptions,
} from 'common/core/page-metadata/page-metadata-models'
import { PageId } from 'common-mad/core/models/app-models'
import {
  StopTypeNumCode,
  TransportTypeNumCode,
} from '../../stops/models/stop-models'

const stopPageMetadata: { [key in StopTypeNumCode]: StopPageMetadata } = {
  [StopTypeNumCode.INTERURBAN]: {
    title: ({ stopCode }) =>
      `Parada de buses ${stopCode} | Cuánto tarda mi bus`,
    description: ({ stopCode }) =>
      `Consulta cuánto tiempo le queda a tu bus para llegar a la parada ${stopCode}`,
  },
  [StopTypeNumCode.EMT]: {
    title: ({ stopCode }) =>
      `Parada de buses ${stopCode} | Cuánto tarda mi bus`,
    description: ({ stopCode }) =>
      `Consulta cuánto tiempo le queda a tu bus para llegar a la parada ${stopCode}`,
  },
  [StopTypeNumCode.CERCANIAS]: {
    title: ({ stopName }) => `Cercanías ${stopName} | Cuándo llega mi tren`,
    description: ({ stopName }) =>
      `Consulta las llegadas de los próximos trenes en ${stopName}`,
  },
  [StopTypeNumCode.METRO]: {
    title: ({ stopName }) => `Metro ${stopName} | Tiempo de espera`,
    description: ({ stopName }) =>
      `Consulta las llegadas de los próximos trenes en ${stopName}`,
  },
  [StopTypeNumCode.LIGHT_METRO]: {
    title: ({ stopName }) => `Metro Ligero ${stopName} | Tiempo de espera`,
    description: ({ stopName }) =>
      `Consulta las llegadas de los próximos trenes en ${stopName}`,
  },
}

const linePageMetadata: { [key in TransportTypeNumCode]: LinePageMetadata } = {
  [TransportTypeNumCode.INTERURBAN]: {
    title: ({ lineNumber, lineName }) =>
      `Horarios autobús interurbano ${lineNumber} | ${lineName}`,
    description: ({ lineNumber, lineName }) =>
      `Consulta los horarios y las paradas de la línea de autobuses interurbanos ${lineNumber}: ${lineName}`,
  },
  [TransportTypeNumCode.URBAN]: {
    title: ({ lineNumber, lineName }) =>
      `Horarios autobús urbano ${lineNumber} | ${lineName}`,
    description: ({ lineNumber, lineName }) =>
      `Consulta los horarios y las paradas de la línea de autobuses urbanos ${lineNumber}: ${lineName}`,
  },
  [TransportTypeNumCode.EMT]: {
    title: ({ lineNumber, lineName }) =>
      `Horarios autobús EMT ${lineNumber} | ${lineName}`,
    description: ({ lineNumber, lineName }) =>
      `Consulta los horarios y las paradas de la línea de autobuses EMT ${lineNumber}: ${lineName}`,
  },
  [TransportTypeNumCode.CERCANIAS]: {
    title: ({ lineNumber, lineName }) =>
      `Línea de Cercanías ${lineNumber} | ${lineName}`,
    description: ({ lineNumber, lineName }) =>
      `Consulta los horarios y las paradas de la línea de Cercanías ${lineNumber}: ${lineName}`,
  },
  [TransportTypeNumCode.METRO]: {
    title: ({ lineNumber, lineName }) =>
      `Horarios de la línea de Metro ${lineNumber} | ${lineName}`,
    description: ({ lineNumber, lineName }) =>
      `Consulta los horarios y las paradas de la línea de Metro ${lineNumber}: ${lineName}`,
  },
  [TransportTypeNumCode.LIGHT_METRO]: {
    title: ({ lineNumber, lineName }) =>
      `Horarios de la línea de Metro Ligero ${lineNumber} | ${lineName}`,
    description: ({ lineNumber, lineName }) =>
      `Consulta los horarios y las paradas de la línea de Metro Ligero ${lineNumber}: ${lineName}`,
  },
}

export const pageMetadataCollection: { [key in PageId]: PageMetadata } = {
  [PageId.HomePage]: {
    title: () => 'OK Transporte Madrid | Cuánto tarda mi bus o tren',
    description: () =>
      `Consulta cuánto tiempo le queda a tu bus o tren para llegar a la parada.
      Tiempo de espera de autobuses interurbanos, EMT, Cercanías, Metro y Metro Ligero.`,
  },
  [PageId.StopPage]: {
    title: (options: StopPageMetadataOptions) =>
      stopPageMetadata[options.stopTypeCode as StopTypeNumCode].title(options),
    description: (options: StopPageMetadataOptions) =>
      stopPageMetadata[options.stopTypeCode as StopTypeNumCode].description(
        options,
      ),
  },
  [PageId.LinesPage]: {
    title: () => 'Líneas de autobuses interurbanos y EMT de Madrid',
    description: () =>
      'Consulta paradas y horarios de las líneas de autobuses interurbanos y EMT de Madrid.',
  },
  [PageId.LinePage]: {
    title: (options: LinePageMetadataOptions) =>
      linePageMetadata[options.transportTypeCode as TransportTypeNumCode].title(
        options,
      ),
    description: (options: LinePageMetadataOptions) =>
      linePageMetadata[
        options.transportTypeCode as TransportTypeNumCode
      ].description(options),
  },
  [PageId.SearchPage]: {
    title: () => 'Buscar parada | OK Transporte Madrid',
    description: () =>
      `Encuentra tu parada por su nombre o número y consulta cuándo tarda tu bus en llegar.
      Tiempo de espera de autobuses interurbanos, EMT, Cercanías, Metro y Metro Ligero`,
  },
  [PageId.StopMapPage]: {
    title: () => 'Mapa de paradas | OK Transporte Madrid',
    description: () =>
      `Encuentra tu parada en el mapa y consulta cuánto le queda a tu bus para llegar.
      Tiempo de espera de autobuses interurbanos, EMT, Cercanías, Metro y Metro Ligero`,
  },
}
