import { ClientType } from 'common/core/param-config/param-app-config-models'
import { startGenericApp } from 'common/bootstrap/bootstrap'
import { StopSyncManager } from 'common/domain/stops/components/StopSyncManager/StopSyncManager'
import { getMadOtBrandConfig } from './brand-config-building'
import { madTranslationGroups } from './i18n/helpers/app-translations'
import { getAppRoutesConfig } from '../common-mad/core/components/AppRoutes'

export function startApp() {
  startGenericApp(
    getMadOtBrandConfig,
    madTranslationGroups,
    getAppRoutesConfig,
    'otm',
    true,
    (paramAppConfig) => [ClientType.Web].includes(paramAppConfig.clientType),
    StopSyncManager,
  )
}
