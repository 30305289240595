import { FC, PropsWithChildren, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import BottomLayout from 'common/core/page-fragments/BottomLayout/BottomLayout'

interface Props {
  AddtionalWrapperComponent: FC<PropsWithChildren> | undefined
}

export const AppLayout: FC<Props> = ({ AddtionalWrapperComponent }) => {
  function renderAdditionalWrapper(children: ReactNode) {
    if (AddtionalWrapperComponent) {
      return <AddtionalWrapperComponent>{children}</AddtionalWrapperComponent>
    }
    return children
  }

  return <BottomLayout>{renderAdditionalWrapper(<Outlet />)}</BottomLayout>
}
