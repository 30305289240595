export class GA4Service {
  public static initialize(
    gaMeasurementId: string,
    enabled: boolean,
    initialUserProperties: UserProperties,
    windowToAttach: Window = window,
  ) {
    if (!enabled) {
      // eslint-disable-next-line no-param-reassign
      ;(windowToAttach as any)[`ga-disable-${gaMeasurementId}`] = true
    }
    const gtag = GA4Service.createGtag(windowToAttach)
    gtag('js', new Date())
    // gtag('config', gaMeasurementId, { debug_mode: true })
    gtag('config', gaMeasurementId)
    gtag('set', 'user_properties', initialUserProperties)
    GA4Service.loadScript(windowToAttach, gaMeasurementId)
    return new GA4Service(gtag)
  }

  private static createGtag(windowToAttach: Window) {
    const windowAsAny = windowToAttach as any
    windowAsAny.dataLayer = windowAsAny.dataLayer || []
    const gtag = function gtagFn() {
      // eslint-disable-next-line prefer-rest-params
      windowAsAny.dataLayer.push(arguments)
    } as Gtag.Gtag
    windowAsAny.gtag = gtag
    return gtag
  }

  private static loadScript(windowToAttach: Window, gaMeasurementId: string) {
    const script = windowToAttach.document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`
    windowToAttach.document.head.appendChild(script)
  }

  private constructor(private gtag: Gtag.Gtag) {}

  public sendEvent(eventName: string, params?: EventParams) {
    // console.log('GA4 event:', eventName, params)
    this.gtag('event', eventName, params)
  }

  public setUserProperties(properties: UserProperties) {
    this.gtag('set', 'user_properties', properties)
  }
}

/* Make sure that all these names are present in the GA4 event custom dimensions */
type UserProperties = Partial<
  Record<
    | 'npm_package_version'
    | 'android_client_version'
    | 'ios_client_version'
    | 'is_logged_in'
    | 'locale'
    | 'theme'
    | 'allow_ad_alert'
    | 'ot_browser'
    | 'ot_os',
    string | number | undefined
  >
>

/* Make sure that all these names are present in the GA4 event custom dimensions */
type EventParams = Partial<
  Record<
    | 'error_code'
    | 'error_message'
    | 'subscription_id'
    | 'base_plan_id'
    | 'place'
    | 'stop_code'
    | 'stop_type'
    | 'line_code'
    | 'line_dir'
    | 'theme'
    | 'locale'
    | 'action'
    | 'campaign'
    | 'search_term'
    | 'text'
    | 'label'
    | 'url'
    | 'date'
    | 'ot_browser'
    | 'ot_os'
    | 'value',
    string | number | undefined
  >
>
