export class StopsRouteManager {
  static getStopsUrl() {
    return '/paradas'
  }

  static getStopUrl(stopCode: string) {
    return `/paradas/${stopCode}`
  }

  static getStopUrlDef() {
    return '/paradas/:stopCode'
  }

  static mapStopParamName = 'codParada'

  static mapLineNumberParamName = 'numLinea'

  static mapStopTypeNumCodeParamName = 'numTParada'

  static getStopsMapUrl(
    selectedStopCode?: string,
    stopTypeNumCode?: string,
    lineNumber?: string,
  ) {
    const sharedPart = '/paradas/mapa'
    if (selectedStopCode) {
      return `${sharedPart}?${StopsRouteManager.mapStopParamName}=${selectedStopCode}`
    }
    if (stopTypeNumCode && lineNumber) {
      const stopTypePart = `${StopsRouteManager.mapStopTypeNumCodeParamName}=${stopTypeNumCode}`
      const lineNumberPart = `${StopsRouteManager.mapLineNumberParamName}=${lineNumber}`
      return `${sharedPart}?${stopTypePart}&${lineNumberPart}`
    }
    return sharedPart
  }

  static getStopsSearchUrl() {
    return '/paradas/buscar'
  }

  static getLinesUrl() {
    return '/lineas'
  }

  static getLineUrl(lineCode: string) {
    return `/lineas/${lineCode}`
  }

  static getLineUrlDef() {
    return '/lineas/:lineCode'
  }
}
