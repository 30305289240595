import { CommunicatorResponseError } from './errors'

interface PromiseCallbacks<ResponsePayload> {
  success: (value: ResponsePayload, messageId: string) => void
  error: (errorPayload: string, messageId: string) => void
}

export class GenericCommunicator<RequestPayload, ResponsePayload> {
  private messageIdConunter = 0

  private callbackMap: {
    [messageId: string]: PromiseCallbacks<ResponsePayload>
  } = {}

  private static timeoutInMilliseconds = 10 * 1000

  constructor(
    private requestFunction: (
      payload: RequestPayload,
      messageId: string,
    ) => void,
    responseSuccessSubscrptionFunction: (
      callback: (payload: ResponsePayload, messageId: string) => void,
    ) => void,
    responseErrorSubscrptionFunction?: (
      callback: (payload: string, messageId: string) => void,
    ) => void,
  ) {
    responseSuccessSubscrptionFunction((value, messageId) => {
      this.callbackMap[messageId]?.success(value, messageId)
    })
    responseErrorSubscrptionFunction?.((value, messageId) => {
      this.callbackMap[messageId]?.error(value, messageId)
    })
  }

  public async execute(requestPayload: RequestPayload) {
    const newMessageId = this.messageIdConunter + 1
    this.messageIdConunter = newMessageId
    const stringMessageId = newMessageId.toString()

    this.requestFunction(requestPayload, stringMessageId)
    return new Promise<ResponsePayload>((resolve, reject) => {
      this.callbackMap[stringMessageId] = {
        success: (value) => {
          delete this.callbackMap[stringMessageId]
          resolve(value)
        },
        error: (errorPayload) => {
          delete this.callbackMap[stringMessageId]
          reject(convertPayloadToError(errorPayload))
        },
      }
      setTimeout(() => {
        reject(
          new Error(
            `Timeout: native app has not executed the callback with '${stringMessageId}' message ID.`,
          ),
        )
      }, GenericCommunicator.timeoutInMilliseconds)
    })
  }
}

interface ResponseErrorPayload {
  errorCode: string
  errorMessage: string
}

function convertPayloadToError(errorPayloadString: string) {
  const payload = parseJsonSafely(errorPayloadString)
  if (payload) {
    const castPayload = payload as ResponseErrorPayload
    if (castPayload.errorCode && castPayload.errorMessage) {
      return new CommunicatorResponseError(
        castPayload.errorCode,
        castPayload.errorMessage,
      )
    }
    return new Error('Parsed error payload object is missing required fields.')
  }
  return new Error('Error payload string is not valid JSON')
}

function parseJsonSafely(value: string) {
  try {
    return JSON.parse(value)
  } catch {
    return undefined
  }
}
