import { createContext, useContext } from 'react'
import { UiPrefs } from './models'

export interface UiPrefsContextModel {
  uiPrefs: UiPrefs
  mergeUiPrefs: (newPrefs: Partial<UiPrefs>) => void
}

function createUiPrefsContext() {
  const Context = createContext<UiPrefsContextModel | null>(null)
  Context.displayName = 'UiPrefsContext'
  return Context
}

export const UiPrefsContext = createUiPrefsContext()

export default function useUiPrefsContext() {
  const context = useContext(UiPrefsContext)
  if (!context) {
    throw new Error('UiPrefsContext is not provided.')
  }
  return context
}
