import UAParser from 'ua-parser-js'

export function isAndroidOrIos() {
  return isAndroid() || isIos()
}

export function isAndroid() {
  const osName = new UAParser().getOS().name?.toLowerCase()
  return osName?.includes('android')
}

export function isIos() {
  const osName = new UAParser().getOS().name?.toLowerCase()
  return osName === 'ios'
}
