import {
  BrandConfig,
  NavbarItem,
} from 'common/core/brand-config/brand-config.models'
import {
  ClientType,
  ParamAppConfig,
} from 'common/core/param-config/param-app-config-models'
import { BuildtimeConfig } from 'common/core/buildtime-config/buildtime-config-models'
import { AppServicesBuilder } from 'common/core/app-services/AppServicesBuilder'
import { CityId } from 'common/core/firebase/firestore-models'
import { DIContainer } from 'common/shared/deps-injection/DepsContainer'
import { pageMetadataCollection } from 'common-mad/core/helpers/page-metadata'
import MadDisplayAdWrapper from 'common-mad/ads/components/MadDisplayAdWrapper/MadDisplayAdWrapper'
import { MadAdsenseSlot } from 'common-mad/ads/models/mad-ads-models'
import { MadridNfcTransportCardDepsToken } from 'common/domain/nfc-transport-card/di-tokens'
import { NfcTransportCardApiClient } from 'common/domain/nfc-transport-card/services/nfc-transport-card-api-client/nfc-transport-card-api-client'
import { GaIdMapping } from 'common/core/ga4/GA4ServiceBuilder'
import { StopDeps, StopDepsToken } from 'common/domain/stops/deps/stop-deps'
import { StopServicesInitConfig } from 'common/domain/stops/deps/stop-services-builder'
import { PremiumDepsToken } from 'common/core/premium/deps'
import { OtGplayBillingClient } from 'common/core/premium/ot-gplay-billing-client/ot-gplay-billing-client'
import { FirestoreEntitlementsClient } from 'common/core/premium/firestore-entitlements-client/FirestoreEntitlementsClient'
import { ServerTimeDepsToken } from 'common/core/server-time/deps'
import { ServerTimeClient } from 'common/core/server-time/server-time-client'
import { PostersDepsToken } from 'common/domain/posters/deps'
import { CoreRouteManager } from 'common/core/router/CoreRouteManager'
import { MadInfoRouteManager } from 'common-mad/core/services/route-manager/MadInfoRouteManager'
import { buildMadStopDeps } from './stop-deps-building'

export interface MadConfigInput {
  buildtimeConfig: BuildtimeConfig
  appParamConfig: ParamAppConfig
  allowedStopTypeCodes: StopServicesInitConfig['allowedStopTypeCodes']
  allowedTransportTypeCodes: StopServicesInitConfig['allowedTransportTypeCodes']
  isGplayBillingAllowed: BrandConfig['common']['isGplayBillingAllowed']
  localStoragePrefix: string
  cityId: CityId
  stopListFileUrl: string
  lineListFileUrl: string
  gaIdMapping: GaIdMapping
  projectId: BrandConfig['common']['projectId']
  homeName: BrandConfig['common']['homeName']
  contactEmail: BrandConfig['common']['contactEmail']
  appIconImgUrl: BrandConfig['common']['appLandingPage']['appIconImgUrl']
  appDescriptionText: BrandConfig['common']['appLandingPage']['appDescriptionText']
  defaultColorTheme: BrandConfig['common']['defaultColorTheme']
  allowChatButtonOnSettingsPage: BrandConfig['common']['helpers']['allowChatButtonOnSettingsPage']
  allowChatButtonOnHomePage: BrandConfig['common']['helpers']['allowChatButtonOnHomePage']
  allowChatButtonOnStopSearchPage: BrandConfig['common']['helpers']['allowChatButtonOnStopSearchPage']
  allowChatButtonOnStopPage: BrandConfig['common']['helpers']['allowChatButtonOnStopPage']
  popularStops: StopDeps['helpers']['popularStops']
  stopSeacrhFieldPlaceholder: StopDeps['helpers']['stopSeacrhFieldPlaceholder']
  composeSelectStopSecondaryText: StopDeps['helpers']['composeSelectStopSecondaryText']
  navbarItems: NavbarItem[]
  shouldShowAboutUsSection?: BrandConfig['common']['shouldShowAboutUsSection']
}

export function buildMadBrandConfig(input: MadConfigInput): BrandConfig {
  DIContainer.set(ServerTimeDepsToken, {
    servertTimeClient: new ServerTimeClient(),
  })

  DIContainer.set(MadridNfcTransportCardDepsToken, {
    nfcTransportCardApiClient: new NfcTransportCardApiClient(),
  })

  const appServices = new AppServicesBuilder(
    input.buildtimeConfig,
    input.appParamConfig,
    {
      localStoragePrefix: input.localStoragePrefix,
      cityId: input.cityId,
      allowInterstitialAds: () => true,
      firebaseOptions: {
        apiKey: 'AIzaSyALZoZ-5HIsW4sk15nkNFmgy7V56mPu9qI',
        authDomain: 'oktransportemadrid.com',
        databaseURL: 'https://otm-project-23327.firebaseio.com',
        projectId: 'otm-project-23327',
        storageBucket: 'otm-project-23327.appspot.com',
        messagingSenderId: '1097746999203',
        appId: '1:1097746999203:web:7a5c738f6a754cb5f61c16',
        measurementId: 'G-HVNQ7H4341',
      },
      gaIdMapping: input.gaIdMapping,
    },
  ).buildAppServices()

  DIContainer.set(PremiumDepsToken, {
    otGplayBillingClient: new OtGplayBillingClient(),
    firestoreEntitlementsClient: new FirestoreEntitlementsClient(
      appServices.firestore,
      input.cityId,
    ),
  })

  DIContainer.set(
    StopDepsToken,
    buildMadStopDeps({
      buildtimeConfig: input.buildtimeConfig,
      appParamConfig: input.appParamConfig,
      allowedStopTypeCodes: input.allowedStopTypeCodes,
      allowedTransportTypeCodes: input.allowedTransportTypeCodes,
      localStoragePrefix: input.localStoragePrefix,
      stopListFileUrl: input.stopListFileUrl,
      lineListFileUrl: input.lineListFileUrl,
      popularStops: input.popularStops,
      stopSeacrhFieldPlaceholder: input.stopSeacrhFieldPlaceholder,
      composeSelectStopSecondaryText: input.composeSelectStopSecondaryText,
      userFirestoreClientWithCache: appServices.userFirestoreClientWithCache,
    }),
  )

  DIContainer.set(PostersDepsToken, {
    getPosterParentPageUrl: () => MadInfoRouteManager.getInfoUrl(),
  })

  return {
    common: {
      localStoragePrefix: input.localStoragePrefix,
      projectId: input.projectId,
      homeName: input.homeName,
      contactEmail: input.contactEmail,
      pageMetadataCollection,
      bottomBannerAdSlot: MadAdsenseSlot.BottomBanner,
      shouldShowAboutUsSection: input.shouldShowAboutUsSection,
      isAuthAllowed: () => true,
      isGplayBillingAllowed: input.isGplayBillingAllowed,
      defaultColorTheme: input.defaultColorTheme,
      navbar: {
        items: input.navbarItems,
      },
      urls: {
        getSettingsUrl: CoreRouteManager.getSettingsUrl,
      },
      services: {
        buildtimeConfig: appServices.buildtimeConfig,
        paramAppConfig: appServices.paramAppConfig,
        clientAppInfo: appServices.clientAppInfo,
        uiPreferencesStore: appServices.uiPreferencesStore,
        interstitialAdsManager: appServices.interstitialAdsManager,
        appUsageTracker: appServices.appUsageTracker,
        i18nPersistor: appServices.i18nPersistor,
        firebaseApp: appServices.firebaseApp,
        userFirestoreClientWithCache: appServices.userFirestoreClientWithCache,
        ga4Service: appServices.ga4Service,
        cachedUserStore: appServices.cachedUserStore,
      },
      components: {
        displayAdWrapperComponent: MadDisplayAdWrapper,
      },
      helpers: {
        allowChatButtonOnSettingsPage: input.allowChatButtonOnSettingsPage,
        allowChatButtonOnHomePage: input.allowChatButtonOnHomePage,
        allowChatButtonOnStopSearchPage: input.allowChatButtonOnStopSearchPage,
        allowChatButtonOnStopPage: input.allowChatButtonOnStopPage,
        isAdsenseUsedForGoogleBottomAd: (paramAppConfig) =>
          [ClientType.Web].includes(paramAppConfig.clientType),
        allowPrivateWebAds: () => false,
      },
      appLandingPage: {
        metadataTitle: () => 'Descarga gratis la app de OK Transporte Madrid',
        metadataDescription: () =>
          'Consulta cuánto tarda el bus en llegar a tu parada',
        appDescriptionText: input.appDescriptionText,
        appIconImgUrl: input.appIconImgUrl,
      },
    },
  }
}
