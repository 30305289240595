import { Project, ProjectId } from 'common/core/projects/projects.models'
import { CoreRouteManager } from '../router/CoreRouteManager'

function composeAppLandingPageUrl(websiteUrl: string) {
  return `${websiteUrl}${CoreRouteManager.getAppUniversalPageUrl()}`
}

export function getProjects(): Project[] {
  const madWebsiteUrl = 'https://oktransportemadrid.com'
  const madCercaniasWebsiteUrl = 'https://cercanias.oktransportemadrid.com'
  const madBusWebsiteUrl = 'https://buses.oktransportemadrid.com'
  const madTransportCardWebsiteUrl = 'https://ttp.oktransportemadrid.com'
  const bcnWebsiteUrl = 'https://oktransportebarcelona.com'
  const bilWebsiteUrl = 'https://bilbao.oktransit.app'
  return [
    {
      projectId: ProjectId.OkTransitMadrid,
      websiteUrl: madWebsiteUrl,
      universalUrl: composeAppLandingPageUrl(madWebsiteUrl),
      getName: () => 'OK Transporte Madrid',
      androidPackageName: 'com.oktransportemadrid',
      androidPlayStoreUrl: (utmSource: string) =>
        `https://play.google.com/store/apps/details?id=com.oktransportemadrid&gl=ES&referrer=utm_source%3D${utmSource}`,
      androidHuaweiStoreUrl: 'https://appgallery.huawei.com/app/C102250491',
      iosStoreUrl:
        'https://apps.apple.com/us/app/madrid-bus-metro-cercan%C3%ADas/id1540996824',
    },
    {
      projectId: ProjectId.OkTransitMadridCercanias,
      websiteUrl: madCercaniasWebsiteUrl,
      universalUrl: composeAppLandingPageUrl(madCercaniasWebsiteUrl),
      getName: () => 'OK Cercanías Madrid',
      androidPackageName: 'com.oktransportemadrid.cercanias',
      androidPlayStoreUrl: (utmSource: string) =>
        `https://play.google.com/store/apps/details?id=com.oktransportemadrid.cercanias&gl=ES&referrer=utm_source%3D${utmSource}`,
      androidHuaweiStoreUrl: 'https://appgallery.huawei.com/app/C102250491', // TODO: Change this
      iosStoreUrl:
        'https://apps.apple.com/us/app/madrid-bus-metro-cercan%C3%ADas/id1540996824', // TODO: Change this
    },
    {
      projectId: ProjectId.OkTransitMadridBus,
      websiteUrl: madBusWebsiteUrl,
      universalUrl: composeAppLandingPageUrl(madBusWebsiteUrl),
      getName: () => 'OK Transporte Madrid - Buses',
      androidPackageName: 'com.oktransportemadrid.buses',
      androidPlayStoreUrl: (utmSource: string) =>
        `https://play.google.com/store/apps/details?id=com.oktransportemadrid.buses&gl=ES&referrer=utm_source%3D${utmSource}`,
      androidHuaweiStoreUrl: 'https://appgallery.huawei.com/app/C102250491', // TODO: Change this
      iosStoreUrl:
        'https://apps.apple.com/us/app/madrid-bus-metro-cercan%C3%ADas/id1540996824', // TODO: Change this
    },
    {
      projectId: ProjectId.OkTransitMadridTransportCard,
      websiteUrl: madTransportCardWebsiteUrl,
      universalUrl: composeAppLandingPageUrl(madTransportCardWebsiteUrl),
      getName: () => 'OK Transporte Madrid - TTP',
      androidPackageName: 'com.oktransportemadrid.transportcard',
      androidPlayStoreUrl: (utmSource: string) =>
        `https://play.google.com/store/apps/details?id=com.oktransportemadrid.publictransportcard&gl=ES&referrer=utm_source%3D${utmSource}`,
      androidHuaweiStoreUrl: 'https://appgallery.huawei.com/app/C102250491', // TODO: Change this
      iosStoreUrl:
        'https://apps.apple.com/us/app/madrid-bus-metro-cercan%C3%ADas/id1540996824', // TODO: Change this
    },
    {
      projectId: ProjectId.OkTransitBarcelona,
      websiteUrl: bcnWebsiteUrl,
      universalUrl: composeAppLandingPageUrl(bcnWebsiteUrl),
      getName: () => 'OK Transporte Barcelona',
      androidPackageName: 'com.oktransportebarcelona',
      androidPlayStoreUrl: (utmSource: string) =>
        `https://play.google.com/store/apps/details?id=com.oktransportebarcelona&gl=ES&referrer=utm_source%3D${utmSource}`,
      androidHuaweiStoreUrl: 'https://appgallery.huawei.com/app/C103494747',
      iosStoreUrl:
        'https://apps.apple.com/us/app/barcelona-bus-tiempo-real/id1547789968',
    },
    {
      projectId: ProjectId.OkTransitBilbao,
      websiteUrl: bilWebsiteUrl,
      universalUrl: composeAppLandingPageUrl(bilWebsiteUrl),
      getName: () => 'OK Transit Bilbao',
      androidPackageName: 'app.oktransit.bilbao',
      androidPlayStoreUrl: (utmSource: string) =>
        `https://play.google.com/store/apps/details?id=app.oktransit.bilbao&gl=ES&referrer=utm_source%3D${utmSource}`,
      androidHuaweiStoreUrl: 'https://appgallery.huawei.com/app/C104367415',
      iosStoreUrl:
        'https://apps.apple.com/us/app/bilbao-bus-tiempo-real/id1563191790',
    },
  ]
}

export function getProjectById(projectId: ProjectId) {
  const foundProject = getProjects().find(
    (project) => project.projectId === projectId,
  )
  if (!foundProject) {
    throw new Error(`Cannot find project with '${projectId}' id.`)
  }
  return foundProject
}
