import axios from 'axios'
import { SessionCreationResponse } from './models'

export class SessionCreationApiClient {
  public async createSession() {
    const url =
      'https://api.oktransportemadrid.com/v1/nfc-transport-card/sessions'
    const response = await axios.post<SessionCreationResponse>(url, undefined, {
      timeout: 15 * 1000,
    })
    return response.data
  }
}
