import { Navigate, RouteObject } from 'react-router-dom'
import { IntlShape } from 'react-intl'
import { MadInfoRouteManager } from '../services/route-manager/MadInfoRouteManager'

export const getMadInfoRoutesConfig = (intl: IntlShape) =>
  [
    {
      path: MadInfoRouteManager.getInfoUrl(),
      async lazy() {
        const { InfoPage } = await import(
          'common-mad/fares/components/InfoPage/InfoPage'
        )
        return { Component: InfoPage }
      },
    },
    {
      path: MadInfoRouteManager.getChristmasSchedulesUrl2324(),
      async lazy() {
        const { ChristmasSchedulesPage } = await import(
          'common-mad/christmas/components/CristmasSchedulesPage/CristmasSchedulesPage'
        )
        return { Component: ChristmasSchedulesPage }
      },
    },
    {
      path: MadInfoRouteManager.getLostAndFoundUrl(),
      async lazy() {
        const { LostAndFoundPage } = await import(
          'common-mad/fares/components/LostAndFoundPage/LostAndFoundPage'
        )
        return { Component: LostAndFoundPage }
      },
    },
    {
      path: '/tarifas',
      element: <Navigate to={MadInfoRouteManager.getFaresUrl()} replace />,
    },
    {
      path: MadInfoRouteManager.getFaresUrl(),
      async lazy() {
        const { FaresPage } = await import(
          'common-mad/fares/components/FaresPage/FaresPage'
        )
        return { Component: FaresPage }
      },
    },
    {
      path: MadInfoRouteManager.getInterurbanFaresUrl(),
      async lazy() {
        const { InterurbanFaresPage } = await import(
          'common-mad/fares/components/InterurbanFaresPage/InterurbanFaresPage'
        )
        return { Component: InterurbanFaresPage }
      },
    },
    {
      path: '/tarifas/buses-interurbanos',
      element: (
        <Navigate to={MadInfoRouteManager.getInterurbanFaresUrl()} replace />
      ),
    },
    {
      path: MadInfoRouteManager.getEmtFaresUrl(),
      async lazy() {
        const { EmtFaresPage } = await import(
          'common-mad/fares/components/EmtFaresPage/EmtFaresPage'
        )
        return { Component: EmtFaresPage }
      },
    },
    {
      path: '/tarifas/buses-emt',
      element: <Navigate to={MadInfoRouteManager.getEmtFaresUrl()} replace />,
    },

    {
      path: MadInfoRouteManager.getCercaniasFaresUrl(),
      async lazy() {
        const { CercaniasFaresPage } = await import(
          'common-mad/fares/components/CercaniasFaresPage/CercaniasFaresPage'
        )
        return { Component: CercaniasFaresPage }
      },
    },
    {
      path: '/tarifas/cercanias',
      element: (
        <Navigate to={MadInfoRouteManager.getCercaniasFaresUrl()} replace />
      ),
    },

    {
      path: MadInfoRouteManager.getMetroFaresUrl(),
      async lazy() {
        const { MetroFaresPage } = await import(
          'common-mad/fares/components/MetroFaresPage/MetroFaresPage'
        )
        return { Component: MetroFaresPage }
      },
    },
    {
      path: '/tarifas/metro',
      element: <Navigate to={MadInfoRouteManager.getMetroFaresUrl()} replace />,
    },

    {
      path: MadInfoRouteManager.getLightMetroFaresUrl(),
      async lazy() {
        const { LightMetroFaresPage } = await import(
          'common-mad/fares/components/LightMetroFaresPage/LightMetroFaresPage'
        )
        return { Component: LightMetroFaresPage }
      },
    },
    {
      path: '/tarifas/metro-ligero',
      element: (
        <Navigate to={MadInfoRouteManager.getLightMetroFaresUrl()} replace />
      ),
    },

    {
      path: MadInfoRouteManager.getTransportPassFaresUrl(),
      async lazy() {
        const { TransportPassFaresPage } = await import(
          'common-mad/fares/components/TransportPassFaresPage/TransportPassFaresPage'
        )
        return { Component: TransportPassFaresPage }
      },
    },
    {
      path: '/tarifas/abono-transporte',
      element: (
        <Navigate to={MadInfoRouteManager.getTransportPassFaresUrl()} replace />
      ),
    },

    {
      path: '/planos',
      element: <Navigate to={MadInfoRouteManager.getInfoUrl()} replace />,
    },
    {
      path: MadInfoRouteManager.getMetroSchematicMapUrl(),
      async lazy() {
        const { MetroSchematicPosterPage } = await import('./mad-poster-pages')
        return { Component: MetroSchematicPosterPage }
      },
    },
    {
      path: MadInfoRouteManager.getMetroMapUrl(),
      async lazy() {
        const { MetroPosterPage } = await import('./mad-poster-pages')
        return { Component: MetroPosterPage }
      },
    },
    {
      path: MadInfoRouteManager.getCercaniasMapUrl(),
      async lazy() {
        const { CercaniasSchematicPosterPage } = await import(
          './mad-poster-pages'
        )
        return { Component: CercaniasSchematicPosterPage }
      },
    },
    {
      path: MadInfoRouteManager.getMadridTransportPosterUrl(),
      async lazy() {
        const { MultiTransportPosterPage } = await import('./mad-poster-pages')
        return { Component: MultiTransportPosterPage }
      },
    },
    {
      path: MadInfoRouteManager.getEmtNocturnalMapUrl(),
      async lazy() {
        const { NocturnalEmtPosterPage } = await import('./mad-poster-pages')
        return { Component: NocturnalEmtPosterPage }
      },
    },
    {
      path: MadInfoRouteManager.getInterurbanNocturnalMapUrl(),
      async lazy() {
        const { NocturnalSuburbanPosterPage } = await import(
          './mad-poster-pages'
        )
        return { Component: NocturnalSuburbanPosterPage }
      },
    },
    {
      path: '/mapa-buses-emt',
      element: (
        <Navigate
          to={MadInfoRouteManager.getMadridTransportPosterUrl()}
          replace
        />
      ),
    },
    {
      path: '/mapa-transporte-madrid',
      element: (
        <Navigate
          to={MadInfoRouteManager.getMadridTransportPosterUrl()}
          replace
        />
      ),
    },
    {
      path: '/planos/mapa-transporte-madrid',
      element: (
        <Navigate
          to={MadInfoRouteManager.getMadridTransportPosterUrl()}
          replace
        />
      ),
    },
    {
      path: '/plano-metro',
      element: (
        <Navigate to={MadInfoRouteManager.getMetroSchematicMapUrl()} replace />
      ),
    },
    {
      path: '/planos/plano-metro',
      element: (
        <Navigate to={MadInfoRouteManager.getMetroSchematicMapUrl()} replace />
      ),
    },
    {
      path: '/mapa-metro',
      element: <Navigate to={MadInfoRouteManager.getMetroMapUrl()} replace />,
    },
    {
      path: '/planos/mapa-metro',
      element: <Navigate to={MadInfoRouteManager.getMetroMapUrl()} replace />,
    },
    {
      path: '/plano-cercanias',
      element: (
        <Navigate to={MadInfoRouteManager.getCercaniasMapUrl()} replace />
      ),
    },
    {
      path: '/planos/plano-cercanias',
      element: (
        <Navigate to={MadInfoRouteManager.getCercaniasMapUrl()} replace />
      ),
    },
    {
      path: '/mapa-buses-emt-nocturnos',
      element: (
        <Navigate to={MadInfoRouteManager.getEmtNocturnalMapUrl()} replace />
      ),
    },
    {
      path: '/planos/mapa-buses-emt-nocturnos',
      element: (
        <Navigate to={MadInfoRouteManager.getEmtNocturnalMapUrl()} replace />
      ),
    },
    {
      path: '/mapa-buses-interurbanos-nocturnos',
      element: (
        <Navigate
          to={MadInfoRouteManager.getInterurbanNocturnalMapUrl()}
          replace
        />
      ),
    },
    {
      path: '/planos/mapa-buses-interurbanos-nocturnos',
      element: (
        <Navigate
          to={MadInfoRouteManager.getInterurbanNocturnalMapUrl()}
          replace
        />
      ),
    },
  ] as RouteObject[]
