import { FC, useCallback, useEffect, useState } from 'react'
import { User } from 'firebase/auth'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { StopFav } from 'common/domain/stops/services/stop-favs-store/StopFavsStore'
import PageLayout from 'common/core/page-fragments/PageLayout/PageLayout'
import ActionBar from 'common/core/page-fragments/ActionBar/ActionBar'
import Container from 'common/core/ui/components/Container/Container'
import { FormattedMessage } from 'react-intl'
import { InstantlyActive } from 'common/core/ui/components/InstantlyActive/InstantlyActive'
import s from './StopSyncExecutor.module.scss'

interface Props {
  user: User
  localStopFavs: StopFav[]
  onSyncEnd: () => void
}

export const StopSyncExecutor: FC<Props> = ({
  user,
  localStopFavs,
  onSyncEnd,
}) => {
  const {
    common: {
      services: { userFirestoreClientWithCache, ga4Service },
    },
  } = useBrandConfigContext()

  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false)
  const [firestoreStopFavs, setFirestoreStopFavs] = useState<StopFav[]>()

  const createStopFavsInFirestore = useCallback(
    async (stopFavs: StopFav[]) => {
      ga4Service.sendEvent('syncUploadStopFavsStart')
      try {
        await userFirestoreClientWithCache.setStopFavs(user, stopFavs)
      } catch (error) {
        ga4Service.sendEvent('syncUploadStopFavsError')
        throw error
      }
      onSyncEnd()
    },
    [user, onSyncEnd, userFirestoreClientWithCache, ga4Service],
  )

  const getStopFavs = useCallback(async () => {
    try {
      const userDocWrapper =
        await userFirestoreClientWithCache.getUserDocWrapper(user)
      return userDocWrapper.getStopFavs()
    } catch (error) {
      ga4Service.sendEvent('syncGetStopFavsError')
      throw error
    }
  }, [user, userFirestoreClientWithCache, ga4Service])

  const performSync = useCallback(async () => {
    const stopFavs = await getStopFavs()
    if (stopFavs.length > 0) {
      setFirestoreStopFavs(stopFavs)
      setIsMergeModalOpen(true)
      ga4Service.sendEvent('syncMergeMsgShow')
    } else {
      createStopFavsInFirestore(localStopFavs)
    }
  }, [createStopFavsInFirestore, localStopFavs, getStopFavs, ga4Service])

  useEffect(() => {
    performSync()
  }, [performSync])

  function mergeLocalAndFirestoreStopFavs() {
    const firestoreStopFavsNotIncludedLocally = firestoreStopFavs!.filter(
      (firestoreFav) => {
        const isFirestoreFavFoundLocally = localStopFavs.find(
          (localFav) => localFav.stopCode === firestoreFav.stopCode,
        )
        return !isFirestoreFavFoundLocally
      },
    )
    const mergedFavs = [
      ...firestoreStopFavsNotIncludedLocally,
      ...localStopFavs,
    ]
    createStopFavsInFirestore(mergedFavs)
    ga4Service.sendEvent('syncMergeSelectUploadLocalFavs')
  }

  function deleteLocalStopFavs() {
    onSyncEnd()
    ga4Service.sendEvent('syncMergeSelectDeleteLocalFavs')
  }

  function renderMergeModal() {
    return (
      <div className={s.card}>
        <FormattedMessage id="sync_intro" />
        <InstantlyActive
          as="button"
          type="button"
          onClick={mergeLocalAndFirestoreStopFavs}
          className={s.button}
        >
          <FormattedMessage id="sync_btn_upload_local_stop_favs" />
        </InstantlyActive>
        <InstantlyActive
          as="button"
          type="button"
          onClick={deleteLocalStopFavs}
          className={s.button}
        >
          <FormattedMessage id="sync_btn_delete_local_stop_favs" />
        </InstantlyActive>
      </div>
    )
  }

  return (
    <PageLayout
      actionbar={<ActionBar title={<FormattedMessage id="sync_title" />} />}
    >
      <Container>{isMergeModalOpen && renderMergeModal()}</Container>
    </PageLayout>
  )
}
