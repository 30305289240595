import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { UiPrefs, defaultValues } from './models'
import { UiPrefsContext } from './ui-prefs-context'

export const UiPrefsManager: FC<PropsWithChildren> = ({ children }) => {
  const {
    common: {
      services: { uiPreferencesStore, ga4Service },
    },
  } = useBrandConfigContext()

  const [uiPrefs, setUiPrefs] = useState<UiPrefs>(
    uiPreferencesStore.getPrefsWithDefaults(),
  )

  const handleStateChange = useCallback(() => {
    setUiPrefs(uiPreferencesStore.getPrefsWithDefaults())
  }, [uiPreferencesStore])

  useEffect(() => {
    uiPreferencesStore.onStateChange.addListener(handleStateChange)
    return () => {
      uiPreferencesStore.onStateChange.removeListener(handleStateChange)
    }
  }, [handleStateChange, uiPreferencesStore])

  const allowInterstitialAdAlertRawValue =
    uiPreferencesStore.getPrefs().allowInterstitialAdAlert

  const calculateInterstitalAdAlertAxValue = useCallback(() => {
    if (allowInterstitialAdAlertRawValue !== undefined) {
      return allowInterstitialAdAlertRawValue ? 'true' : 'false'
    }
    return `defaultValue (${defaultValues.allowInterstitialAdAlert})`
  }, [allowInterstitialAdAlertRawValue])

  useEffect(() => {
    ga4Service.setUserProperties({
      allow_ad_alert: calculateInterstitalAdAlertAxValue(),
    })
  }, [calculateInterstitalAdAlertAxValue, ga4Service])

  const mergeUiPrefs = useCallback(
    (newPrefs: Partial<UiPrefs>) => {
      uiPreferencesStore.mergePrefs(newPrefs)
    },
    [uiPreferencesStore],
  )

  const contextValue = useMemo(
    () => ({
      uiPrefs,
      mergeUiPrefs,
    }),
    [uiPrefs, mergeUiPrefs],
  )

  return (
    <UiPrefsContext.Provider value={contextValue}>
      {children}
    </UiPrefsContext.Provider>
  )
}
