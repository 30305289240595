import { createContext, useContext } from 'react'
import { PremiumManagerContextModel } from './models'

function createPremiumManagerContext() {
  const Context = createContext<PremiumManagerContextModel | null>(null)
  Context.displayName = 'PremiumManagerContext'
  return Context
}

export const PremiumManagerContext = createPremiumManagerContext()

export default function usePremiumManagerContext() {
  const context = useContext(PremiumManagerContext)
  if (!context) {
    throw new Error('PremiumManagerContext is not provided.')
  }
  return context
}

export function useEnabledPremiumManagerContext() {
  const context = usePremiumManagerContext()
  if (!context.enabled) {
    throw new Error('PremiumManagerContext is not enabled.')
  }
  return context
}
