import { FC, PropsWithChildren, ReactNode } from 'react'
import { ReactComponent as ArrowRightIcon } from 'common/core/images/keyboard_arrow_right-24px.svg'
import s from './PrimaryOptionsItem.module.scss'

interface PrimaryOptionsItemProps {
  leftChildren?: ReactNode
}

const PrimaryOptionsItem: FC<PropsWithChildren<PrimaryOptionsItemProps>> = (
  props,
) => (
  <div className={s.root}>
    {props.leftChildren && (
      <div className={s.leftPart}>{props.leftChildren}</div>
    )}
    <div className={s.rightPart}>{props.children}</div>
    <ArrowRightIcon className={s.arrow} />
  </div>
)

export default PrimaryOptionsItem
