export function loadAdsenseScript() {
  const container = document.body || document.head || document.documentElement
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
  container.appendChild(script)
}

export function addNewAdToShow() {
  try {
    const w = window as any
    ;(w.adsbygoogle = w.adsbygoogle || []).push({})
  } catch (error) {
    // We swallow Adsense errors because in React 18 in development mode
    // with strict mode enabled we get this error:
    // "All ins elements in the DOM with class=adsbygoogle already have ads in them.".
    // This happens because in development mode all components
    // are mounted, unmounted and then mounted again. Although, for some reason
    // I don't yet understand, the components are first rendered twice, and then useEffect
    // are run twice. This means that after the two renders there are only one <ins /> tag,
    // but then useEffect is called twice and google adsense JS framework cannot find enough (two)
    // <ins /> tags in the DOM. This is not a problem in production mode because
    // the components are only mounted once.
    // I wasn't able to find a solution to this problem, since there is no mecahnism
    // in adsense js framework to "pop" ads that already have been "pushed". That would have
    // solve the problem, since we would be able to use that in the cleanup function of useEffect.
    // eslint-disable-next-line no-console
    console.error(error)
  }
}
