import classNames from 'classnames'
import {
  AppModal,
  AppModalSize,
} from 'common/core/ui/components/AppModal/AppModal'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { useIntl } from 'react-intl'
import CopyToClipboardButton from 'common/core/ui/components/CopyToClipboardButton/CopyToClipboardButton'
import s from './ContactModal.module.scss'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
}

const ContactModal = ({ isOpen, onRequestClose }: Props) => {
  const intl = useIntl()
  const {
    contactEmail,
    services: { ga4Service },
  } = useBrandConfigContext().common

  return (
    <AppModal
      isOpen={isOpen}
      title={intl.formatMessage({ id: 'contact_modal_title' })}
      shouldShowCloseButton
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      maxWidth={AppModalSize.Normal}
      renderContent={() => (
        <>
          {intl.formatMessage({ id: 'contact_modal_text' })}
          <input
            type="text"
            className={s.textarea}
            readOnly
            value={contactEmail}
          />
          <CopyToClipboardButton
            onCopy={() => {
              ga4Service.sendEvent('contactModal:copyToClipboard')
            }}
            textToShare={contactEmail}
            className={classNames(s.option, s.clipboard)}
          />
        </>
      )}
    />
  )
}

export default ContactModal
