export const transportCardMessagesInSpanish = {
  nfc_card_section_title: 'Saldo tarjeta transporte',
  nfc_card_update_app:
    'Actualiza la app para poder leer tu tarjeta de transporte público con NFC',
  nfc_card_update_app_button: 'Actualizar la app',
  nfc_card_purchase_date: 'Día de la carga',
  nfc_card_first_use_date: 'Día del primer uso',
  nfc_card_last_use_date: 'Último día válido',
  nfc_card_additional_recharge:
    'Además, has hecho una recarga adicional el día {date}',
  nfc_card_zone: 'Zona:',
  nfc_card_remaining_trips: 'Viajes restantes:',
  nfc_card_no_tickets: 'Esta tarjeta no tiene títulos cargados',
  nfc_card_serial_number: 'Número de serie:',
  nfc_card_reading_in_progress: 'Leyendo tarjeta',
  nfc_card_error_tag_disconnected:
    'No hemos podido leer la tarjeta porque se ha quitado muy rápido. Vuelve a ponerla en el lector.',
  nfc_card_reading_error:
    'Ha ocurrido un error al leer la tarjeta. Por favor, inténtalo de nuevo.',
  nfc_card_intro:
    'Acerca tu tarjeta de transporte público al lector NFC del dispositivo',
  nfc_card_nfc_disabled: 'El lector NFC está deshabilitado',
  nfc_card_open_nfc_settings: 'Ir a ajustes de NFC',
  nfc_card_nfc_not_supported: 'Este dispositivo no dispone de un lector NFC',
  nfc_card_read_by_code_button: '¿Puedo leer la tarjeta por número?',
  nfc_card_read_by_code_modal_title: 'Leer tarjeta por número',
  nfc_card_read_by_code_modal_content:
    '<p>Desafortunadamente la opción de leer la tarjeta por número ya no está disponible.</p><p>Esto es debido a que el Consorcio de Transportes de Madrid ha decidido retirar esa funcionalidad y ha deshabilitado el servidor que permitía hacerlo.</p><p>Por lo tanto actualmente las tarjetas de transporte solo se pueden leer por NFC. Es una pena pero desafortunadamente no podemos hacer nada al respecto.</p>',
}

export const transportCardMessagesInEnglish = {
  nfc_card_section_title: 'Transport card balance',
  nfc_card_update_app:
    'Update the app to be able to read your public transport card with NFC',
  nfc_card_update_app_button: 'Update the app',
  nfc_card_purchase_date: 'Purchase date',
  nfc_card_first_use_date: 'First use date',
  nfc_card_last_use_date: 'Last valid day',
  nfc_card_additional_recharge:
    'Additionally, you made an additional recharge on {date}',
  nfc_card_zone: 'Zone:',
  nfc_card_remaining_trips: 'Remaining trips:',
  nfc_card_no_tickets: 'This card has no tickets',
  nfc_card_serial_number: 'Serial number:',
  nfc_card_reading_in_progress: 'Reading card',
  nfc_card_error_tag_disconnected:
    "We couldn't read the card because it was removed too quickly. Please put it back on the reader.",
  nfc_card_reading_error:
    'An error occurred while reading the card. Please try again.',
  nfc_card_intro:
    "Approach your public transport card to the device's NFC reader",
  nfc_card_nfc_disabled: 'NFC reader is disabled',
  nfc_card_open_nfc_settings: 'Go to NFC settings',
  nfc_card_nfc_not_supported: 'This device does not have an NFC reader',
  nfc_card_read_by_code_button: 'Can I read the card by number?',
  nfc_card_read_by_code_modal_title: 'Read card by number',
  nfc_card_read_by_code_modal_content:
    "<p>Unfortunately, the option to read the card by number is no longer available.</p><p>This is because the Madrid Transport Consortium has decided to withdraw that functionality and has disabled the server that allowed it.</p><p>Therefore, transport cards can currently only be read by NFC. It's a pity but unfortunately, we can't do anything about it.</p>",
}
