/* eslint-disable max-classes-per-file */

declare global {
  interface Window {
    androidAppToWebviewBridge?: {
      onGetSharedPrefsJSONSuccess: (param: string, messageId: string) => void
      onSetSharedPrefsStringRecordSuccess: (messageId: string) => void
      onSetSharedPrefsNumberRecordSuccess: (messageId: string) => void
      onSetSharedPrefsBooleanRecordSuccess: (messageId: string) => void
      onDeleteSharedPrefsRecordSuccess: (messageId: string) => void
      onDeleteAllSharedPrefsSuccess: (messageId: string) => void

      onResetUMPConsentInformationSuccess: (messageId: string) => void
      onUMPPrivacyOptionsFormDismissedSuccess: (messageId: string) => void
      onUMPPrivacyOptionsFormDismissedError: (messageId: string) => void
      onUMPConsentFormDismissedSuccess: (messageId: string) => void
      onUMPConsentFormDismissedError: (messageId: string) => void
      onGetUMPConsentInformationJSONSuccess: (
        param: string,
        messageId: string,
      ) => void

      onGetPlayStoreReferrerJSONSuccess: (
        param: string,
        messageId: string,
      ) => void

      onShowInAppReviewPopupSuccess: (messageId: string) => void
      onShowInAppReviewPopupError: (
        errorCode: string,
        messageId: string,
      ) => void

      onNfcTransceiveCommandsSuccess: (
        responseData: string,
        messageId: string,
      ) => void
      onNfcTransceiveCommandsError: (
        errorJsonPayload: string,
        messageId: string,
      ) => void
      onNfcTagDiscovered: (messageId: string) => void
      onIsNfcSupportedSuccess: (response: boolean, messageId: string) => void
      onIsNfcEnabledSuccess: (response: boolean, messageId: string) => void
      onNfcEnablementChange: (response: boolean, messageId: string) => void

      onPurchasesUpdate: (payloadJson: string, messageId: string) => void
    }
  }
}

window.androidAppToWebviewBridge = {
  onGetSharedPrefsJSONSuccess: (param: string, messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onGetSharedPrefsJSONSuccess.executeCallbacks(
      param,
      messageId,
    )
  },
  onSetSharedPrefsStringRecordSuccess: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onSetSharedPrefsStringRecordSuccess.executeCallbacks(
      undefined,
      messageId,
    )
  },
  onSetSharedPrefsNumberRecordSuccess: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onSetSharedPrefsNumberRecordSuccess.executeCallbacks(
      undefined,
      messageId,
    )
  },
  onSetSharedPrefsBooleanRecordSuccess: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onSetSharedPrefsBooleanRecordSuccess.executeCallbacks(
      undefined,
      messageId,
    )
  },
  onDeleteSharedPrefsRecordSuccess: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onDeleteSharedPrefsRecordSuccess.executeCallbacks(
      undefined,
      messageId,
    )
  },
  onDeleteAllSharedPrefsSuccess: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onDeleteAllSharedPrefsSuccess.executeCallbacks(
      undefined,
      messageId,
    )
  },

  onResetUMPConsentInformationSuccess: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onResetUMPConsentInformationSuccess.executeCallbacks(
      undefined,
      messageId,
    )
  },
  onUMPPrivacyOptionsFormDismissedSuccess: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onUMPPrivacyOptionsFormDismissedSuccess.executeCallbacks(
      undefined,
      messageId,
    )
  },
  onUMPPrivacyOptionsFormDismissedError: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onUMPPrivacyOptionsFormDismissedError.executeCallbacks(
      undefined,
      messageId,
    )
  },
  onUMPConsentFormDismissedSuccess: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onUMPConsentFormDismissedSuccess.executeCallbacks(
      undefined,
      messageId,
    )
  },
  onUMPConsentFormDismissedError: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onUMPConsentFormDismissedError.executeCallbacks(
      undefined,
      messageId,
    )
  },
  onGetUMPConsentInformationJSONSuccess: (param: string, messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onGetUMPConsentInformationJSONSuccess.executeCallbacks(
      param,
      messageId,
    )
  },

  onGetPlayStoreReferrerJSONSuccess: (param: string, messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onGetPlayStoreReferrerJSONSuccess.executeCallbacks(
      param,
      messageId,
    )
  },

  onShowInAppReviewPopupSuccess: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onShowInAppReviewPopupSuccess.executeCallbacks(
      undefined,
      messageId,
    )
  },
  onShowInAppReviewPopupError: (erroCode: string, messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onShowInAppReviewPopupError.executeCallbacks(
      erroCode,
      messageId,
    )
  },

  onNfcTransceiveCommandsSuccess: (
    responseCommandsJsonArray: string,
    messageId: string,
  ) => {
    AndroidAppToWebviewBridge.getInstance().onNfcTransceiveCommandsSuccess.executeCallbacks(
      responseCommandsJsonArray,
      messageId,
    )
  },

  onNfcTransceiveCommandsError: (
    errorJsonPayload: string,
    messageId: string,
  ) => {
    AndroidAppToWebviewBridge.getInstance().onNfcTransceiveCommandsError.executeCallbacks(
      errorJsonPayload,
      messageId,
    )
  },

  onNfcTagDiscovered: (messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onNfcTagDiscovered.executeCallbacks(
      undefined,
      messageId,
    )
  },

  onIsNfcSupportedSuccess: (response: boolean, messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onIsNfcSupportedSuccess.executeCallbacks(
      response,
      messageId,
    )
  },

  onIsNfcEnabledSuccess: (response: boolean, messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onIsNfcEnabledSuccess.executeCallbacks(
      response,
      messageId,
    )
  },

  onNfcEnablementChange: (response: boolean, messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onNfcEnablementChange.executeCallbacks(
      response,
      messageId,
    )
  },

  onPurchasesUpdate: (payloadJson: string, messageId: string) => {
    AndroidAppToWebviewBridge.getInstance().onPurchasesUpdate.executeCallbacks(
      payloadJson,
      messageId,
    )
  },
}

type Callback<T> = (value: T, messageId: string) => void

export class CallbackManager<T> {
  private callbacks: Callback<T>[] = []

  public executeCallbacks(value: T, messageId: string) {
    this.callbacks.forEach((callback) => {
      callback(value, messageId)
    })
  }

  public addCallback(callback: Callback<T>) {
    this.callbacks.push(callback)
  }

  public removeCallback(callback: Callback<T>) {
    const index = this.callbacks.indexOf(callback)
    if (index !== -1) {
      this.callbacks.splice(index, 1)
    }
  }
}

export class AndroidAppToWebviewBridge {
  public onGetSharedPrefsJSONSuccess = new CallbackManager<string>()

  public onSetSharedPrefsStringRecordSuccess = new CallbackManager<undefined>()

  public onSetSharedPrefsNumberRecordSuccess = new CallbackManager<undefined>()

  public onSetSharedPrefsBooleanRecordSuccess = new CallbackManager<undefined>()

  public onDeleteSharedPrefsRecordSuccess = new CallbackManager<undefined>()

  public onDeleteAllSharedPrefsSuccess = new CallbackManager<undefined>()

  public onResetUMPConsentInformationSuccess = new CallbackManager<undefined>()

  public onUMPPrivacyOptionsFormDismissedSuccess =
    new CallbackManager<undefined>()

  public onUMPPrivacyOptionsFormDismissedError =
    new CallbackManager<undefined>()

  public onUMPConsentFormDismissedSuccess = new CallbackManager<undefined>()

  public onUMPConsentFormDismissedError = new CallbackManager<undefined>()

  public onGetUMPConsentInformationJSONSuccess = new CallbackManager<string>()

  public onGetPlayStoreReferrerJSONSuccess = new CallbackManager<string>()

  public onShowInAppReviewPopupSuccess = new CallbackManager<undefined>()

  public onShowInAppReviewPopupError = new CallbackManager<string>()

  public onNfcTransceiveCommandsSuccess = new CallbackManager<string>()

  public onNfcTransceiveCommandsError = new CallbackManager<string>()

  public onNfcTagDiscovered = new CallbackManager<undefined>()

  public onIsNfcSupportedSuccess = new CallbackManager<boolean>()

  public onIsNfcEnabledSuccess = new CallbackManager<boolean>()

  public onNfcEnablementChange = new CallbackManager<boolean>()

  public onPurchasesUpdate = new CallbackManager<string>()

  private static instance: AndroidAppToWebviewBridge | undefined

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance() {
    if (!AndroidAppToWebviewBridge.instance) {
      AndroidAppToWebviewBridge.instance = new AndroidAppToWebviewBridge()
    }
    return AndroidAppToWebviewBridge.instance
  }
}
