import {
  buildUrlSearchParamsWithoutUndefined,
  buildUrlWithParams,
} from 'common/shared/url-utils'

export class CoreRouteManager {
  // static getAppDashboardUrl() {
  //   return '/-app/inicio'
  // }

  // static getRootUrl() {
  //   return '/'
  // }

  static getSettingsUrl() {
    return '/ajustes'
  }

  static getAdminUrl() {
    return '/ajustes/admin'
  }

  static getDebugUrl() {
    return '/ajustes/debug'
  }

  static getAdsSettingsUrl() {
    return '/ajustes/publicidad'
  }

  static getAboutUsUrl() {
    return '/ajustes/sobre-nosotros'
  }

  static getThemeSettingsUrl() {
    return '/ajustes/tema-visual'
  }

  static getAccountSettingsUrl(postLoginRedirectUrl?: string) {
    const params = buildUrlSearchParamsWithoutUndefined({
      postLoginRedirectUrl,
    })
    return buildUrlWithParams('/ajustes/cuenta', params)
  }

  /*
  This url should start with an underscore to force service-weorker to
  load the last version of the app.
  This is important since this route will be open in the web browser
  */
  static getGoogleSignInUrlDefinition() {
    return '/_google-sign-in'
  }

  static getGoogleSignInUrl(
    loginRequesterOtClient: string,
    isExternal: boolean,
    postLoginRedirectUrl?: string,
  ) {
    const params = buildUrlSearchParamsWithoutUndefined({
      loginRequesterOtClient,
      postLoginRedirectUrl,
      target: isExternal ? 'otExternalTarget' : undefined,
    })
    return buildUrlWithParams('/_google-sign-in', params)
  }

  static getGoogleCredentialHandlerUrl(
    idToken?: string,
    loginRequesterOtClient?: string,
    postLoginRedirectUrl?: string,
  ) {
    const params = buildUrlSearchParamsWithoutUndefined({
      idToken,
      loginRequesterOtClient,
      postLoginRedirectUrl,
    })
    return buildUrlWithParams('/google-credential-handler', params)
  }

  static getGoogleManualTokenSignInUrl(postLoginRedirectUrl?: string) {
    const params = buildUrlSearchParamsWithoutUndefined({
      postLoginRedirectUrl,
    })
    return buildUrlWithParams('/ajustes/google-manual-token-sign-in', params)
  }

  static getAppUniversalPageUrl(sourcePage?: string) {
    const path = '/app'
    if (sourcePage) {
      return `${path}?sourcePage=${sourcePage}`
    }
    return path
  }

  static getPremiumAccountPageUrl() {
    return '/ajustes/tu-premium'
  }

  static getPremiumCatalogPageUrl() {
    return '/ajustes/planes-premium'
  }

  static getPremiumSubsPurchasePageUrl(
    subscriptionId: string,
    basePlanId: string,
  ) {
    return `/ajustes/adquirir-premium/${subscriptionId}/${basePlanId}`
  }

  static getPremiumOtpPurchasePageUrl(subscriptionId: string) {
    return `/ajustes/adquirir-premium-para-siempre/${subscriptionId}`
  }
}
