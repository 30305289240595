import { CheckCircleOutlinedIcon, WarningIcon } from 'common/core/images'
import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import s from './AlertCard.module.scss'

interface Props {
  text: ReactNode
  title: ReactNode
  bottomSection: ReactNode
  type: 'success' | 'error'
}

export const mainButtonClassName = s.buttonMain
export const secondaryButtonClassName = s.buttonSecondary

export const AlertCard: FC<Props> = ({ text, title, bottomSection, type }) => {
  function renderIcon() {
    if (type === 'success') {
      return (
        <CheckCircleOutlinedIcon className={classNames(s.icon, s.success)} />
      )
    }
    return <WarningIcon className={classNames(s.icon, s.error)} />
  }

  return (
    <>
      <div className={s.card}>
        <div className={s.cardInner}>
          <div className={s.body}>
            <div className={s.innerBody}>
              {renderIcon()}
              <h2 className={s.title}>{title}</h2>
              <div className={s.text}>{text}</div>
            </div>
          </div>
          {bottomSection}
        </div>
      </div>
    </>
  )
}
