import { ServerTimeManager } from '../server-time/models'
import {
  EntitlementWrapper,
  OtpPurchaseState,
} from './firestore-entitlements-client/models'

export class EntitlementCalculator {
  constructor(
    private entitlement: EntitlementWrapper,
    private serverTime: ServerTimeManager,
  ) {}

  public getEntitlement() {
    return this.entitlement
  }

  public isEntitled() {
    const isEntitled =
      this.hasGplaySubsValidEntitlement() || this.hasGplayOtpValidEntitlement()
    return isEntitled
  }

  public hasGplaySubsValidEntitlement() {
    const gplayPurchase = this.entitlement.getGplaySubsPurchase()
    if (gplayPurchase) {
      const endDate = new Date(gplayPurchase.endDate)
      const marginInMillis = 1000 * 60 * 60 * 24 * 2
      return (
        endDate.getTime() + marginInMillis >
        this.serverTime.getServerTime().getTime()
      )
    }
    return false
  }

  public hasGplayOtpValidEntitlement() {
    const gplayPurchase = this.entitlement.getGplayOtpPurchase()
    if (gplayPurchase) {
      return gplayPurchase.purchaseState === OtpPurchaseState.Purchased
    }
    return false
  }
}
