import axios from 'axios'
import { RawServerTimeRetrievalResponse } from './models'

export class ServerTimeClient {
  public async getTime() {
    const url = 'https://api.oktransportemadrid.com/time'
    // const url = 'http://192.168.1.143:8080/time'
    const response = await axios.get<RawServerTimeRetrievalResponse>(url, {
      timeout: 15 * 1000,
    })
    return response.data
  }
}
