import { commonMessagesInSpanish } from 'common/core/i18n/helpers/common-translations-es.helpers'
import { commonMessagesInEnglish } from 'common/core/i18n/helpers/common-translations-en.helpers'
import {
  transportCardMessagesInEnglish,
  transportCardMessagesInSpanish,
} from 'common/domain/nfc-transport-card/card-translations'

export const madMessagesInSpanish = {
  ...commonMessagesInSpanish,
  ...transportCardMessagesInSpanish,

  navbar_stops: 'Paradas',
  navbar_lines: 'Líneas',
  navbar_posters: 'Planos',
  navbar_card: 'Abono',
  navbar_info: 'Info',

  // eslint-disable-next-line quotes
  stop_search_field_placeholder: `Ej. 'atocha' o 'c7'`,

  stop_loading_crtm_alert:
    'Actualmente esto puede tardar hasta un minuto por problemas técnicos en el servidor del Consorcio de Transportes.',

  next_arrivals_title_buses: 'Próximos buses',
  next_arrivals_title_trains: 'Próximos trenes',

  search_stop_lines_desc_bus: 'Buses',
  search_stop_lines_desc_other: 'Líneas',

  found_line_description_cercanias: 'Línea {lineNumber} de Cercanías',
  found_line_description_metro: 'Línea {lineNumber} de Metro',
  found_line_description_light_metro: 'Línea {lineNumber} de Metro Ligero',
  found_line_description_emt: 'Bus {lineNumber} EMT',
  found_line_description_interurban: 'Bus {lineNumber} interurbano',
  found_line_description_urban: 'Bus {lineNumber} municipal',
  found_line_description_other: 'Línea {lineNumber}',

  line_description_cercanias: 'Línea de Cercanías',
  line_description_metro: 'Línea de Metro',
  line_description_light_metro: 'Línea de Metro Ligero',
  line_description_emt: 'Bus EMT',
  line_description_interurban: 'Bus interurbano',
  line_description_urban: 'Bus municipal',
  line_description_other: 'Línea de transporte',

  stop_description_interurban_bus: 'Parada de buses interurbanos',
  stop_description_emt_bus: 'Parada de buses EMT',
  stop_description_cercanias: 'Parada de Cercanías',
  stop_description_metro: 'Estación de Metro',
  stop_description_light_metro: 'Estación de Metro Ligero',
  stop_description_other: 'Parada',

  posters_metro_schematic: 'Plano de Metro',
  posters_metro: 'Mapa de Metro',
  posters_cercanias_schematic: 'Plano de Cercanías',
  posters_multi_transport: 'Mapa de EMT + Metro + Cercanías',
  posters_nocturnal_emt_buses: 'Mapa de buses EMT nocturnos',
  posters_nocturnal_suburban_buses: 'Mapa de buses interurbanos nocturnos',

  info_actionbar_title: 'Información útil',
  fares_link: 'Tarifas y billetes',
  info_lost_and_found_link: 'Objetos perdidos',
  info_christmas_schedules: 'Horarios especiales de Navidad',

  fares_cercanias_link: 'Tarifas de Cercanías',
  fares_metro_link: 'Tarifas de Metro',
  fares_light_metro_link: 'Tarifas de Metro Ligero',
  fares_emt_link: 'Tarifas de buses EMT',
  fares_interurban_link: 'Tarifas de buses interurbanos',

  related_cercanias_title: 'Relacionado con Cercanías',
  related_metro_title: 'Relacionado con Metro',
  related_light_metro_title: 'Relacionado con Metro Ligero',
  related_emt_title: 'Relacionado con EMT',
  related_interurban_title: 'Relacionado con los buses interurbanos',
}

export const madMessagesInEnglish = {
  ...commonMessagesInEnglish,
  ...transportCardMessagesInEnglish,

  navbar_stops: 'Stops',
  navbar_lines: 'Lines',
  navbar_posters: 'Maps',
  navbar_card: 'T. Pass',
  navbar_info: 'Info',

  // eslint-disable-next-line quotes
  stop_search_field_placeholder: `E.g. '6002', 'moncloa' or 'bus 51 sol'`,
  stop_loading_crtm_alert:
    'Currently, it may take one minute due to technical problems on the servers of Consorcio Regional de Transportes de Madrid.',

  next_arrivals_title_buses: 'Next buses',
  next_arrivals_title_trains: 'Next trains',

  search_stop_lines_desc_bus: 'Buses',
  search_stop_lines_desc_other: 'Lines',

  found_line_description_cercanias: 'Cercanías train line {lineNumber}',
  found_line_description_metro: 'Metro line {lineNumber}',
  found_line_description_light_metro: 'Light Rail line {lineNumber}',
  found_line_description_emt: 'EMT bus {lineNumber}',
  found_line_description_interurban: 'Suburban bus {lineNumber}',
  found_line_description_urban: 'Urban Bus {lineNumber}',
  found_line_description_other: 'Line {lineNumber}',

  line_description_cercanias: 'Cercanías train line',
  line_description_light_metro: 'Light Rail line',
  line_description_metro: 'Metro line',
  line_description_emt: 'EMT bus',
  line_description_interurban: 'Suburban bus',
  line_description_urban: 'Urban bus',
  line_description_other: 'Transport line',

  stop_description_interurban_bus: 'Suburban bus stop',
  stop_description_emt_bus: 'EMT bus stop',
  stop_description_cercanias: 'Cercanías train station',
  stop_description_metro: 'Metro station',
  stop_description_light_metro: 'Light Rail station',
  stop_description_other: 'Stop',

  posters_metro_schematic: 'Metro network map (schematic)',
  posters_metro: 'Metro network map',
  posters_cercanias_schematic: 'Cercanías network map (schematic)',
  posters_multi_transport: 'EMT buses + Metro + Cercanías network map',
  posters_nocturnal_emt_buses: 'Nocturnal EMT buses network map',
  posters_nocturnal_suburban_buses: 'Nocturnal suburban buses network map',

  info_actionbar_title: 'Useful information',
  info_lost_and_found_link: 'Lost & found',
  info_christmas_schedules: 'Transport schedules in Christmas',

  fares_link: 'Fares and tickets',
  fares_cercanias_link: 'Cercanías fares',
  fares_metro_link: 'Metro fares',
  fares_light_metro_link: 'Light Rail fares',
  fares_emt_link: 'EMT buses fares',
  fares_interurban_link: 'Suburban buses fares',

  related_cercanias_title: 'More about Cercanías',
  related_metro_title: 'More about Metro',
  related_light_metro_title: 'More about Ligh Rail',
  related_emt_title: 'More about EMT buses',
  related_interurban_title: 'More about suburban buses',
}
