import { FC, PropsWithChildren } from 'react'
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import { CoreRouteManager } from 'common/core/router/CoreRouteManager'
import { AppLayout } from './AppLayout'

interface Props {
  getAuthenticatedRoutesConfig: (intl: IntlShape) => RouteObject[]
  AddtionalWrapperComponent: FC<PropsWithChildren> | undefined
}

export const AuthenticatedRoutesManager: FC<Props> = ({
  getAuthenticatedRoutesConfig,
  AddtionalWrapperComponent,
}) => {
  const intl = useIntl()

  function getRoutes() {
    const routes: RouteObject[] = [
      {
        /* A special route that bypasses Workbox cache (because it starts with '_').
        We need to bypass the cache here because this route will be opened in a
        external browser (after a redirect from the native app), and we need
        to force the browser to get the latest version of our app. Otherwise,
        we may get a client "page not found" error, or any other discrepancy could happen. */
        path: CoreRouteManager.getGoogleSignInUrlDefinition(),
        async lazy() {
          const { GoogleSignInPage } = await import(
            'common/core/auth/components/GoogleSignInPage/GoogleSignInPage'
          )
          return { Component: GoogleSignInPage }
        },
      },
      {
        path: CoreRouteManager.getGoogleCredentialHandlerUrl(),
        async lazy() {
          const { GoogleCredentialHandlerPage } = await import(
            'common/core/auth/components/GoogleCredentialHandlerPage/GoogleCredentialHandlerPage'
          )
          return { Component: GoogleCredentialHandlerPage }
        },
      },
      {
        path: CoreRouteManager.getAppUniversalPageUrl(),
        async lazy() {
          const { AppLandingPage } = await import(
            'common/domain/app-landing/components/AppLandingPage/AppLandingPage'
          )
          return { Component: AppLandingPage }
        },
      },
      {
        path: '/',
        element: (
          <AppLayout AddtionalWrapperComponent={AddtionalWrapperComponent} />
        ),
        children: getAuthenticatedRoutesConfig(intl),
      },
    ]
    return routes
  }

  const router = createBrowserRouter(getRoutes())

  return <RouterProvider router={router} />
}
