import { useQuery } from '@tanstack/react-query'
import { DIContainer } from 'common/shared/deps-injection/DepsContainer'
import { ServerTimeDepsToken } from './deps'
import { ServerTimeManager } from './models'
import { QueryPersistenceConfig } from '../../shared/react-query/models'

const MAIN_KEY = 'serverTime'

export const retrieveServerTimeQueryPersistenceConfig: QueryPersistenceConfig =
  {
    mainQueryKey: MAIN_KEY,
    shouldPersist: () => true,
  }

export function useRetrieveServerTime() {
  return useQuery({
    queryKey: [MAIN_KEY],
    queryFn: () => {
      const { servertTimeClient } = DIContainer.get(ServerTimeDepsToken)
      return servertTimeClient.getTime()
    },
    select(data) {
      return new ServerTimeManager(data)
    },
    networkMode: 'online', // Could be 'offlineFirst' if we want to use a HTTP cache
    refetchOnWindowFocus: false, // Using false in order to decrease the number of requests
    refetchOnReconnect: false, // Using false in order to decrease the number of requests
    staleTime: 0, // Could be greater than 0 if we want to decrease the number of requests
    gcTime: 1000 * 60 * 60 * 24 * 7, // Should be equal or greater than persistence maxAge
  })
}
