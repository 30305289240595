import axios from 'axios'
import {
  StopListItem,
  StopListItemIndex,
} from 'common/domain/stops/models/stop-list-models'

export class StopListApiClient {
  constructor(
    private apiGateway: string,
    private allowedStopTypeCodes?: string[],
  ) {}

  public async getStopList(): Promise<StopListItem[]> {
    const url = this.apiGateway
    const response = await axios.get<StopListItem[]>(url, {
      timeout: 15 * 1000,
    })
    // eslint-disable-next-line prefer-destructuring
    const allowedStopTypeCodes = this.allowedStopTypeCodes
    if (allowedStopTypeCodes) {
      return response.data.filter((item) => {
        const stopTypeNumCode = item[StopListItemIndex.StopTypeNumCode]
        return allowedStopTypeCodes.includes(stopTypeNumCode.toString())
      })
    }
    return response.data
  }
}
