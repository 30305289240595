import { User } from 'firebase/auth'
import {
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore/lite'
import {
  FirestoreStopFav,
  UserDoc,
  UserDocWrapper,
} from 'common/core/firebase/firestore-models'
import { CityId } from '../firestore-models'

const converter: FirestoreDataConverter<UserDoc> = {
  toFirestore(raw) {
    return raw as DocumentData
  },
  fromFirestore(snapshot) {
    return snapshot.data() as UserDoc
  },
}

export class UserFirestoreClient {
  constructor(
    private firestore: Firestore,
    private cityId: CityId,
  ) {}

  private getUserDocRef(user: User) {
    return doc(
      this.firestore,
      'cities',
      this.cityId,
      'users',
      user.uid,
    ).withConverter(converter)
  }

  public async setStopFavs(user: User, favs: FirestoreStopFav[]) {
    const userDocRef = this.getUserDocRef(user)
    return setDoc(userDocRef, { stopFavs: favs }, { merge: true })
  }

  public async getUserDocWrapper(user: User) {
    const userDocRef = this.getUserDocRef(user)
    const userDocSnap = await getDoc(userDocRef)
    if (userDocSnap.exists()) {
      return new UserDocWrapper(userDocSnap.data())
    }
    return new UserDocWrapper()
  }
}
