const stopCodeRegEx = new RegExp(/^((\d\d?)(-|_))?(\d+)$/)

export const isStopCode = (text: string) => stopCodeRegEx.test(text)

interface AuxPart {
  prefix: string
  separator: string
}

export class StopCodeParts {
  constructor(
    private auxPart: AuxPart | undefined,
    private mainPart: string,
  ) {}

  getPrefix() {
    return this.auxPart?.prefix
  }

  getSeparator() {
    return this.auxPart?.separator
  }

  getMainPart() {
    return this.mainPart
  }

  getMainPartWithoutLeadingZeros() {
    return this.mainPart.replace(/^0+/, '')
  }

  getMainPartAsNumber() {
    return parseInt(this.mainPart, 10)
  }
}

export function getStopCodeParts(
  originalValue: string,
): StopCodeParts | undefined {
  const match = stopCodeRegEx.exec(originalValue)
  if (match) {
    return new StopCodeParts(
      {
        prefix: match[2],
        separator: match[3],
      },
      match[4],
    )
  }
  return undefined
}
