import { DIToken } from 'common/shared/deps-injection/DepsContainer'
import { IntlShape } from 'react-intl'
import { ComponentType, ReactNode } from 'react'
import { LineThemeBuilder } from '../../lines/models/line-style-models'
import { StopMapIcons } from '../../maps/models/maps-models'
import { SearchableFakeLine } from '../../search/services/FakeSearchableLinesBuilder'
import { Stop } from '../models/stop-models'
import { StopFav } from '../services/stop-favs-store/StopFavsStore'
import { StopServices } from './stop-services'

export const StopDepsToken = new DIToken<StopDeps>('StopDeps')

export interface StopDeps {
  urls: {
    getStopUrl: (stopCode: string) => string
    getStopSearchUrl: () => string
    getStopsMapUrl: (
      selectedStopCode?: string,
      stopTypeNumCode?: string,
      lineNumber?: string,
    ) => string
    getLinesUrl: () => string
    getLineUrl: (lineCode: string) => string
  }
  services: StopServices
  helpers: {
    popularStops: StopFav[]
    getStopLinesDescriptionInSearch: (stopTypeNumCode: string) => ReactNode
    composeLineDescriptionInSearchResults: (
      lineNumber: string,
      transportTypeCode: string,
    ) => ReactNode
    composeLineDescription: (transportTypeCode: string) => ReactNode
    getTransportTypeCodeFromStopTypeCode: (stopTypeCode: string) => string
    getStopTypeCodeFromTransportTypeCode: (transportTypeCode: string) => string
    getFakeSearchableLineTransportTypeCode(line: SearchableFakeLine): string
    stopSeacrhFieldPlaceholder: (intl: IntlShape) => string
    transportTypeCodesWithRealLines: string[]
    stopMapIcons: StopMapIcons
    getArrrivalsTitleText: (stop: Stop) => ReactNode
    composeStopDescription: (stopTypeCode: string) => ReactNode
    stopTypeCodesWithoutFavDeparturesAlert: string[]
    defaultMapCenter: {
      lat: number
      lng: number
    }
    autocompleteMapBounds: {
      south: number
      west: number
      north: number
      east: number
    }
    shouldShowRateAppButtonOnStopPage: (stop: Stop) => boolean
    allowRateDialogAfterAddingStopFav: (stop: Stop) => boolean
    buildLineTheme: LineThemeBuilder
    isBusStopType: (stopTypeCode: string) => boolean
    composeSelectStopSecondaryText: () => ReactNode
  }
  pageIds: {
    home: string
    stopSearch: string
    stopMap: string
    stop: string
    lines: string
    line: string
  }
  components: {
    stopIcon: ComponentType<{ stopTypeCode: string }>
    bigStopIcon: ComponentType<{ stopTypeCode: string }>
    stopRelatedContent: ComponentType<{ stop: Stop }>
    stopLoadingContent?: ComponentType<{ stopCode: string }>
    arrivalsSourcesModalContent: ComponentType
  }
}
