import {
  AppModal,
  AppModalSize,
} from 'common/core/ui/components/AppModal/AppModal'
import { useIntl } from 'react-intl'
import s from 'common/core/ui/components/modals/modals.module.scss'
import { InstantlyActive } from 'common/core/ui/components/InstantlyActive/InstantlyActive'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
  onInstallClick: () => void
}

const ExclusiveAppFeatureModal = ({
  isOpen,
  onRequestClose,
  onInstallClick,
}: Props) => {
  const intl = useIntl()

  function handleInstallClick() {
    onInstallClick()
  }

  function handleCloseClick() {
    onRequestClose()
  }

  return (
    <AppModal
      isOpen={isOpen}
      title={intl.formatMessage({ id: 'exclusive_app_feature_modal_title' })}
      shouldShowCloseButton
      shouldCloseOnOverlayClick
      onRequestClose={handleCloseClick}
      maxWidth={AppModalSize.Normal}
      renderContent={() => (
        <>
          {intl.formatMessage({ id: 'exclusive_app_feature_modal_text' })}
          <div className={s.buttonsContainer}>
            <InstantlyActive
              as="button"
              type="button"
              className={s.button}
              onClick={handleInstallClick}
            >
              {intl.formatMessage({
                id: 'exclusive_app_feature_modal_install',
              })}
            </InstantlyActive>
          </div>
        </>
      )}
    />
  )
}

export default ExclusiveAppFeatureModal
