import classNames from 'classnames'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { InstantlyActiveNavLink } from 'common/core/ui/components/InstantlyActive/InstantlyActiveNavLink'
import { FC } from 'react'
import s from './Navbar.module.scss'

const Navbar: FC = () => {
  const { navbar } = useBrandConfigContext().common

  return (
    <div className={s.root}>
      <div className={s.navbarContainer}>
        <ul className={s.menu}>
          {navbar.items.map((item) => (
            <li key={item.url}>
              <InstantlyActiveNavLink
                to={item.url}
                className={classNames({
                  [s.link]: true,
                  [s.notification]: false,
                })}
                activeClassName={s.active}
              >
                {item.icon}
                {item.text}
              </InstantlyActiveNavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Navbar
