import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import s from './ValueSelectorItem.module.scss'
import { InstantlyActive } from '../InstantlyActive/InstantlyActive'

interface Props {
  value: string | undefined
  text: ReactNode
  selectedValue: string | undefined
  onSelect: (value: string | undefined) => void
}

const ValueSelectorItem: FC<Props> = ({
  value,
  text,
  selectedValue,
  onSelect,
}) => (
  <InstantlyActive
    as="button"
    type="button"
    className={s.themeOption}
    onClick={() => onSelect(value)}
  >
    <div
      className={classNames({
        [s.selectionIndicator]: true,
        [s.selected]: value === selectedValue,
      })}
    />
    {text}
  </InstantlyActive>
)

export default ValueSelectorItem
