import { FC, PropsWithChildren } from 'react'
import useUserContext from 'common/core/auth/hooks/useUserContext'
import { EntitlementRetriever } from './EntitlementRetriever'
import { PremiumManagerContext } from './context'

export const PremiumManager: FC<PropsWithChildren> = ({ children }) => {
  const user = useUserContext()

  if (user) {
    return <EntitlementRetriever user={user}>{children}</EntitlementRetriever>
  }

  return (
    <PremiumManagerContext.Provider value={{ enabled: false }}>
      {children}
    </PremiumManagerContext.Provider>
  )
}
