type Listener = () => void

export class Listenable {
  private listeners: Listener[] = []

  public notifyListeners() {
    this.listeners.forEach((listener) => {
      listener()
    })
  }

  public addListener(listener: Listener) {
    this.listeners.push(listener)
  }

  public removeListener(listener: Listener) {
    const index = this.listeners.indexOf(listener)
    if (index !== -1) {
      this.listeners.splice(index, 1)
    }
  }
}
