import { User } from 'firebase/auth'
import { createContext, useContext } from 'react'

function createUserContext() {
  const Context = createContext<User | null | undefined>(undefined)
  Context.displayName = 'UserContext'
  return Context
}

export const UserContext = createUserContext()

export default function useUserContext() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('UserContext is not provided.')
  }
  return context
}
