import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { OtAndroidBridge } from 'common/core/native-app/ot-android-bridge'
import {
  ClientType,
  ParamAppConfig,
} from 'common/core/param-config/param-app-config-models'
import usePremiumManagerContext from 'common/core/premium/premium-manager/context'
import { AdsSystemContext } from './useAdsSystemContext'
import { AdsSystemContextModel } from './models'

function isNativeBottomAdControllable({
  clientType,
  androidClientVersionCode,
}: ParamAppConfig) {
  return (
    clientType === ClientType.Android &&
    androidClientVersionCode &&
    androidClientVersionCode >= 17
  )
}

function doesClientUseAdsense({ clientType }: ParamAppConfig) {
  return clientType === ClientType.Web
}

function isBottomAdControllable(paramAppConfig: ParamAppConfig) {
  return (
    doesClientUseAdsense(paramAppConfig) ||
    isNativeBottomAdControllable(paramAppConfig)
  )
}

function isEligibleForPrivateBottomAd() {
  return Math.random() <= 0.3
}

export const AdsSystem: FC<PropsWithChildren> = ({ children }) => {
  const {
    common: {
      localStoragePrefix,
      services: { paramAppConfig, ga4Service },
      helpers: { isAdsenseUsedForGoogleBottomAd, allowPrivateWebAds },
    },
  } = useBrandConfigContext()

  const premiumManagerContext = usePremiumManagerContext()

  const [isWebBottomAdShown, setIsWebBottomAdShown] = useState(false)
  const [isWebBottomAdPrivate, setIsWebBottomAdPrivate] = useState(false)

  const context = useMemo<AdsSystemContextModel>(
    () => ({
      isWebBottomAdShown,
      isWebBottomAdPrivate,
    }),
    [isWebBottomAdPrivate, isWebBottomAdShown],
  )

  const showGoogleAd = useCallback(() => {
    if (isAdsenseUsedForGoogleBottomAd(paramAppConfig)) {
      OtAndroidBridge.disableBottomAd()
      setIsWebBottomAdShown(true)
      setIsWebBottomAdPrivate(false)
    } else {
      OtAndroidBridge.enableBottomAd()
      setIsWebBottomAdShown(false)
      setIsWebBottomAdPrivate(false)
    }
  }, [isAdsenseUsedForGoogleBottomAd, paramAppConfig])

  const showPrivateAd = useCallback(() => {
    OtAndroidBridge.disableBottomAd()
    setIsWebBottomAdShown(true)
    setIsWebBottomAdPrivate(true)
    localStorage.setItem(
      `${localStoragePrefix}:privateAds`,
      JSON.stringify({ privateBottomAdTimestamp: Date.now() }),
    )
  }, [localStoragePrefix])

  useEffect(() => {
    if (
      premiumManagerContext.enabled &&
      premiumManagerContext.entitlementCalculator?.isEntitled()
    ) {
      OtAndroidBridge.disableBottomAd()
      return
    }
    if (
      allowPrivateWebAds(paramAppConfig) &&
      isBottomAdControllable(paramAppConfig)
    ) {
      if (isEligibleForPrivateBottomAd()) {
        showPrivateAd()
        ga4Service.sendEvent('sessionEligibleForPrivateBottomAd')
      } else {
        showGoogleAd()
        ga4Service.sendEvent('sessionNotEligibleForPrivateBottomAd')
      }
    } else {
      showGoogleAd()
    }
  }, [
    allowPrivateWebAds,
    ga4Service,
    paramAppConfig,
    premiumManagerContext,
    showGoogleAd,
    showPrivateAd,
  ])

  return (
    <AdsSystemContext.Provider value={context}>
      {children}
    </AdsSystemContext.Provider>
  )
}
