import { createContext, useContext } from 'react'
import { TransManagerContextValue } from '../models/trans-manager-models'

function createTransManagerContext() {
  const Context = createContext<TransManagerContextValue | null>(null)
  Context.displayName = 'TransManagerContext'
  return Context
}

export const TransManagerContext = createTransManagerContext()

export default function useTransManagerContext() {
  const context = useContext(TransManagerContext)
  if (!context) {
    throw new Error('TransManagerContext is not provided.')
  }
  return context
}
