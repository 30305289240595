export class AppRouteManager {
  static getAppDashboardUrl() {
    return '/-app/inicio'
  }

  static getRootUrl() {
    return '/'
  }

  static getCardUrl() {
    return '/saldo-abono-transporte'
  }

  static getNfcTransportCardUrl() {
    return '/saldo-abono-transporte-nfc'
  }
}
