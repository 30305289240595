import { FC, PropsWithChildren, useCallback } from 'react'
import { User, getAuth, signOut } from 'firebase/auth'
import { useRetrieveServerTime } from 'common/core/server-time/state-hook'
import LoadingView from 'common/core/ui/components/aux-views/LoadingView'
import {
  AlertCard,
  mainButtonClassName,
  secondaryButtonClassName,
} from 'common/core/ui/components/AlertCard/AlertCard'
import { useIntl } from 'react-intl'
import { InstantlyActive } from 'common/core/ui/components/InstantlyActive/InstantlyActive'
import useContactModalContext from 'common/core/page-fragments/contact-modal/context'
import Container from 'common/core/ui/components/Container/Container'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { EnabledPremiumContextProvider } from './EnabledPremiumContextProvider'
import { useRetrieveUserEntitlement } from '../firestore-entitlements-client/state-hook'

interface Props {
  user: User
}

export const EntitlementRetriever: FC<PropsWithChildren<Props>> = ({
  children,
  user,
}) => {
  const intl = useIntl()
  const timeQuery = useRetrieveServerTime()
  const {
    common: {
      services: { firebaseApp, userFirestoreClientWithCache, ga4Service },
    },
  } = useBrandConfigContext()

  // TODO: make a centralized logout mechanism that erases firestore cache
  async function logout() {
    const auth = getAuth(firebaseApp)
    await signOut(auth)
    userFirestoreClientWithCache.resetCache()
    ga4Service.sendEvent('entitlement:logout')
  }

  const entitlementQuery = useRetrieveUserEntitlement(user)
  const { openContactModal } = useContactModalContext()

  const handlePremiumPurchase = useCallback(() => {
    timeQuery.refetch()
    entitlementQuery.refetch()
  }, [entitlementQuery, timeQuery])

  function renderError(handleRetry: () => void) {
    return (
      <Container>
        <AlertCard
          type="error"
          title={intl.formatMessage({
            id: 'todo',
            defaultMessage: 'Vaya, algo ha ido mal',
          })}
          text={intl.formatMessage({
            id: 'todo',
            defaultMessage:
              'Si tras varios intentos este error no se resuelve, por favor, contáctanos por email.',
          })}
          bottomSection={
            <>
              <InstantlyActive
                type="button"
                as="button"
                className={mainButtonClassName}
                onClick={handleRetry}
              >
                {intl.formatMessage({
                  id: 'retry',
                  defaultMessage: 'Reintentar',
                })}
              </InstantlyActive>
              <InstantlyActive
                type="button"
                as="button"
                className={secondaryButtonClassName}
                onClick={() => logout()}
              >
                {intl.formatMessage({
                  id: 'auth_logout',
                  defaultMessage: 'Cerrar sesión',
                })}
              </InstantlyActive>

              <InstantlyActive
                type="button"
                as="button"
                className={secondaryButtonClassName}
                onClick={() => openContactModal()}
              >
                {intl.formatMessage({
                  id: 'contact_card_primary',
                  defaultMessage: 'Contacta con nosotros',
                })}
              </InstantlyActive>
            </>
          }
        />
      </Container>
    )
  }

  function renderContent() {
    if (timeQuery.isPending || entitlementQuery.isPending) {
      return <LoadingView />
    }

    if (timeQuery.data && entitlementQuery.data !== undefined) {
      return (
        <EnabledPremiumContextProvider
          entitlement={entitlementQuery.data}
          onPremiumPurchase={handlePremiumPurchase}
          serverTime={timeQuery.data}
        >
          {children}
        </EnabledPremiumContextProvider>
      )
    }

    if (timeQuery.isError && !timeQuery.data) {
      return renderError(() => timeQuery.refetch())
    }

    if (entitlementQuery.isError && !entitlementQuery.data) {
      return renderError(() => entitlementQuery.refetch())
    }

    return null
  }

  return <>{renderContent()}</>
}
