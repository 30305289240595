import axios from 'axios'
import { LineModel } from 'common/domain/lines/models/line-models'

export default class LineApiClient {
  constructor(private apiGateway: string) {}

  public async getLine(lineCode: string): Promise<LineModel> {
    const url = this.getLineUrl(lineCode)
    const response = await axios.get<LineModel>(url, {
      timeout: 15000,
    })
    return response.data
  }

  private getLineUrl(lineCode: string): string {
    return `${this.apiGateway}/v1/lines/${lineCode}`
  }
}
