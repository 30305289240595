import { FC } from 'react'
import s from './Spinner.module.scss'

export enum SpinnerSize {
  Small,
  Medium,
}
interface Props {
  size?: SpinnerSize
}

const classMapping: { [size in SpinnerSize]: string } = {
  [SpinnerSize.Small]: s.smallSize,
  [SpinnerSize.Medium]: s.mediumSize,
}

function getSizeCssClass(size: SpinnerSize) {
  const foundClass = classMapping[size]
  return foundClass || undefined
}

export const Spinner: FC<Props> = ({ size = SpinnerSize.Medium }) => (
  <div className={`${s.loader} ${getSizeCssClass(size)}`} />
)
