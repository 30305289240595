import { Navigate, RouteObject } from 'react-router-dom'
import { StopsRouteManager } from 'common/core/router/StopsRouteManager'
import { useRequiredParamOrThrow } from 'common/shared/router-utils'
import { FC } from 'react'

const RedirectToStopPage: FC = () => (
  <Navigate
    to={StopsRouteManager.getStopUrl(useRequiredParamOrThrow('stopCode'))}
    replace
  />
)

const RedirectToLinePage: FC = () => (
  <Navigate
    to={StopsRouteManager.getLineUrl(useRequiredParamOrThrow('lineCode'))}
    replace
  />
)

export const stopRoutesConfig: RouteObject[] = [
  {
    path: StopsRouteManager.getStopsUrl(),
    async lazy() {
      const { HomePageView } = await import(
        'common/domain/home/components/HomePageView/HomePageView'
      )
      return { Component: HomePageView }
    },
  },
  {
    path: StopsRouteManager.getStopsSearchUrl(),
    async lazy() {
      const { SearchPage } = await import(
        'common/domain/search/components/SearchPage/SearchPage'
      )
      return { Component: SearchPage }
    },
  },
  {
    path: StopsRouteManager.getStopsMapUrl(),
    async lazy() {
      const { StopMapPage } = await import(
        'common/domain/maps/components/StopMapPage/StopMapPage'
      )
      return { Component: StopMapPage }
    },
  },
  {
    path: '/mapa-paradas',
    element: <Navigate to={StopsRouteManager.getStopsMapUrl()} replace />,
  },
  {
    path: '/buscar-parada',
    element: <Navigate to={StopsRouteManager.getStopsSearchUrl()} replace />,
  },
  {
    path: '/parada/:stopCode',
    element: <RedirectToStopPage />,
  },
  {
    path: StopsRouteManager.getStopUrlDef(),
    async lazy() {
      const { StopPage } = await import(
        'common/domain/stops/components/StopPage'
      )
      return { Component: StopPage }
    },
  },
  {
    path: StopsRouteManager.getLinesUrl(),
    async lazy() {
      const { LinesPage } = await import(
        'common/domain/lines/components/LinesPage/LinesPage'
      )
      return { Component: LinesPage }
    },
  },
  {
    path: StopsRouteManager.getLineUrlDef(),
    async lazy() {
      const { LinePage } = await import(
        'common/domain/lines/components/LinePage/LinePage'
      )
      return { Component: LinePage }
    },
  },
  {
    path: '/linea/:lineCode',
    element: <RedirectToLinePage />,
  },
]
