import { useState } from 'react'
import { AppModal } from 'common/core/ui/components/AppModal/AppModal'
import { FormattedMessage, useIntl } from 'react-intl'
import useTransManagerContext from 'common/core/i18n/hooks/useTransManagerContext'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { ClientType } from 'common/core/param-config/param-app-config-models'
import s from 'common/core/ui/components/modals/modals.module.scss'
import { InstantlyActive } from 'common/core/ui/components/InstantlyActive/InstantlyActive'
import LocaleSelector from '../LocaleSelector/LocaleSelector'

const I18nModal = () => {
  const intl = useIntl()
  const {
    localeSelectedByUser,
    usedLocale,
    allowToShowLocaleSelectionModal,
    changeLocale,
  } = useTransManagerContext()
  const {
    common: {
      services: { ga4Service, paramAppConfig },
    },
  } = useBrandConfigContext()

  const prefLanguage = navigator.language
  const isSpanishPrefLanguage =
    prefLanguage?.split('-')[0]?.toLowerCase() === 'es'

  const shouldShowModal =
    allowToShowLocaleSelectionModal &&
    paramAppConfig.clientType !== ClientType.Web &&
    !localeSelectedByUser &&
    !isSpanishPrefLanguage

  const [selectedLocale, setSelectedLocale] = useState(usedLocale)

  function handleLocaleSelect(locale: string) {
    setSelectedLocale(locale)
  }

  function handleAccept() {
    changeLocale(selectedLocale)
    ga4Service.sendEvent('selectLocale', {
      locale: selectedLocale,
      place: 'initialI18nModal',
    })
  }

  return (
    <AppModal
      isOpen={shouldShowModal}
      shouldCloseOnOverlayClick={false}
      title={intl.formatMessage({ id: 'settings_language' })}
      shouldShowCloseButton={false}
      renderContent={() => (
        <>
          <LocaleSelector
            selectedLocale={selectedLocale}
            onLocaleSelect={handleLocaleSelect}
          />
          <div className={s.buttonsContainer}>
            <InstantlyActive
              as="button"
              type="button"
              onClick={handleAccept}
              className={s.button}
            >
              <FormattedMessage id="accept_button" />
            </InstantlyActive>
          </div>
        </>
      )}
    />
  )
}

export default I18nModal
