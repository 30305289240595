declare global {
  interface Window {
    readonly otmAndroidBridge?: OtAndroidBridgeInterface
  }
}

interface OtAndroidBridgeInterface {
  readonly changeStatusBarColor?: (hexColor: string) => void
  readonly changeNavigationBarColor?: (hexColor: string) => void
  readonly showInterstitialAd?: () => void
  readonly enableBottomAd?: () => void
  readonly disableBottomAd?: () => void
  readonly initAdmobWithConsents?: () => void
  readonly initAdmobWithoutConsents?: () => void

  readonly getSharedPrefsJSON?: (messageId: string) => void
  readonly setSharedPrefsStringRecord?: (
    key: string,
    value: string,
    messageId: string,
  ) => void
  readonly setSharedPrefsNumberRecord?: (
    key: string,
    value: number,
    messageId: string,
  ) => void
  readonly setSharedPrefsBooleanRecord?: (
    key: string,
    value: boolean,
    messageId: string,
  ) => void
  readonly deleteSharedPrefsRecord?: (key: string, messageId: string) => void
  readonly deleteAllSharedPrefs?: (messageId: string) => void

  readonly resetUMPConsentInformation?: (messageId: string) => void
  readonly showUMPPrivacyOptionsForm?: (messageId: string) => void
  readonly showUMPPrivacyOptionsFormf?: (messageId: string) => void
  readonly getUMPConsentInformationJSON?: (messageId: string) => void

  readonly getPlayStoreReferrerJSON?: (messageId: string) => void

  readonly showInAppReviewPopup?: (messageId: string) => void

  readonly nfcTransceiveCommands?: (
    jsonArray: string,
    messageId: string,
  ) => void
  readonly openNfcSystemSettings?: (messageId: string) => void
  readonly isNfcSupported?: (messageId: string) => void
  readonly isNfcEnabled?: (messageId: string) => void

  readonly startSubscriptionPurchase?: (
    payloadJson: string,
    messageId: string,
  ) => void
  readonly startOneTimeProductPurchase?: (
    payloadJson: string,
    messageId: string,
  ) => void
}

export class OtAndroidBridge {
  static changeStatusBarColor(hexColor: string) {
    window.otmAndroidBridge?.changeStatusBarColor?.(hexColor)
  }

  static changeNavigationBarColor(hexColor: string) {
    window.otmAndroidBridge?.changeNavigationBarColor?.(hexColor)
  }

  static requestInterstitialAd() {
    window.otmAndroidBridge?.showInterstitialAd?.()
  }

  static enableBottomAd() {
    window.otmAndroidBridge?.enableBottomAd?.()
  }

  static disableBottomAd() {
    window.otmAndroidBridge?.disableBottomAd?.()
  }

  static initAdmobWithConsents() {
    window.otmAndroidBridge?.initAdmobWithConsents?.()
  }

  static initAdmobWithoutConsents() {
    window.otmAndroidBridge?.initAdmobWithoutConsents?.()
  }

  static getSharedPrefsJSON(messageId: string) {
    return window.otmAndroidBridge?.getSharedPrefsJSON?.(messageId)
  }

  static setSharedPrefsStringRecord(
    key: string,
    value: string,
    messageId: string,
  ) {
    return window.otmAndroidBridge?.setSharedPrefsStringRecord?.(
      key,
      value,
      messageId,
    )
  }

  static setSharedPrefsNumberRecord(
    key: string,
    value: number,
    messageId: string,
  ) {
    return window.otmAndroidBridge?.setSharedPrefsNumberRecord?.(
      key,
      value,
      messageId,
    )
  }

  static setSharedPrefsBooleanRecord(
    key: string,
    value: boolean,
    messageId: string,
  ) {
    return window.otmAndroidBridge?.setSharedPrefsBooleanRecord?.(
      key,
      value,
      messageId,
    )
  }

  static deleteSharedPrefsRecord(param: string, messageId: string) {
    return window.otmAndroidBridge?.deleteSharedPrefsRecord?.(param, messageId)
  }

  static deleteAllSharedPrefs(messageId: string) {
    return window.otmAndroidBridge?.deleteAllSharedPrefs?.(messageId)
  }

  static resetUMPConsentInformation(messageId: string) {
    return window.otmAndroidBridge?.resetUMPConsentInformation?.(messageId)
  }

  static showUMPPrivacyOptionsForm(messageId: string) {
    return window.otmAndroidBridge?.showUMPPrivacyOptionsForm?.(messageId)
  }

  static getUMPConsentInformationJSON(messageId: string) {
    return window.otmAndroidBridge?.getUMPConsentInformationJSON?.(messageId)
  }

  static getPlayStoreReferrerJSON(messageId: string) {
    return window.otmAndroidBridge?.getPlayStoreReferrerJSON?.(messageId)
  }

  static showInAppReviewPopup(messageId: string) {
    return window.otmAndroidBridge?.showInAppReviewPopup?.(messageId)
  }

  static nfcTransceiveCommands(jsonArray: string, messageId: string) {
    return window.otmAndroidBridge?.nfcTransceiveCommands?.(
      jsonArray,
      messageId,
    )
  }

  static openNfcSystemSettings(messageId: string) {
    window.otmAndroidBridge?.openNfcSystemSettings?.(messageId)
  }

  static isNfcSupported(messageId: string) {
    window.otmAndroidBridge?.isNfcSupported?.(messageId)
  }

  static isNfcEnabled(messageId: string) {
    window.otmAndroidBridge?.isNfcEnabled?.(messageId)
  }

  static startSubscriptionPurchase(payloadJson: string, messageId: string) {
    window.otmAndroidBridge?.startSubscriptionPurchase?.(payloadJson, messageId)
  }

  static startOneTimeProductPurchase(payloadJson: string, messageId: string) {
    window.otmAndroidBridge?.startOneTimeProductPurchase?.(
      payloadJson,
      messageId,
    )
  }
}
