import { SearchableStop } from 'common/domain/search/models/search-models'
import { getStopListItemStopTypeCode } from 'common/domain/search/helpers/stop-list-helpers'
import {
  getSearchableWords,
  normalizeStopCodeForSearch,
} from 'common/domain/search/helpers/search-normalize'
import normalizeForSearch from 'normalize-for-search'
import {
  StopListItem,
  StopListItemIndex,
} from 'common/domain/stops/models/stop-list-models'

export class SearchableStopsBuilder {
  constructor(
    private stopTypeCodesSearchableByLineNumber: string[],
    private stopTypeCodesOrder: string[],
  ) {}

  private getStopNormalizedLineNumbers(stop: StopListItem): string[] {
    const stopType = stop[StopListItemIndex.StopTypeNumCode].toString()
    if (this.stopTypeCodesSearchableByLineNumber.includes(stopType)) {
      return stop[StopListItemIndex.LineNumbers].map(normalizeForSearch)
    }
    return []
  }

  private getOrderByStopTypeCode(stopTypeCode: string) {
    return this.stopTypeCodesOrder.indexOf(stopTypeCode)
  }

  private stopListSortingFunction(
    itemA: SearchableStop,
    itemB: SearchableStop,
  ): number {
    const stopAOrder = this.getOrderByStopTypeCode(
      getStopListItemStopTypeCode(itemA.item),
    )
    const stopBOrder = this.getOrderByStopTypeCode(
      getStopListItemStopTypeCode(itemB.item),
    )
    const itemAName = itemA.item[StopListItemIndex.StopName]
    const itemBName = itemB.item[StopListItemIndex.StopName]
    if (stopAOrder === stopBOrder) {
      if (itemBName < itemAName) {
        return 1
      }
      if (itemBName > itemAName) {
        return -1
      }
      return 0
    }
    return stopAOrder - stopBOrder
  }

  public getSearchableStops(stops: StopListItem[]): SearchableStop[] {
    return stops
      .map<SearchableStop>((originalStop) => ({
        item: originalStop,
        normalizedWords: getSearchableWords(
          originalStop[StopListItemIndex.StopName],
        ),
        normalizedLineNumbers: this.getStopNormalizedLineNumbers(originalStop),
        stopCodeNormalizedForSearch: normalizeStopCodeForSearch(
          originalStop[StopListItemIndex.StopCode],
        ),
      }))
      .sort(this.stopListSortingFunction.bind(this))
  }
}
