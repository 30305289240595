import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { FC } from 'react'
import s from './MadArrivalsSourcesModalContent.module.scss'

const MadArrivalsSourcesModalContent: FC = () => {
  const { contactEmail } = useBrandConfigContext().common
  return (
    <>
      <p className={s.firstParagraph}>
        Obtenemos todos los datos de las fuentes oficiales como el Consorcio de
        Transportes de Madrid. No generamos estos datos por nuestra cuenta, y
        por lo tanto dependemos completamente de las fuentes oficiales.
      </p>
      <p>
        El problema es que los servidores de estas fuentes a veces no están
        disponibles o proporcionan datos incorrectos. Esto ocurre sobre todo con
        las líneas de autobuses interurbanos (verdes) y municipales (rojos).
      </p>
      <p>
        Estos errores suelen ser temporales, pero si persisten durante varios
        días nos puedes escribir a{' '}
        <span className={s.email}>{contactEmail}</span> y nosotros nos pondremos
        en contacto con la fuente de datos correspondiente para que solucionen
        el problema.
      </p>
      <p className={s.lastParagraph}>
        Perdona por las molestias si hay algo que no funciona.
      </p>
    </>
  )
}

export default MadArrivalsSourcesModalContent
