import { FC, PropsWithChildren, useEffect, useState } from 'react'

const AD_REFRESH_INTERVAL_IN_MILLISECONDS = 40 * 1000

const AdsenseAutoRefresher: FC<PropsWithChildren> = ({ children }) => {
  const [adCounter, setAdCounter] = useState(1)

  useEffect(() => {
    setTimeout(() => {
      setAdCounter(adCounter + 1)
    }, AD_REFRESH_INTERVAL_IN_MILLISECONDS)
  }, [adCounter, setAdCounter])

  return <div key={adCounter}>{children}</div>
}

export default AdsenseAutoRefresher
