import { ReactComponent as InterurbanIcon } from 'common-mad/stops/images/stop-interurban.svg'
import { ReactComponent as EmtIcon } from 'common-mad/stops/images/stop-emt.svg'
import { ReactComponent as CercaniasIcon } from 'common-mad/stops/images/stop-cercanias.svg'
import { ReactComponent as MetroIcon } from 'common-mad/stops/images/stop-metro.svg'
import { ReactComponent as LightMetroIcon } from 'common-mad/stops/images/stop-light-metro.svg'
import { StopTypeNumCode } from 'common-mad/stops/models/stop-models'
import { FC } from 'react'

interface StopIconProps {
  stopTypeCode: string
}

const mapping: { [key in StopTypeNumCode]: () => JSX.Element } = {
  [StopTypeNumCode.INTERURBAN]: () => (
    <InterurbanIcon width="40px" height="40px" />
  ),
  [StopTypeNumCode.EMT]: () => <EmtIcon width="40px" height="40px" />,
  [StopTypeNumCode.CERCANIAS]: () => (
    <CercaniasIcon width="40px" height="40px" />
  ),
  [StopTypeNumCode.METRO]: () => <MetroIcon width="46px" />,
  [StopTypeNumCode.LIGHT_METRO]: () => <LightMetroIcon width="46px" />,
}

const MadBigStopIcon: FC<StopIconProps> = ({ stopTypeCode }) => {
  const iconRenderingFunction = mapping[stopTypeCode as StopTypeNumCode]
  if (iconRenderingFunction) {
    return iconRenderingFunction()
  }
  return <></>
}

export default MadBigStopIcon
