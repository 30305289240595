import padStart from 'lodash.padstart'
import { StopCodeWrapperInterface } from 'common/domain/stops/services/StopCodeWrapperInterface'
import { StopTypeNumCode } from '../models/stop-models'

export class StopCodeWrapper implements StopCodeWrapperInterface {
  private static removeInitialZeros(str: string): string {
    return str.replace(/^0+/, '')
  }

  private static containsOnlyDigits(str: string): boolean {
    return /^\d+$/.test(str)
  }

  constructor(private originalStopCode: string) {}

  private getCrtmStopCode(): string {
    if (StopCodeWrapper.containsOnlyDigits(this.originalStopCode)) {
      const stopCodeWithoutInitialZeros = StopCodeWrapper.removeInitialZeros(
        this.originalStopCode,
      )
      const numericStopCode = parseInt(this.originalStopCode, 10)
      if (numericStopCode < 6000) {
        return `${StopTypeNumCode.EMT}_${stopCodeWithoutInitialZeros}`
      }
      return `${StopTypeNumCode.INTERURBAN}_${padStart(stopCodeWithoutInitialZeros, 5, '0')}`
    }
    return this.originalStopCode.replace('-', '_')
  }

  public getStopTypeCode(): StopTypeNumCode {
    const crtmStopCode = this.getCrtmStopCode()
    const foundType = Object.values(StopTypeNumCode).find((stopTypeCode) =>
      crtmStopCode.startsWith(stopTypeCode),
    )
    if (!foundType) {
      throw new Error(
        `Cannot determine the stop type code from the '${this.originalStopCode}' stop code`,
      )
    }
    return foundType
  }
}
