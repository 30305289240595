import axios from 'axios'
import { CardReadingRequestPayload, RawCardReadingResponse } from './models'

export class CardReadingApiClient {
  public async sendCommand(payload?: CardReadingRequestPayload) {
    const url =
      'https://api.oktransportemadrid.com/v1/nfc-transport-card/card-reading'
    const response = await axios.post<RawCardReadingResponse>(url, payload, {
      timeout: 15 * 1000,
    })
    return response.data
  }
}
