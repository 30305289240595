import StopCodeWrapperFactoryInterface from 'common/domain/stops/services/StopCodeWrapperFactoryInterface'
import { StopCodeWrapper } from '../helpers/stop-code-wrapper'

export default class MadStopCodeWrapperFactory
  implements StopCodeWrapperFactoryInterface
{
  create(stopCode: string) {
    return new StopCodeWrapper(stopCode)
  }
}
