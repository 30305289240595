import { BuildtimeConfig } from 'common/core/buildtime-config/buildtime-config-models'
import {
  ClientType,
  ParamAppConfig,
} from 'common/core/param-config/param-app-config-models'
import { GA4Service } from './GA4Service'

export type GaIdMapping = { [key in ClientType]: string }

export class GA4ServiceBuilder {
  constructor(
    private buildtimeConfig: BuildtimeConfig,
    private appParamConfig: ParamAppConfig,
    private idMapping: GaIdMapping,
  ) {}

  public build() {
    const measurementId = this.getGAId()
    const isEnabled = this.appParamConfig.enableGA
    return GA4Service.initialize(measurementId, isEnabled, {
      android_client_version:
        this.appParamConfig.androidClientVersionCode?.toString(),
      ios_client_version: this.appParamConfig.iosClientVersion,
      npm_package_version: this.buildtimeConfig.REACT_APP_NPM_PACKAGE_VERSION,
    })
  }

  private getGAId(): string {
    const { clientType } = this.appParamConfig
    const foundId = this.idMapping[clientType]
    if (!foundId) {
      throw new Error(
        `There is no Google Analytics ID available for '${clientType}' client type`,
      )
    }
    return foundId
  }
}
