import { FC, PropsWithChildren, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useLocation } from 'react-router-dom'
import s from './EnteringAnimation.module.scss'

const EnteringAnimation: FC<PropsWithChildren> = ({ children }) => {
  const nodeRef = useRef(null)
  const location = useLocation()
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in
      appear
      key={location.pathname}
      timeout={150}
      classNames={{
        appear: s.appear,
        appearActive: s.appearActive,
      }}
    >
      <div className={s.container} ref={nodeRef}>
        {children}
      </div>
    </CSSTransition>
  )
}

export default EnteringAnimation
