import { User } from 'firebase/auth'
import {
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  doc,
  getDoc,
} from 'firebase/firestore/lite'
import { CityId } from 'common/core/firebase/firestore-models'
import { RawAppEntitlement } from './models'

const converter: FirestoreDataConverter<RawAppEntitlement> = {
  toFirestore(rawAppEntitlement) {
    return rawAppEntitlement as DocumentData
  },
  fromFirestore(snapshot) {
    return snapshot.data() as RawAppEntitlement
  },
}

export class FirestoreEntitlementsClient {
  constructor(
    private firestore: Firestore,
    private cityId: CityId,
  ) {}

  private getDocRef(user: User) {
    return doc(
      this.firestore,
      'cities',
      this.cityId,
      'entitlements',
      user.uid,
    ).withConverter(converter)
  }

  public async getEntitlement(user: User) {
    const docRef = this.getDocRef(user)
    const userDocSnap = await getDoc(docRef)
    if (userDocSnap.exists()) {
      const data = userDocSnap.data()
      return data
    }
    return null
  }
}
