import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { TransManagerContext } from '../hooks/useTransManagerContext'
import { TranslationMessagesGroups } from '../models/trans-manager-models'

interface Props {
  translationGroups: TranslationMessagesGroups
  allowToShowLocaleSelectionModal: boolean
}

function getTranslationsForLocale(
  translationGroups: TranslationMessagesGroups,
  locale: string,
) {
  const foundGroup = translationGroups[locale]
  if (!foundGroup) {
    throw new Error(`Cannot find translations for '${locale}' locale`)
  }
  return foundGroup
}

const TransManager: FC<PropsWithChildren<Props>> = ({
  children,
  translationGroups,
  allowToShowLocaleSelectionModal,
}) => {
  const {
    common: {
      services: { ga4Service, i18nPersistor },
    },
  } = useBrandConfigContext()

  const [localeSelectedByUser, setLocaleSelectedByUser] = useState(
    i18nPersistor.getLocale(),
  )

  const defaultLocale = 'es'
  const usedLocale = localeSelectedByUser || defaultLocale
  const translationsForLocale = getTranslationsForLocale(
    translationGroups,
    usedLocale,
  )

  const contextValue = useMemo(
    () => ({
      changeLocale: (locale: string) => {
        setLocaleSelectedByUser(locale)
        i18nPersistor.setLocale(locale)
      },
      localeSelectedByUser,
      usedLocale,
      allLocales: Object.keys(translationGroups),
      allowToShowLocaleSelectionModal,
    }),
    [
      localeSelectedByUser,
      usedLocale,
      i18nPersistor,
      translationGroups,
      allowToShowLocaleSelectionModal,
    ],
  )

  useEffect(() => {
    ga4Service.setUserProperties({
      locale: localeSelectedByUser || 'defaultLocale',
    })
  }, [ga4Service, localeSelectedByUser])

  return (
    <IntlProvider
      messages={translationsForLocale}
      locale={usedLocale}
      defaultLocale={defaultLocale}
    >
      <TransManagerContext.Provider value={contextValue}>
        {children}
      </TransManagerContext.Provider>
    </IntlProvider>
  )
}

export default TransManager
