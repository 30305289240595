import classNames from 'classnames'
import { ReactComponent as CloseIcon } from 'common/core/images/close-24px.svg'
import { FC, ReactNode } from 'react'
import s from './AppModal.module.scss'
import { ModalWithBackNav } from '../ModalWithBackNav/ModalWithBackNav'
import { InstantlyActive } from '../InstantlyActive/InstantlyActive'

export enum AppModalSize {
  Small = 1,
  Normal = 2,
  Large = 3,
}

const sizeClassNamesMapping: { [size in AppModalSize]: string } = {
  [AppModalSize.Small]: s.sizeSmall,
  [AppModalSize.Normal]: s.sizeNormal,
  [AppModalSize.Large]: s.sizeLarge,
}

interface Props {
  isOpen: boolean
  title: string
  maxWidth?: AppModalSize
  shouldCloseOnOverlayClick?: boolean
  shouldShowCloseButton: boolean
  onRequestClose?: () => void
  renderContent: (onInnerRequestClose: () => void) => ReactNode
}

export const AppModal: FC<Props> = (props) => {
  function getSizeClassName() {
    return props.maxWidth ? sizeClassNamesMapping[props.maxWidth] : s.sizeSmall
  }

  return (
    <ModalWithBackNav
      isOpen={props.isOpen}
      contentLabel={props.title}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick ?? true}
      onRequestClose={props.onRequestClose}
      overlayClassName={s.modalOverlay}
      className={classNames(s.modal, getSizeClassName())}
      closeTimeoutMS={150}
      renderContent={(onInnerRequestClose) => (
        <>
          <div className={s.header}>
            {props.title}
            {props.shouldShowCloseButton && (
              <InstantlyActive
                as="button"
                type="button"
                className={s.closeButton}
                onClick={() => onInnerRequestClose()}
                aria-label="Cerrar"
              >
                <CloseIcon />
              </InstantlyActive>
            )}
          </div>
          {props.renderContent(onInnerRequestClose)}
        </>
      )}
    />
  )
}
