import axios from 'axios'

export class OtGplayBillingClient {
  public async createSubscriptionPurchaseConfirmation(
    payload: SubscriptionPurchaseConfirmationCreationPayload,
  ) {
    const url =
      'https://api.oktransportemadrid.com/gplay-billing/handle-subscription-purchase'
    // const url =
    //   'http://192.168.1.143:8080/gplay-billing/handle-subscription-purchase'
    const response =
      await axios.post<SubscriptionPurchaseConfirmationCreationResponse>(
        url,
        payload,
        {
          timeout: 15 * 1000,
        },
      )
    return response.data
  }

  public async handleOtpPurchase(payload: OtpPurchaseHandlingPayload) {
    const url =
      'https://api.oktransportemadrid.com/gplay-billing/handle-one-time-product-purchase'
    // const url =
    //   'http://192.168.1.143:8080/gplay-billing/handle-one-time-product-purchase'
    const response = await axios.post<OtpPurchaseHandlingResponse>(
      url,
      payload,
      {
        timeout: 15 * 1000,
      },
    )
    return response.data
  }
}

export interface SubscriptionPurchaseConfirmationCreationPayload {
  purchaseToken: string
  packageName: string
}

export type SubscriptionPurchaseConfirmationCreationResponse = void

export interface OtpPurchaseHandlingPayload {
  purchaseToken: string
  productId: string
  packageName: string
}

export type OtpPurchaseHandlingResponse = void
