export interface FirestoreStopFav {
  name: string
  stopCode: string
}

export interface UserDoc {
  stopFavs?: FirestoreStopFav[]
}

export class UserDocWrapper {
  constructor(private userDoc?: UserDoc) {}

  public getUserDoc() {
    return this.userDoc
  }

  public getStopFavs() {
    return this.userDoc?.stopFavs || []
  }
}

export enum CityId {
  Madrid = 'madrid',
  MadridCercanias = 'madridCercanias',
  MadridBus = 'madridBus',
  MadridTransportCard = 'madridTransportCard',
  Barcelona = 'barcelona',
  Bilbao = 'bilbao',
}
