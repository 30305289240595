/* eslint-disable react/jsx-props-no-spreading */
import { FC, PropsWithChildren, useRef } from 'react'

import classNames from 'classnames'
import { NavLink, NavLinkProps } from 'react-router-dom'
import s from './InstantlyActive.module.scss'

interface Props extends NavLinkProps {
  highContrastActive?: boolean
  className?: string
  activeClassName?: string
}

export const InstantlyActiveNavLink: FC<PropsWithChildren<Props>> = ({
  highContrastActive,
  className,
  activeClassName,
  children,
  ...restProps
}) => {
  const coverElRef = useRef<HTMLDivElement>(null)

  function composeCoverClassNames(isActive: boolean) {
    return classNames({
      [s.cover]: true,
      [s.active]: isActive,
      [s.highContrastActive]: isActive && highContrastActive,
    })
  }

  function changeCoverActiveClassName(isActive: boolean) {
    if (coverElRef.current) {
      coverElRef.current.className = composeCoverClassNames(isActive)
    }
  }

  function makeActive() {
    changeCoverActiveClassName(true)
    setTimeout(() => {
      changeCoverActiveClassName(false)
    }, 0)
  }

  return (
    <>
      <NavLink
        {...restProps}
        className={({ isActive }) =>
          classNames({
            [s.root]: true,
            [className || '']: true,
            [activeClassName || '']: isActive,
          })
        }
        onPointerDown={makeActive}
      >
        <div className={s.cover} ref={coverElRef} />
        {children}
      </NavLink>
    </>
  )
}
