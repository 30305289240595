import ValueSelector from 'common/core/ui/components/ValueSelector/ValueSelector'
import ValueSelectorItem from 'common/core/ui/components/ValueSelectorItem/ValueSelectorItem'
import useTransManagerContext from 'common/core/i18n/hooks/useTransManagerContext'
import { FC } from 'react'

function getLocaleTranslation(locale: string) {
  const map: { [locale: string]: string } = {
    es: 'Castellano',
    en: 'English',
    ca: 'Catalá',
  }
  return map[locale] || locale
}

interface Props {
  selectedLocale: string
  onLocaleSelect: (locale: string) => void
}

const LocaleSelector: FC<Props> = ({ selectedLocale, onLocaleSelect }) => {
  const { allLocales } = useTransManagerContext()

  return (
    <ValueSelector>
      {allLocales.map((locale) => (
        <ValueSelectorItem
          key={locale}
          onSelect={() => onLocaleSelect(locale)}
          value={locale}
          text={getLocaleTranslation(locale)}
          selectedValue={selectedLocale}
        />
      ))}
    </ValueSelector>
  )
}

export default LocaleSelector
