import { useLocation, Link, RouteObject } from 'react-router-dom'
import { InstantlyActive } from 'common/core/ui/components/InstantlyActive/InstantlyActive'
import { CoreRouteManager } from 'common/core/router/CoreRouteManager'

function NoMatch() {
  const { pathname, search } = useLocation()
  const path = `${pathname}${search}`
  return (
    <div>
      La página &quot;<code>{path}</code>&quot; no existe. <br />
      <InstantlyActive as={Link} to="/">
        Ir a la página principal
      </InstantlyActive>
    </div>
  )
}

export const coreRoutesConfig: RouteObject[] = [
  {
    path: CoreRouteManager.getAdminUrl(),
    async lazy() {
      const { AdminPage } = await import(
        'common/domain/admin/AdminPage/AdminPage'
      )
      return { Component: AdminPage }
    },
  },
  {
    path: CoreRouteManager.getSettingsUrl(),
    async lazy() {
      const { SettingsPage } = await import(
        'common/domain/settings/components/SettingsPage/SettingsPage'
      )
      return { Component: SettingsPage }
    },
  },
  {
    path: CoreRouteManager.getDebugUrl(),
    async lazy() {
      const { DebugPage } = await import(
        'common/domain/admin/DebugPage/DebugPage'
      )
      return { Component: DebugPage }
    },
  },
  {
    path: CoreRouteManager.getAdsSettingsUrl(),
    async lazy() {
      const { AdsSettingsPage } = await import(
        'common/domain/settings/components/AdsSettingsPage/AdsSettingsPage'
      )
      return { Component: AdsSettingsPage }
    },
  },
  {
    path: CoreRouteManager.getThemeSettingsUrl(),
    async lazy() {
      const { ThemeSettingsPage } = await import(
        'common/domain/settings/components/ThemeSettingsPage/ThemeSettingsPage'
      )
      return { Component: ThemeSettingsPage }
    },
  },
  {
    path: CoreRouteManager.getAccountSettingsUrl(),
    async lazy() {
      const { AccountSettingsPage } = await import(
        'common/domain/settings/components/AccountSettingsPage/AccountSettingsPage'
      )
      return { Component: AccountSettingsPage }
    },
  },
  {
    path: CoreRouteManager.getAboutUsUrl(),
    async lazy() {
      const { AboutUsPage } = await import(
        'common/domain/settings/components/AboutUsPage/AboutUsPage'
      )
      return { Component: AboutUsPage }
    },
  },
  {
    path: CoreRouteManager.getGoogleManualTokenSignInUrl(),
    async lazy() {
      const { GoogleManualTokenSignInPage } = await import(
        'common/core/auth/components/GoogleManualTokenSignInPage/GoogleManualTokenSignInPage'
      )
      return { Component: GoogleManualTokenSignInPage }
    },
  },
  {
    path: CoreRouteManager.getPremiumAccountPageUrl(),
    async lazy() {
      const { PremiumAccountPage } = await import(
        'common/core/premium/premium-account-page/PremiumAccountPage'
      )
      return { Component: PremiumAccountPage }
    },
  },
  {
    path: CoreRouteManager.getPremiumCatalogPageUrl(),
    async lazy() {
      const { PremiumCatalogPage } = await import(
        'common/core/premium/premium-catalog-page/PremiumCatalogPage'
      )
      return { Component: PremiumCatalogPage }
    },
  },
  {
    path: CoreRouteManager.getPremiumSubsPurchasePageUrl(
      ':subscriptionId',
      ':basePlanId',
    ),
    async lazy() {
      const { PremiumSubsPurchasePage } = await import(
        'common/core/premium/subs-purchase-page/PremiumSubsPurchasePage'
      )
      return { Component: PremiumSubsPurchasePage }
    },
  },
  {
    path: CoreRouteManager.getPremiumOtpPurchasePageUrl(':productId'),
    async lazy() {
      const { PremiumOtpPurchasePage } = await import(
        'common/core/premium/otp-purchase-page/PremiumOtpPurchasePage'
      )
      return { Component: PremiumOtpPurchasePage }
    },
  },
  {
    path: '*',
    element: <NoMatch />,
  },
]
