import { createContext, useContext } from 'react'
import { AppUpgradeContextModel } from './models'

function createAppUpgradeContext() {
  const Context = createContext<AppUpgradeContextModel | null>(null)
  Context.displayName = 'AppUpgradeContext'
  return Context
}

export const AppUpgradeContext = createAppUpgradeContext()

export default function useAppUpgradeContext() {
  const context = useContext(AppUpgradeContext)
  if (!context) {
    throw new Error('AppUpgradeContext is not provided.')
  }
  return context
}
