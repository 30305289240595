import {
  BrandConfig,
  NavbarItem,
} from 'common/core/brand-config/brand-config.models'
import { FormattedMessage } from 'react-intl'
import {
  ClientType,
  ParamAppConfig,
} from 'common/core/param-config/param-app-config-models'
import { ProjectId } from 'common/core/projects/projects.models'
import { BuildtimeConfig } from 'common/core/buildtime-config/buildtime-config-models'
import { AppThemeName } from 'common/core/theme/app-theme-models'
import { StopFav } from 'common/domain/stops/services/stop-favs-store/StopFavsStore'
import { buildMadBrandConfig } from 'common-mad/brand-config/brand-config-building'
import { CityId } from 'common/core/firebase/firestore-models'
import { ReactComponent as TourIcon } from 'common/core/images/tour_FILL0_wght400_GRAD0_opsz24.svg'
import { ReactComponent as CardIcon } from 'common/core/images/card-flat.svg'
import { ReactComponent as MapIcon } from 'common/core/images/map_FILL0_wght400_GRAD0_opsz24.svg'
import { ReactComponent as SettingsIcon } from 'common/core/images/tune_FILL0_wght400_GRAD0_opsz24.svg'
import { ReactComponent as TimelineIcon } from 'common/core/images/route_FILL0_wght400_GRAD0_opsz24.svg'
import { CoreRouteManager } from 'common/core/router/CoreRouteManager'
import { StopsRouteManager } from 'common/core/router/StopsRouteManager'
import { AppRouteManager } from 'common-mad/core/services/route-manager/AppRouteManager'
import { MadInfoRouteManager } from 'common-mad/core/services/route-manager/MadInfoRouteManager'
import { appDescription } from './appDescription'
import appIcon from './app-icon.png'

const popularStops: StopFav[] = [
  {
    name: 'Atocha',
    stopCode: '5-11',
  },
  {
    name: 'Sol',
    stopCode: '4-12',
  },
  {
    name: 'Colonia Jardín',
    stopCode: '10-10',
  },
  {
    name: 'Cibeles',
    stopCode: '73',
  },
  {
    name: 'Intercambiador de Moncloa',
    stopCode: '06002',
  },
  {
    name: 'Intercambiador Av. América',
    stopCode: '12477',
  },
  {
    name: 'Intercambiador Aluche',
    stopCode: '08380',
  },
  {
    name: 'Intercambiador Plaza Castilla',
    stopCode: '17472',
  },
  {
    name: 'Intercambiador Plaza Elíptica',
    stopCode: '17042',
  },
  {
    name: 'Conde de Casal',
    stopCode: '07433',
  },
  {
    name: 'Villaverde Bajo Cruce',
    stopCode: '16729',
  },
]

function getTransportCardSectionItem(appParamConfig: ParamAppConfig) {
  if (appParamConfig.clientType === ClientType.Android) {
    return {
      url: AppRouteManager.getNfcTransportCardUrl(),
      text: <FormattedMessage id="navbar_card" />,
      icon: <CardIcon />,
    }
  }
  if (appParamConfig.clientType === ClientType.Web) {
    return {
      url: AppRouteManager.getCardUrl(),
      text: <FormattedMessage id="navbar_card" />,
      icon: <CardIcon />,
    }
  }
  return undefined
}

function buildNavbarItems(appParamConfig: ParamAppConfig): NavbarItem[] {
  const items: NavbarItem[] = [
    {
      url: StopsRouteManager.getStopsUrl(),
      text: <FormattedMessage id="navbar_stops" />,
      icon: <TourIcon />,
    },
    {
      url: StopsRouteManager.getLinesUrl(),
      text: <FormattedMessage id="navbar_lines" />,
      icon: <TimelineIcon />,
    },
    {
      url: MadInfoRouteManager.getInfoUrl(),
      text: <FormattedMessage id="navbar_info" />,
      icon: <MapIcon />,
    },
  ]
  const transportCardSectionItem = getTransportCardSectionItem(appParamConfig)
  if (transportCardSectionItem) {
    items.push(transportCardSectionItem)
  }
  items.push({
    url: CoreRouteManager.getSettingsUrl(),
    text: <FormattedMessage id="navbar_settings" />,
    icon: <SettingsIcon />,
    isSettingsUrl: true,
  })
  return items
}

export function getMadOtBrandConfig(
  buildtimeConfig: BuildtimeConfig,
  appParamConfig: ParamAppConfig,
  localStoragePrefix: string,
): BrandConfig {
  return buildMadBrandConfig({
    buildtimeConfig,
    appParamConfig,
    localStoragePrefix,
    allowedStopTypeCodes: undefined,
    allowedTransportTypeCodes: undefined,
    cityId: CityId.Madrid,
    stopListFileUrl: '/cached/stop-list-20240512.json',
    lineListFileUrl: '/cached/line-list-20240512.json',
    gaIdMapping: {
      [ClientType.Android]: 'G-3CPJ6P9M0K',
      [ClientType.IOS]: 'G-5Z7SW8YPQK',
      [ClientType.Web]: 'G-PZJC5CZJ4X',
    },
    projectId: ProjectId.OkTransitMadrid,
    homeName: 'OK Transporte Madrid',
    contactEmail: 'oktransitapp+madrid@gmail.com',
    appIconImgUrl: appIcon,
    appDescriptionText: appDescription,
    defaultColorTheme: AppThemeName.LightBlue,
    allowChatButtonOnSettingsPage: () => false,
    allowChatButtonOnHomePage: () => false,
    allowChatButtonOnStopSearchPage: () => false,
    allowChatButtonOnStopPage: () => false,
    isGplayBillingAllowed: () => true,
    popularStops,
    stopSeacrhFieldPlaceholder: (intl) =>
      intl.formatMessage({ id: 'stop_search_field_placeholder' }),
    composeSelectStopSecondaryText: () => (
      <FormattedMessage id="home_intro_two_bus_or_train" />
    ),
    navbarItems: buildNavbarItems(appParamConfig),
  })
}
