import { Listenable } from 'common/shared/Listenable'
import defaults from 'lodash.defaults'
import { UiPrefs, defaultValues } from './models'

export class UiPrefsStore {
  private cachedPrefs: UiPrefs | undefined

  public onStateChange = new Listenable()

  constructor(private localStorageKey: string) {}

  public getPrefs(): UiPrefs {
    if (!this.cachedPrefs) {
      this.cachedPrefs = this.getPrefsFromStorage()
    }
    return this.cachedPrefs
  }

  public getPrefsWithDefaults(): UiPrefs {
    return defaults({}, this.getPrefs(), defaultValues)
  }

  public mergePrefs(newPrefs: Partial<UiPrefs>) {
    const currentPrefs = this.getPrefs()
    this.setPrefs({
      ...currentPrefs,
      ...newPrefs,
    })
  }

  private setPrefs(newPrefs: UiPrefs): void {
    this.cachedPrefs = newPrefs
    this.savePrefsToStorage(newPrefs)
    this.onStateChange.notifyListeners()
  }

  private savePrefsToStorage(newPrefs: UiPrefs): void {
    this.cachedPrefs = newPrefs
    localStorage.setItem(this.localStorageKey, JSON.stringify(newPrefs))
  }

  private getPrefsFromStorage(): UiPrefs {
    const dataStr = localStorage.getItem(this.localStorageKey)
    if (dataStr) {
      return JSON.parse(dataStr)
    }
    return {}
  }
}
