import { User } from 'firebase/auth'

interface LsPayload {
  user: User | null
}

export class CachedUserStore {
  constructor(private localStorageKey: string) {}

  public getCachedUser() {
    const payload = window.localStorage.getItem(this.localStorageKey)
    if (payload) {
      return (JSON.parse(payload) as LsPayload).user
    }
    return null
  }

  public setCachedUser(user: User | null) {
    const payload: LsPayload = { user }
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(payload))
  }
}
