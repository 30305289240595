export class MadInfoRouteManager {
  static getInfoUrl() {
    return '/info'
  }

  static getFaresUrl() {
    return '/info/tarifas'
  }

  static getInterurbanFaresUrl() {
    return '/info/tarifas/buses-interurbanos'
  }

  static getEmtFaresUrl() {
    return '/info/tarifas/buses-emt'
  }

  static getCercaniasFaresUrl() {
    return '/info/tarifas/cercanias'
  }

  static getMetroFaresUrl() {
    return '/info/tarifas/metro'
  }

  static getLightMetroFaresUrl() {
    return '/info/tarifas/metro-ligero'
  }

  static getTransportPassFaresUrl() {
    return '/info/tarifas/abono-transporte'
  }

  static getLostAndFoundUrl() {
    return '/info/objetos-perdidos'
  }

  static getChristmasSchedulesUrl2324() {
    return '/info/transporte-madrid-horarios-navidad-2023-24'
  }

  static getMetroSchematicMapUrl() {
    return '/info/plano-metro'
  }

  static getMetroMapUrl() {
    return '/info/mapa-metro'
  }

  static getCercaniasMapUrl() {
    return '/info/plano-cercanias'
  }

  static getMadridTransportPosterUrl() {
    return '/info/mapa-transporte-madrid'
  }

  static getEmtNocturnalMapUrl() {
    return '/info/mapa-buses-emt-nocturnos'
  }

  static getInterurbanNocturnalMapUrl() {
    return '/info/mapa-buses-interurbanos-nocturnos'
  }
}
