import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import PrimaryOptionsItem from 'common/core/ui/components/PrimaryOptionsItem/PrimaryOptionsItem'
import emtAllPreviewImg from 'common-mad/posters/images/preview_emt_all.jpg'
import { ReactComponent as EuroIcon } from 'common/core/images/euro-24px.svg'
import { StopTypeNumCode } from 'common-mad/stops/models/stop-models'
import emtNocturnalPreviewImg from 'common-mad/posters/images/preview_plano_emt_nocturnos.jpg'
import suburbanNocturnalBusesPreviewImg from 'common-mad/posters/images/preview_mapa_interurbanos_nocturnos.jpg'
import metroSchematicPreviewImg from 'common-mad/posters/images/preview_plano_metro_esquematico.jpg'
import cercaniasSchematicPreviewImg from 'common-mad/posters/images/preview_plano_cercanias_esquematico.jpg'
import metroCartoPreviewImg from 'common-mad/posters/images/preview_plano_metro_cartografico.jpg'
import ExternalSectionTitle from 'common/core/ui/components/ExternalSectionTitle/ExternalSectionTitle'
import { Stop } from 'common/domain/stops/models/stop-models'
import { InstantlyActive } from 'common/core/ui/components/InstantlyActive/InstantlyActive'
import { MadInfoRouteManager } from 'common-mad/core/services/route-manager/MadInfoRouteManager'
import { FC } from 'react'
import s from './RelatedContent.module.scss'

export interface Props {
  stop: Stop
}

const RelatedContent: FC<Props> = ({ stop }) => {
  const intl = useIntl()

  function renderFaresLink(linkUrl: string, text: string) {
    return (
      <InstantlyActive as={Link} className={s.relatedItem} to={linkUrl}>
        <PrimaryOptionsItem
          leftChildren={
            <div className={s.iconContainer}>
              <EuroIcon />
            </div>
          }
        >
          {text}
        </PrimaryOptionsItem>
      </InstantlyActive>
    )
  }

  function renderCercaniasRelatedContent() {
    const sectionTitle = intl.formatMessage({
      id: 'related_cercanias_title',
      defaultMessage: 'Relacionado con Cercanías',
    })
    const cercaniasFaresLinkText = intl.formatMessage({
      id: 'fares_cercanias_link',
      defaultMessage: 'Tarifas de Cercanías',
    })
    return (
      <>
        <ExternalSectionTitle title={sectionTitle} />
        <div className={s.relatedCard}>
          {renderFaresLink(
            MadInfoRouteManager.getCercaniasFaresUrl(),
            cercaniasFaresLinkText,
          )}
          <InstantlyActive
            as={Link}
            className={s.relatedItem}
            to={MadInfoRouteManager.getCercaniasMapUrl()}
          >
            <PrimaryOptionsItem
              leftChildren={
                <div className={s.previewImg}>
                  <img
                    src={cercaniasSchematicPreviewImg}
                    alt={intl.formatMessage({
                      id: 'posters_cercanias_schematic',
                    })}
                  />
                </div>
              }
            >
              {intl.formatMessage({ id: 'posters_cercanias_schematic' })}
            </PrimaryOptionsItem>
          </InstantlyActive>
          {renderMultiTransporMapLink()}
        </div>
      </>
    )
  }

  function renderMetroSchematicMapLink() {
    return (
      <InstantlyActive
        as={Link}
        className={s.relatedItem}
        to={MadInfoRouteManager.getMetroSchematicMapUrl()}
      >
        <PrimaryOptionsItem
          leftChildren={
            <div className={s.previewImg}>
              <img
                src={metroSchematicPreviewImg}
                alt={intl.formatMessage({ id: 'posters_metro_schematic' })}
              />
            </div>
          }
        >
          {intl.formatMessage({ id: 'posters_metro_schematic' })}
        </PrimaryOptionsItem>
      </InstantlyActive>
    )
  }

  function renderMultiTransporMapLink() {
    return (
      <InstantlyActive
        as={Link}
        className={s.relatedItem}
        to={MadInfoRouteManager.getMadridTransportPosterUrl()}
      >
        <PrimaryOptionsItem
          leftChildren={
            <div className={s.previewImg}>
              <img
                src={emtAllPreviewImg}
                alt={intl.formatMessage({ id: 'posters_multi_transport' })}
              />
            </div>
          }
        >
          {intl.formatMessage({ id: 'posters_multi_transport' })}
        </PrimaryOptionsItem>
      </InstantlyActive>
    )
  }

  function renderMetroMapLink() {
    return (
      <InstantlyActive
        as={Link}
        className={s.relatedItem}
        to={MadInfoRouteManager.getMetroMapUrl()}
      >
        <PrimaryOptionsItem
          leftChildren={
            <div className={s.previewImg}>
              <img
                src={metroCartoPreviewImg}
                alt={intl.formatMessage({ id: 'posters_metro' })}
              />
            </div>
          }
        >
          {intl.formatMessage({ id: 'posters_metro' })}
        </PrimaryOptionsItem>
      </InstantlyActive>
    )
  }

  function renderMetroRelatedContent() {
    const sectionTitle = intl.formatMessage({
      id: 'related_metro_title',
      defaultMessage: 'Relacionado con Metro',
    })
    const metroFaresLinkText = intl.formatMessage({
      id: 'fares_metro_link',
      defaultMessage: 'Tarifas de Metro',
    })
    return (
      <>
        <ExternalSectionTitle title={sectionTitle} />
        <div className={s.relatedCard}>
          {renderFaresLink(
            MadInfoRouteManager.getMetroFaresUrl(),
            metroFaresLinkText,
          )}
          {renderMetroSchematicMapLink()}
          {renderMetroMapLink()}
          {renderMultiTransporMapLink()}
        </div>
      </>
    )
  }

  function renderLightMetroRelatedContent() {
    const sectionTitle = intl.formatMessage({
      id: 'related_light_metro_title',
      defaultMessage: 'Relacionado con Metro Ligero',
    })
    const lightMetroFaresLinkText = intl.formatMessage({
      id: 'fares_light_metro_link',
      defaultMessage: 'Tarifas de Metro Ligero',
    })
    return (
      <>
        <ExternalSectionTitle title={sectionTitle} />
        <div className={s.relatedCard}>
          {renderFaresLink(
            MadInfoRouteManager.getLightMetroFaresUrl(),
            lightMetroFaresLinkText,
          )}
          {renderMetroSchematicMapLink()}
          {renderMetroMapLink()}
        </div>
      </>
    )
  }

  function renderEmtRelatedContent() {
    const sectionTitle = intl.formatMessage({
      id: 'related_emt_title',
      defaultMessage: 'Relacionado con EMT',
    })
    const emtFaresLinkText = intl.formatMessage({
      id: 'fares_emt_link',
      defaultMessage: 'Tarifas de buses EMT',
    })
    return (
      <>
        <ExternalSectionTitle title={sectionTitle} />
        <div className={s.relatedCard}>
          {renderFaresLink(
            MadInfoRouteManager.getEmtFaresUrl(),
            emtFaresLinkText,
          )}
          {renderMultiTransporMapLink()}
          <InstantlyActive
            as={Link}
            className={s.relatedItem}
            to={MadInfoRouteManager.getEmtNocturnalMapUrl()}
          >
            <PrimaryOptionsItem
              leftChildren={
                <div className={s.previewImg}>
                  <img
                    src={emtNocturnalPreviewImg}
                    alt={intl.formatMessage({
                      id: 'posters_nocturnal_emt_buses',
                    })}
                  />
                </div>
              }
            >
              {intl.formatMessage({ id: 'posters_nocturnal_emt_buses' })}
            </PrimaryOptionsItem>
          </InstantlyActive>
        </div>
      </>
    )
  }

  function renderInterurbanRelatedContent() {
    const sectionTitle = intl.formatMessage({
      id: 'related_interurban_title',
      defaultMessage: 'Relacionado con los buses interurbanos',
    })
    const interurbanFaresLinkText = intl.formatMessage({
      id: 'fares_interurban_link',
      defaultMessage: 'Tarifas de buses interurbanos',
    })
    return (
      <>
        <ExternalSectionTitle title={sectionTitle} />
        <div className={s.relatedCard}>
          {renderFaresLink(
            MadInfoRouteManager.getInterurbanFaresUrl(),
            interurbanFaresLinkText,
          )}
          <InstantlyActive
            as={Link}
            className={s.relatedItem}
            to={MadInfoRouteManager.getInterurbanNocturnalMapUrl()}
          >
            <PrimaryOptionsItem
              leftChildren={
                <div className={s.previewImg}>
                  <img
                    src={suburbanNocturnalBusesPreviewImg}
                    alt={intl.formatMessage({
                      id: 'posters_nocturnal_suburban_buses',
                    })}
                  />
                </div>
              }
            >
              {intl.formatMessage({ id: 'posters_nocturnal_suburban_buses' })}
            </PrimaryOptionsItem>
          </InstantlyActive>
        </div>
      </>
    )
  }

  if (stop.stopTypeCode === StopTypeNumCode.EMT) {
    return renderEmtRelatedContent()
  }
  if (stop.stopTypeCode === StopTypeNumCode.INTERURBAN) {
    return renderInterurbanRelatedContent()
  }
  if (stop.stopTypeCode === StopTypeNumCode.METRO) {
    return renderMetroRelatedContent()
  }
  if (stop.stopTypeCode === StopTypeNumCode.LIGHT_METRO) {
    return renderLightMetroRelatedContent()
  }
  if (stop.stopTypeCode === StopTypeNumCode.CERCANIAS) {
    return renderCercaniasRelatedContent()
  }
  return <></>
}

export default RelatedContent
