import pickBy from 'lodash.pickby'

export function buildUrlSearchParamsWithoutUndefined(params: {
  [key: string]: string | undefined
}) {
  const paramsWithoutUndefined = pickBy(
    params,
    (value) => value !== undefined,
  ) as {
    [key: string]: string
  }
  return new URLSearchParams(paramsWithoutUndefined)
}

export function buildUrlWithParams(mainPart: string, params: URLSearchParams) {
  const search = params.toString()
  return search ? `${mainPart}?${search}` : mainPart
}
