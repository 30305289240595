import { DIToken } from 'common/shared/deps-injection/DepsContainer'
import { OtGplayBillingClient } from './ot-gplay-billing-client/ot-gplay-billing-client'
import { FirestoreEntitlementsClient } from './firestore-entitlements-client/FirestoreEntitlementsClient'

export const PremiumDepsToken = new DIToken<PremiumDeps>('PremiumDeps')

export interface PremiumDeps {
  otGplayBillingClient: OtGplayBillingClient
  firestoreEntitlementsClient: FirestoreEntitlementsClient
}
