export class AppUsageTracker {
  constructor(private localStorageKey: string) {}

  public getFirstAppLaunchTimestamp(): number | undefined {
    const payload = this.getLocalStoragePayload()
    return payload?.firstAppLaunchTimestamp
  }

  public setFirstAppLaunchTimestamp(timestamp: number): void {
    const payload = this.getLocalStoragePayload()
    this.setLocalStoragePayload({
      ...payload,
      firstAppLaunchTimestamp: timestamp,
    })
  }

  public registerStopPageVisit() {
    const counter = this.getStopPageVisitCounter()
    if (counter) {
      this.setStopPageVisitCounter(counter + 1)
    } else {
      this.setStopPageVisitCounter(1)
    }
  }

  public setAuthSettingsShown() {
    const payload = this.getLocalStoragePayload()
    this.setLocalStoragePayload({
      ...payload,
      authSettingsShown: true,
    })
  }

  public authSettingsShown() {
    const payload = this.getLocalStoragePayload()
    return payload?.authSettingsShown
  }

  public registerLoginUsed() {
    const payload = this.getLocalStoragePayload()
    this.setLocalStoragePayload({
      ...payload,
      loginUsed: true,
    })
  }

  public loginUsed() {
    const payload = this.getLocalStoragePayload()
    return payload?.loginUsed
  }

  private getStopPageVisitCounter() {
    const payload = this.getLocalStoragePayload()
    return payload?.stopPageVisitCounter
  }

  private setStopPageVisitCounter(count: number): void {
    const payload = this.getLocalStoragePayload()
    this.setLocalStoragePayload({
      ...payload,
      stopPageVisitCounter: count,
    })
  }

  private getLocalStoragePayload(): LSPayload | undefined {
    const payloadStr = window.localStorage.getItem(this.localStorageKey)
    if (!payloadStr) {
      return undefined
    }
    return JSON.parse(payloadStr) as LSPayload
  }

  private setLocalStoragePayload(payload: LSPayload) {
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(payload))
  }
}

interface LSPayload {
  firstAppLaunchTimestamp?: number
  stopPageVisitCounter?: number
  loginUsed?: boolean
  authSettingsShown?: boolean
}
