import { FC, PropsWithChildren, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import s from './ExternalSectionTitle.module.scss'

interface Props {
  title: ReactNode
  showNewLabel?: boolean
}

const ExternalSectionTitle: FC<PropsWithChildren<Props>> = ({
  showNewLabel = false,
  title,
  children,
}) => (
  <div className={s.container}>
    <div className={s.titleContainer}>
      <h2 className={s.title}>{title}</h2>
      {showNewLabel && (
        <span className={s.newLabel}>
          <FormattedMessage id="new" />
        </span>
      )}
    </div>
    {children && <div>{children}</div>}
  </div>
)

export default ExternalSectionTitle
