import { FC, PropsWithChildren, useCallback, useState } from 'react'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import ContactModal from '../ContactModal/ContactModal'
import { ContactModalContext } from './context'

export const ContactModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    common: {
      services: { ga4Service },
    },
  } = useBrandConfigContext()

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const onContactModalOpen = useCallback(() => {
    setIsModalVisible(true)
    ga4Service.sendEvent('contact:openDialog')
  }, [ga4Service])

  return (
    <>
      <ContactModalContext.Provider
        value={{ openContactModal: onContactModalOpen }}
      >
        {children}
      </ContactModalContext.Provider>
      <ContactModal
        isOpen={isModalVisible}
        onRequestClose={() => setIsModalVisible(false)}
      />
    </>
  )
}
