import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import useAdsSystemContext from 'common/core/ads/ads-system/useAdsSystemContext'
import { FC } from 'react'
import s from './WebBottomAd.module.scss'
import AdsenseBannerDisplayAd from '../AdsenseBannerDisplayAd/AdsenseBannerDisplayAd'
import AdsenseAutoRefresher from '../AdsenseAutoRefresher/AdsenseAutoRefresher'

const WebBottomAd: FC = () => {
  const { isWebBottomAdShown, isWebBottomAdPrivate } = useAdsSystemContext()
  const { bottomBannerAdSlot } = useBrandConfigContext().common

  function renderAdType() {
    if (isWebBottomAdPrivate) {
      // return <EpbWebBannerAd />
      return <></>
    }
    return (
      <AdsenseAutoRefresher>
        <AdsenseBannerDisplayAd adsenseSlot={bottomBannerAdSlot} />
      </AdsenseAutoRefresher>
    )
  }

  function renderAd() {
    return (
      <div className={s.fixedBottomAd}>
        <div className={s.fixedBottomAdCentered}>{renderAdType()}</div>
      </div>
    )
  }

  return <>{isWebBottomAdShown && renderAd()}</>
}

export default WebBottomAd
