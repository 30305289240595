import { BuildtimeConfig } from './buildtime-config-models'

export default class BuildtimeConfigBuilder {
  private static getEnvVariableOrThrow(variableName: string): string {
    const envVariableValue = process.env[variableName]
    if (!envVariableValue) {
      throw new Error(
        `Required environment variable '${variableName}' is not set or it's empty`,
      )
    }
    return envVariableValue
  }

  // eslint-disable-next-line class-methods-use-this
  public getBuildtimeConfig(): BuildtimeConfig {
    return {
      NODE_ENV: process.env.NODE_ENV,
      REACT_APP_NPM_PACKAGE_VERSION:
        BuildtimeConfigBuilder.getEnvVariableOrThrow(
          'REACT_APP_NPM_PACKAGE_VERSION',
        ),
      REACT_APP_BUILD_TIME: BuildtimeConfigBuilder.getEnvVariableOrThrow(
        'REACT_APP_BUILD_TIME',
      ),
    }
  }
}
