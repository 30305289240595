import cercaniasStopIcon from 'common-mad/maps/images/stop_cercanias.svg'
import metroStopIcon from 'common-mad/maps/images/stop_metro.svg'
import lightMetroStopIcon from 'common-mad/maps/images/stop_light_metro.svg'
import interurbanStopIcon from 'common-mad/maps/images/stop_interurban.svg'
import interurbaNocturnalStopIcon from 'common-mad/maps/images/stop_interurban_nocturnal.svg'
import emtStopIcon from 'common-mad/maps/images/stop_emt.svg'
import emtNocturnalStopIcon from 'common-mad/maps/images/stop_emt_nocturnal.svg'
import { StopMapIcons } from 'common/domain/maps/models/maps-models'
import { StopTypeNumCode } from 'common-mad/stops/models/stop-models'

export const stopMapIcons: StopMapIcons = {
  normal: {
    [StopTypeNumCode.METRO]: {
      url: metroStopIcon,
      width: 39,
      height: 58,
    },
    [StopTypeNumCode.LIGHT_METRO]: {
      url: lightMetroStopIcon,
      width: 39,
      height: 58,
    },
    [StopTypeNumCode.CERCANIAS]: {
      url: cercaniasStopIcon,
      width: 28,
      height: 56,
    },
    [StopTypeNumCode.EMT]: {
      url: emtStopIcon,
      width: 28,
      height: 56,
    },
    [StopTypeNumCode.INTERURBAN]: {
      url: interurbanStopIcon,
      width: 28,
      height: 56,
    },
  },
  nocturnal: {
    [StopTypeNumCode.EMT]: {
      url: emtNocturnalStopIcon,
      width: 28,
      height: 79,
    },
    [StopTypeNumCode.INTERURBAN]: {
      url: interurbaNocturnalStopIcon,
      width: 28,
      height: 79,
    },
  },
}
