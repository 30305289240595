export class AdsUsagePersistor {
  constructor(private localStorageKey: string) {}

  public wasPeriodInterstitialAdShown(): boolean {
    const payload = this.getLocalStoragePayload()
    return !!payload?.wasPeriodInterstitialAdShown
  }

  public setPeriodInterstitialAdShown(value: boolean): void {
    const payload = this.getLocalStoragePayload()
    this.setLocalStoragePayload({
      ...payload,
      wasPeriodInterstitialAdShown: value,
    })
  }

  public getPeriodStartTimestamp(): number | undefined {
    const payload = this.getLocalStoragePayload()
    return payload?.periodStartTimestamp
  }

  public setPeriodStartTimestamp(timestamp: number): void {
    const payload = this.getLocalStoragePayload()
    this.setLocalStoragePayload({
      ...payload,
      periodStartTimestamp: timestamp,
    })
  }

  private getLocalStoragePayload(): LSPayload | undefined {
    const payloadStr = window.localStorage.getItem(this.localStorageKey)
    if (!payloadStr) {
      return undefined
    }
    return JSON.parse(payloadStr) as LSPayload
  }

  private setLocalStoragePayload(payload: LSPayload) {
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(payload))
  }
}

interface LSPayload {
  periodStartTimestamp?: number
  wasPeriodInterstitialAdShown?: boolean
}
