export interface RawServerTimeRetrievalResponse {
  currentTime: string
}

export class ServerTimeManager {
  constructor(private raw: RawServerTimeRetrievalResponse) {}

  public getServerTime() {
    return new Date(this.raw.currentTime)
  }
}
