import { FC, PropsWithChildren } from 'react'
import s from './aux-views.module.scss'

const FullHeightContainer: FC<PropsWithChildren> = (props) => (
  <div className={s.fullHeighContainer}>
    <div className={s.fullHeighContainerContent}>{props.children}</div>
  </div>
)

export default FullHeightContainer
