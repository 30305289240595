import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged, User } from 'firebase/auth'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { UserContext } from 'common/core/auth/hooks/useUserContext'

const AuthStarter: FC<PropsWithChildren> = ({ children }) => {
  const {
    common: {
      services: { firebaseApp, ga4Service, cachedUserStore },
    },
  } = useBrandConfigContext()

  const [loggedInUser, setLoggedInUser] = useState<User | null>(null)

  useEffect(() => {
    const auth = getAuth(firebaseApp)
    return onAuthStateChanged(auth, async (user) => {
      cachedUserStore.setCachedUser(user)
      setLoggedInUser(user)
      ga4Service.setUserProperties({ is_logged_in: (!!user).toString() })
    })
  }, [cachedUserStore, firebaseApp, ga4Service])

  const finalUser = loggedInUser || cachedUserStore.getCachedUser()

  return (
    <UserContext.Provider value={finalUser}>{children}</UserContext.Provider>
  )
}

export default AuthStarter
