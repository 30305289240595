import { FC, useState } from 'react'
import copy from 'copy-to-clipboard'
import { ReactComponent as CopyIcon } from 'common/core/images/copy.svg'
import { ReactComponent as SuccessTickIcon } from 'common/core/images/success-tick.svg'
import { useIntl } from 'react-intl'
import s from './CopyToClipboardButton.module.scss'
import { InstantlyActive } from '../InstantlyActive/InstantlyActive'

interface Props {
  textToShare: string
  className?: string
  onCopy?: () => void
}

const CopyToClipboardButton: FC<Props> = ({
  textToShare,
  className,
  onCopy,
}) => {
  const intl = useIntl()

  const [showConfirmation, setShowConfirmation] = useState(false)

  function handleButtonClick() {
    copy(textToShare)
    setShowConfirmation(true)
    setTimeout(() => {
      setShowConfirmation(false)
    }, 2000)
    onCopy?.()
  }

  const initialContent = (
    <>
      <CopyIcon />
      {intl.formatMessage({ id: 'common_copy_clipboard' })}
    </>
  )
  const confirmationContent = (
    <>
      <SuccessTickIcon className={s.successTickIcon} />
      {intl.formatMessage({
        id: 'common_copy_clipboard_success',
        defaultMessage: 'Copiado',
      })}
    </>
  )

  return (
    <InstantlyActive
      as="button"
      type="button"
      className={className}
      onClick={handleButtonClick}
    >
      {showConfirmation ? confirmationContent : initialContent}
    </InstantlyActive>
  )
}

export default CopyToClipboardButton
