export const commonMessagesInSpanish = {
  share_app_text: 'Prueba la app {appName}',

  home_intro_one: 'Selecciona una parada',
  home_intro_two_train: 'para consultar cuándo llega a ella tu tren',
  home_intro_two_bus: 'para consultar cuándo llega a ella tu bus',
  home_intro_two_bus_or_train:
    'para consultar cuándo llega a ella tu bus o tren',
  home_stop_search_button_one: 'Buscar parada',
  home_stop_search_button_two: 'por nº o nombre',
  home_map_search_button_one: 'Buscar parada',
  home_map_search_button_two: 'en el mapa',
  home_popular_stops: 'Paradas populares',

  navbar_settings: 'Ajustes',
  settings_language: 'Idioma',

  ads_advertisement_title: 'Publicidad',
  settings_ads: 'Ajustes de publicidad',
  ads_interstitial_ad_alert_title: 'Pronto se mostrará un anuncio',
  ads_interstitial_ad_alert_button: 'Ver ahora',
  ads_interstital_ad_alert_setting_title: 'Mostrar aviso de anuncio',
  ads_interstital_ad_alert_setting_text:
    '<p>Si tienes esta opción habilitada te mostraremos un botón para ver el anuncio a pantalla completa pendiente.</p><p>De esta forma puedes ver el anuncio cuando tú quieras y evitar que aparezca automáticamente.</p><p>Ten en cuenta que si no usas el botón para ver el anuncio, este aparecerá automáticamente después de 20 minutos.</p>',
  ads_interstital_ads_title: 'Anuncios a pantalla completa',

  common_hide: 'Ocultar',

  accept_button: 'Aceptar',

  stops_favs_title: 'Paradas guardadas',
  stops_no_favorites: `No tienes paradas guardadas.
    Usa el botón "Guardar" en una parada
    para que <nobr>aparezca aquí.</nobr>`,
  stop_favs_error:
    'No se ha podido obtener las paradas guardadas. Por favor, reintenta de nuevo.',
  stops_favs_sort_start: 'Ordenar',
  stops_favs_sort_finish: 'Finalizar',

  chat_card_secondary: '¿Necesitas ayuda?',
  chat_card_primary: 'Escríbenos por chat',

  contact_card_secondary: '¿Necesitas ayuda?',
  contact_card_primary: 'Contacta con nosotros',

  share_app_modal_title: 'Compartir la app',
  share_modal_text_one: 'Envía este enlace a tus amigos:',
  share_modal_whatsapp: 'Enviar por WhatsApp',
  share_modal_telegram: 'Enviar por Telegram',

  install_card_secondary_one: 'Nuestra app tiene más ',
  install_card_secondary_two: 'funcionalidades que la web',
  install_card_primary: 'Instalar gratis',

  rate_app_card_secondary: 'Échanos una mano',
  rate_app_card_primary: 'Puntúa la app',

  sync_reminder_secondary: 'Guarda tus paradas en la nube',
  sync_reminder_primary: 'Iniciar sesión',

  action_bar_install_app: 'Instalar app',
  modal_close_btn: 'Cerrar',

  no_internet_error: 'Vaya, parece que no tienes conexión a internet',

  search_tab_panel_by_text: 'Por nº, nombre o línea',
  search_tab_panel_on_map: 'En el mapa',

  stop_search_action_bar_title: 'Buscar parada',
  stop_search_loading: 'Cargando las paradas',
  stop_search_error: `Se he producido un error al recuperar el listado de paradas.
    Por favor, inténtalo de nuevo.`,
  stop_search_by_code: 'Consultar parada por número',
  search_found_stops_title: 'Paradas encontradas',
  search_found_lines_title: 'Líneas encontradas',
  line_search_show_more: 'Mostrar {numberOfItems} líneas más',
  search_show_more_stops: 'Mostrar {numberOfItems} paradas más',
  search_stops_not_found:
    'No se han encontrado paradas con la consulta "{searchQuery}"',
  search_stop_by_unknown_code: 'Consultar parada número {stopCode}',
  search_box_delete_text: 'Borrar',

  recently_viewed_stops: 'Paradas vistas recientemente',
  recently_viewed_stops_delete_all: 'Borrar',

  stop_map_action_bar_title: 'Buscar parada',
  stop_map_loading: 'Cargando el mapa',
  stop_map_error:
    'Se he producido un error al recuperar los datos del mapa. Por favor, inténtalo de nuevo.',
  map_search_places_field_placeholder: 'Buscar lugares en el mapa',
  stop_map_infowindow_lines: 'Líneas: ',
  stop_map_infowindow_go_to_stop: 'Consultar parada',

  stop_action_bar_title: 'Parada',
  stop_action_bar_search_link_title: 'Buscar parada',
  stop_refresh_button: 'Actualizar',
  stop_loading: 'Cargando la parada',
  stop_error:
    'Se ha producido un error al obtener los datos de la parada. Por favor, inténtalo de nuevo.',
  stop_fav_arrivals_hint:
    'CONSEJO: Para que una línea aparezca siempre arriba, usa su botón "Fijar línea arriba"',
  stop_error_arrivals_sources_btn: '¿Por qué no hay datos?',
  stop_zone: 'Zona',
  stop_has_wheelchair_boarding: 'Accesible',
  stop_actions_remove_fav: 'Guardada',
  stop_actions_add_fav: 'Guardar',
  stop_actions_rename: 'Renombrar',
  stop_location: 'Ubicación de la parada',
  stop_actions_show_on_map: 'Ver parada en el mapa',
  stop_actions_map_secondary: 'Encuentra otras paradas cerca',
  stop_departure_direction: 'A',
  stop_departure_platform: 'Vía {platform}',
  stop_departure_distance: '{distance} m',
  stop_departure_no_data: 'actualmente sin datos',
  stop_departure_actions_fav_arrival_add: 'Fijar línea arriba',
  stop_departure_actions_fav_arrival_remove: 'No fijar línea arriba',
  stop_departure_actions_go_to_line: 'Consultar línea',
  stop_departure_actions_show_on_map: 'Ver línea en el mapa',
  arrivals_sources_modal_title: 'Cómo obtenemos los datos',
  got_it_button: 'Entendido',
  stop_fav_modal_title: 'Guardar parada',
  stop_fav_modal_select_name: 'Elige un nombre:',
  stop_fav_modal_cancel: 'Cancelar',
  stop_fav_modal_save: 'Guardar',
  stop_fav_rename_modal_title: 'Renombrar parada',
  stop_fav_rename_modal_select_name: 'Elige un nuevo nombre:',
  stop_fav_rename_modal_cancel: 'Cancelar',
  stop_fav_rename_modal_save: 'Guardar',
  stop_remove_fav_modal_title: 'Eliminar parada guardada',
  stop_remove_fav_modal_text: '¿Eliminar esta parada de las paradas guardadas?',
  stop_remove_fav_cancel: 'Cancelar',
  stop_remove_fav_ok: 'Eliminar',
  stop_share_title: 'Compartir parada',
  stop_share_text: 'Consulta las llegadas en la parada "{stopName}"',
  stop_message_stop_saved: 'Parada guardada',
  stop_message_stop_removed: 'Parada eliminada',
  stop_message_stop_renamed: 'Parada renombrada',

  line_search_action_bar_title: 'Líneas',
  line_search_loading: 'Cargando las líneas',
  line_search_error:
    'Se he producido un error al recuperar el listado de líneas. Por favor, inténtalo de nuevo.',
  line_search_field_placeholder: 'Buscar línea por número o nombre',

  line_action_bar_title: 'Línea',
  line_action_bar_search_link_title: 'Buscar línea',
  line_loading: 'Cargando la línea',
  line_error:
    'Se ha producido un error al obtener los datos de la línea. Por favor, inténtalo de nuevo.',
  line_show_on_map_btn: 'Ver línea en el mapa',
  line_schedule_btn: 'Horario a {destination}',
  line_stops_title: 'Paradas de la línea',
  line_schedule_modal_title: 'Horario',
  line_tab: 'A {destination}',

  posters_action_bar_title: 'Planos y mapas',
  posters_available_offline: 'Disponible sin internet',
  posters_not_available_offline: 'Necesita conexión a internet',

  settings_action_bar_title: 'Ajustes de la app',
  settings_theme: 'Tema visual',
  settings_theme_use_system: 'Según el tema del sistema',
  settings_more_stuff: 'Más cosas',
  secondary_actions_rate_app: 'Puntúa la app',
  secondary_actions_use_chat: 'Escríbenos por chat',
  secondary_actions_share_app: 'Comparte la app',
  secondary_actions_contact: 'Contacta con nosotros',
  settings_our_projects: 'Nuestras otras apps',
  settings_ui: 'Interfaz gráfica',
  settings_privacy: 'Privacidad',
  settings_consents: 'Consentimiento de datos personales',
  settings_show_popular_stops: 'Mostrar las paradas populares',

  contact_modal_title: 'Contacta con nosotros',
  contact_modal_text:
    'Escríbenos a este correo para cualquier duda o sugerencia:',

  retry: 'Reintentar',
  new: 'Nuevo',
  common_copy_clipboard: 'Copiar en el portapapeles',
  common_copy_clipboard_success: 'Copiado',
  exclusive_app_feature_modal_title: 'Sólo en nuestra app',
  exclusive_app_feature_modal_text:
    'Esta funcionalidad solo está disponible en nuestra app. Descárgala gratis ahora y disfruta de esta y muchas más ventajas.',
  exclusive_app_feature_modal_install: 'Instalar la app gratis',

  auth_user_account: 'Cuenta de usuario',
  auth_logged_out_intro:
    'Inicia sesión para guardar tus paradas favoritas en la nube. Así no las perderás si cambias de móvil.',
  auth_login_with_google: 'Iniciar sesión con Google',
  auth_logged_in_with: 'Sesión iniciada con: ',
  auth_logged_in_description:
    'Tus paradas favoritas se guardan en la nube de forma segura.',
  auth_logout: 'Cerrar sesión',
  auth_logout_modal_title: 'Cerrar sesión',
  auth_logout_modal_text: '¿Estás seguro de que quieres cerrar sesión?',
  auth_logout_modal_accept: 'Cerrar sesión',
  auth_logout_modal_cancel: 'Cancelar',
  auth_message_session_closed: 'La sesión se ha cerrado',
  auth_login_with_code: 'Entrar con código',
  auth_error_no_id_token:
    'Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo.',
  auth_token_presenter_title: 'Copiar código',
  auth_token_presenter_text_1: 'Copia el siguiente código en el portapapeles:',
  auth_token_presenter_text_2:
    'Vuelve a la app y ve a la sección de ajustes y pulsa el botón "Entrar con código"',
  auth_login_with_code_error:
    'Ha ocurrido un error al iniciar sesión con el código. Por favor, inténtalo de nuevo.',
  auth_login_with_code_paste: 'Pega el código en el campo de texto:',
  auth_login_with_code_submit: 'Entrar',

  sync_title: 'Sincronizar favoritos',
  sync_intro:
    'Tienes paradas guardadas tanto en tu cuenta de usuario como en este dispositivo. ¿Qué quieres hacer con las paradas del dispositivo?',
  sync_btn_upload_local_stop_favs:
    'Añadir las paradas del dispositivo a mi cuenta de usuario',
  sync_btn_delete_local_stop_favs:
    'Eliminar las paradas del dispositivo y usar solo las de mi cuenta de usuario',
}
