export enum ProjectId {
  OkTransitMadrid = 'OkTransitMadrid',
  OkTransitMadridCercanias = 'OkTransitMadridCercanias',
  OkTransitMadridBus = 'OkTransitMadridBus',
  OkTransitMadridTransportCard = 'OkTransitMadridTransportCard',
  OkTransitBarcelona = 'OkTransitBarcelona',
  OkTransitBilbao = 'OkTransitBilbao',
}

export interface Project {
  projectId: ProjectId
  androidPackageName: string
  getName: () => string
  websiteUrl: string
  universalUrl: string
  androidPlayStoreUrl: (utmSource: string) => string
  androidHuaweiStoreUrl: string
  iosStoreUrl: string
  shouldShow?: boolean
}
