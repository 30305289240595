enum OtIosBridgeAction {
  ShowInterstitialAd = 'showInterstitialAd',
}

declare global {
  interface Window {
    readonly webkit?: {
      readonly messageHandlers?: {
        readonly otIosBridge: {
          readonly postMessage?: (payload: {
            action: OtIosBridgeAction
          }) => void
        }
      }
    }
  }
}

export class OtIosBridge {
  private static postMessage(action: OtIosBridgeAction) {
    window.webkit?.messageHandlers?.otIosBridge?.postMessage?.({ action })
  }

  static requestInterstitialAd() {
    OtIosBridge.postMessage(OtIosBridgeAction.ShowInterstitialAd)
  }
}
