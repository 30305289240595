import { FC, PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'
import EnteringAnimation from 'common/core/ui/components/EnteringAnimation/EnteringAnimation'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { InterstitalAdAlert } from 'common/core/ads/interstitial-ad-alert/InterstitalAdAlert'
import useAdsSystemContext from 'common/core/ads/ads-system/useAdsSystemContext'
import s from './PageLayout.module.scss'

interface Props {
  actionbar: ReactNode
  withNavbar?: boolean
  allowWebBottomAd?: boolean
  allowAdAlert?: boolean
  allowAdsSettingsButton?: boolean
}

const PageLayout: FC<PropsWithChildren<Props>> = ({
  actionbar,
  children,
  withNavbar = true,
  allowWebBottomAd = true,
  allowAdAlert = true,
  allowAdsSettingsButton = true,
}) => {
  const {
    common: {
      services: { clientAppInfo },
    },
  } = useBrandConfigContext()

  const isFixedActionbar = !clientAppInfo.isWebClient()
  const { isWebBottomAdShown } = useAdsSystemContext()

  return (
    <div className={s.root}>
      <div
        className={classNames({
          [s.actionBar]: true,
          [s.fixedActionBar]: isFixedActionbar,
        })}
      >
        {actionbar}
      </div>
      <EnteringAnimation>
        <div
          className={classNames({
            [s.content]: true,
            [s.withNavbar]: withNavbar,
            [s.withFixedActionbar]: isFixedActionbar,
            [s.withBottomAd]: allowWebBottomAd && isWebBottomAdShown,
          })}
        >
          <InterstitalAdAlert
            allowAdAlert={allowAdAlert}
            allowAdsSettingsButton={allowAdsSettingsButton}
          />
          {children}
        </div>
      </EnteringAnimation>
    </div>
  )
}

export default PageLayout
