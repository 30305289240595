import { createContext, useContext } from 'react'

export interface ContactModalContextModel {
  openContactModal: () => void
}

function createContactModalContext() {
  const Context = createContext<ContactModalContextModel | null>(null)
  Context.displayName = 'ContactModalContext'
  return Context
}

export const ContactModalContext = createContactModalContext()

export default function useContactModalContext() {
  const context = useContext(ContactModalContext)
  if (!context) {
    throw new Error('ContactModalContext is not provided.')
  }
  return context
}
