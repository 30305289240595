/* eslint-disable react/jsx-props-no-spreading */
import { FC, ReactNode, useEffect } from 'react'

import ReactModal from 'react-modal'

interface ModalProps extends ReactModal.Props {
  onRequestClose?: () => void
  renderContent: (onRequestClose: () => void) => ReactNode
}

export const ModalWithBackNav: FC<ModalProps> = (props) => {
  const { isOpen, onRequestClose } = props

  useEffect(() => {
    if (isOpen) {
      window.history.pushState('popup-open', '', '')
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      window.addEventListener(
        'popstate',
        () => {
          onRequestClose?.()
        },
        { once: true },
      )
    }
  }, [isOpen, onRequestClose])

  function handleCloseRequest() {
    // window.history.back() will trigger popstate event
    window.history.back()
  }

  return (
    <ReactModal {...props} onRequestClose={handleCloseRequest}>
      {props.renderContent(handleCloseRequest)}
    </ReactModal>
  )
}
