import { ClientType } from 'common/core/param-config/param-app-config-models'
import { MadAdsenseSlot } from 'common-mad/ads/models/mad-ads-models'
import { PageId } from 'common-mad/core/models/app-models'
import { AdsenseDisplayAdWrapperType } from 'common/core/ads/components/adsense-components-types'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'

const stopPageAdsMapping: { [clientType in ClientType]: MadAdsenseSlot } = {
  [ClientType.Web]: MadAdsenseSlot.WebStopPage,
  [ClientType.Android]: MadAdsenseSlot.AndroidStopPage,
  [ClientType.IOS]: MadAdsenseSlot.IosStopPage,
}

const linePageAdsMapping: { [clientType in ClientType]: MadAdsenseSlot } = {
  [ClientType.Web]: MadAdsenseSlot.WebLinePage,
  [ClientType.Android]: MadAdsenseSlot.AndroidLinePage,
  [ClientType.IOS]: MadAdsenseSlot.IosLinePage,
}

const MadDisplayAdWrapper: AdsenseDisplayAdWrapperType = ({
  pageId,
  renderChildrenAd,
}) => {
  const { paramAppConfig } = useBrandConfigContext().common.services

  function getAdsenseSlot(clientType: ClientType): MadAdsenseSlot | undefined {
    if (pageId === PageId.StopPage) {
      return stopPageAdsMapping[clientType]
    }
    if (pageId === PageId.LinePage) {
      return linePageAdsMapping[clientType]
    }
    return undefined
  }

  function renderAd(clientType: ClientType) {
    const adsenseSlot = getAdsenseSlot(clientType)
    if (!adsenseSlot) {
      return <></>
    }
    return <>{renderChildrenAd(adsenseSlot)}</>
  }

  if ([ClientType.Web].includes(paramAppConfig.clientType)) {
    return renderAd(paramAppConfig.clientType)
  }
  return <></>
}

export default MadDisplayAdWrapper
