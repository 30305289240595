import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { OtAndroidBridge } from 'common/core/native-app/ot-android-bridge'
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AppThemeContext } from './useThemeContext'
import { AppThemeName } from './app-theme-models'
import useUiPrefsContext from '../ui-prefs/ui-prefs-context'

const nativeAppColorMapping: { [appThemeName in AppThemeName]: string } = {
  [AppThemeName.LightGreen]: '#469463',
  [AppThemeName.LightBlue]: '#59458b',
  [AppThemeName.LightCyan]: '#0e8cf3',
  [AppThemeName.LightOrange]: '#fa7600',
  [AppThemeName.LightFuchsia]: '#e85193',
  [AppThemeName.LightPurple]: '#7433b2',
  [AppThemeName.LightBlueB]: '#3e59aa',
  [AppThemeName.LightGreenB]: '#1f7549',
  [AppThemeName.LightBlack]: 'black',
  [AppThemeName.LightRed]: '#e85151',
  [AppThemeName.DarkBlack]: '#1a1a1a',
  [AppThemeName.LightWhite]: '#000000',
  [AppThemeName.LightPink]: '#ffb3c2',
  [AppThemeName.DarkBlue]: '#1f1b2e',
  [AppThemeName.DarkRed]: '#320303',
  [AppThemeName.DarkPink]: '#381325',
  [AppThemeName.DarkPurple]: '#341338',
  [AppThemeName.DarkGreen]: '#092421',
  [AppThemeName.DarkBlueB]: '#112630',
  [AppThemeName.DarkBrown]: '#322623',
}

function setAndroidStatusBarColorForTheme(themeName: AppThemeName) {
  const foundColor = nativeAppColorMapping[themeName]
  if (foundColor) {
    OtAndroidBridge.changeStatusBarColor(foundColor)
  }
}

const AppThemeManager: FC<PropsWithChildren> = ({ children }) => {
  const {
    common: {
      defaultColorTheme,
      services: { ga4Service },
    },
  } = useBrandConfigContext()
  const { uiPrefs, mergeUiPrefs } = useUiPrefsContext()
  const [previewThemeName, setPreviewThemeName] = useState<AppThemeName>()
  const previewTimeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const calculateThemeNameToUse = useCallback(() => {
    if (previewThemeName) {
      return previewThemeName
    }
    if (uiPrefs.preferredThemeName) {
      return uiPrefs.preferredThemeName
    }
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      return AppThemeName.DarkBlack
    }
    return defaultColorTheme
  }, [previewThemeName, uiPrefs.preferredThemeName, defaultColorTheme])

  const changeTheme = useCallback(
    (themeName: AppThemeName | undefined) => {
      setPreviewThemeName(undefined)
      mergeUiPrefs({ preferredThemeName: themeName })
      ga4Service.sendEvent('colorThemeChange', {
        theme: themeName || 'defaultTheme',
      })
    },
    [ga4Service, mergeUiPrefs],
  )

  const applyTheme = useCallback((themeNameToUse: AppThemeName) => {
    document.body.className = `globalTheme${themeNameToUse}`
    setAndroidStatusBarColorForTheme(themeNameToUse)
  }, [])

  const previewTheme = useCallback((themeName: AppThemeName) => {
    if (previewTimeoutRef.current) {
      clearTimeout(previewTimeoutRef.current)
    }
    setPreviewThemeName(themeName)
    previewTimeoutRef.current = setTimeout(() => {
      setPreviewThemeName(undefined)
    }, 20000)
  }, [])

  const contextValue = useMemo(
    () => ({
      themeNameToUse: calculateThemeNameToUse(),
      themeNameSelectedByUser: previewThemeName ?? uiPrefs.preferredThemeName,
      changeTheme,
      previewTheme,
    }),
    [
      calculateThemeNameToUse,
      changeTheme,
      previewTheme,
      previewThemeName,
      uiPrefs.preferredThemeName,
    ],
  )

  useEffect(() => {
    applyTheme(contextValue.themeNameToUse)
    ga4Service.setUserProperties({
      theme: contextValue.themeNameSelectedByUser || 'defaultTheme',
    })
  }, [applyTheme, contextValue, ga4Service])

  return (
    <AppThemeContext.Provider value={contextValue}>
      {children}
    </AppThemeContext.Provider>
  )
}

export default AppThemeManager
