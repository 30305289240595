import { User } from 'firebase/auth'
import UserFirestoreClientWithCache from 'common/core/firebase/user-firestore-client-with-cache/UserFirestoreClientWithCache'
import StopFavsStore, { StopFav } from '../stop-favs-store/StopFavsStore'

export default class StopFavsService {
  constructor(
    private stopFavsStore: StopFavsStore,
    private userFirestoreClientWithCache: UserFirestoreClientWithCache,
  ) {}

  public async getStopFavs(user: User | null) {
    if (user) {
      const userDocWrapper =
        await this.userFirestoreClientWithCache.getUserDocWrapper(user)
      return userDocWrapper.getStopFavs()
    }
    return this.stopFavsStore.getStopFavs()
  }

  public setStopFavs(user: User | null, stopFavs: StopFav[]) {
    if (user) {
      return this.userFirestoreClientWithCache.setStopFavs(user, stopFavs)
    }
    return this.stopFavsStore.setStopFavs(stopFavs)
  }
}
