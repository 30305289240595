export enum StopTypeNumCode {
  INTERURBAN = '8',
  EMT = '6',
  CERCANIAS = '5',
  METRO = '4',
  LIGHT_METRO = '10',
}

export enum TransportTypeNumCode {
  INTERURBAN = '8',
  URBAN = '9',
  EMT = '6',
  CERCANIAS = '5',
  METRO = '4',
  LIGHT_METRO = '10',
}
