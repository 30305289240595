export class CommunicatorResponseError extends Error {
  constructor(
    private errorCode: string,
    private errorMessage?: string,
  ) {
    const reason = `${errorCode} | ${errorMessage}`
    super(reason)
    Object.setPrototypeOf(this, CommunicatorResponseError.prototype)
  }

  public getErrorCode(): string {
    return this.errorCode
  }

  public static getErrorCode<T extends string = string>(error: unknown) {
    return error instanceof CommunicatorResponseError
      ? (error.errorCode as T)
      : undefined
  }

  public static getErrorMessage<T extends string = string>(error: unknown) {
    return error instanceof CommunicatorResponseError
      ? (error.errorMessage as T)
      : undefined
  }
}
