import { commonMessagesInSpanish } from 'common/core/i18n/helpers/common-translations-es.helpers'
import { commonMessagesInEnglish } from 'common/core/i18n/helpers/common-translations-en.helpers'
import {
  transportCardMessagesInEnglish,
  transportCardMessagesInSpanish,
} from 'common/domain/nfc-transport-card/card-translations'
import {
  madMessagesInEnglish,
  madMessagesInSpanish,
} from 'common-mad/brand-config/mad-app-translations'

const messagesInSpanish = {
  ...commonMessagesInSpanish,
  ...transportCardMessagesInSpanish,
  ...madMessagesInSpanish,

  stop_search_field_placeholder: "Ej. '6002', 'moncloa' o 'bus 51 sol'",
}

const messagesInEnglish = {
  ...commonMessagesInEnglish,
  ...transportCardMessagesInEnglish,
  ...madMessagesInEnglish,

  stop_search_field_placeholder: "E.g. '6002', 'moncloa' or 'bus 51 sol'",
}

export const madTranslationGroups = {
  es: messagesInSpanish,
  en: messagesInEnglish,
}
