export const appDescription = (
  <>
    <p>
      La app de OK Transporte Madrid es <b>totalmente gratuita</b>, ocupa muy
      poco y ofrece todas estas funcionalidades:
    </p>
    <ul>
      <li>
        Consulta <b>cuándo llega tu bus, Cercanías, Metro o Metro Ligero</b> a
        cualquier parada de la Comunidad de Madrid.
      </li>
      <li>
        Guarda en favoritos las paradas que usas habitualmente para tenerlas
        siempre a mano.
      </li>
      <li>
        Puedes poner tus propios nombres a las paradas, guardadas y reordenarlas
        como quieras.
      </li>
      <li>Consulta las paradas que tiene una línea de autobús.</li>
      <li>
        Consulta los <b>horarios programados</b> de los autobuses.
      </li>
      <li>
        <b>Consulta planos y mapas</b> de Metro, Cercanías y buses EMT
        (disponibles incluso sin internet).
      </li>
      <li>
        Consulta <b>cuándo caduca tu tarjeta de transporte</b> mensual (abono).
      </li>
      <li>
        Consulta <b>los billetes y tarifas</b> del transporte de Madrid.
      </li>
      <li>
        Puedes buscar paradas por su nombre o número, o seleccionarlas en el
        mapa.
      </li>
      <li>
        Disponible el varios temas visuales, incluyendo varios temas oscuros.
      </li>
    </ul>
  </>
)
