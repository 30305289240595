import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { CSSTransition } from 'react-transition-group'
import { useIntl } from 'react-intl'
import useBrandConfigContext from 'common/core/brand-config/useBrandConfigContext'
import { ReactComponent as InfoIcon } from 'common/core/images/tune_FILL0_wght400_GRAD0_opsz24.svg'
import { Link } from 'react-router-dom'
import { InstantlyActive } from 'common/core/ui/components/InstantlyActive/InstantlyActive'
import Container from 'common/core/ui/components/Container/Container'
import useUiPrefsContext from 'common/core/ui-prefs/ui-prefs-context'
import usePremiumManagerContext from 'common/core/premium/premium-manager/context'
import { useShouldSuggestPremium } from 'common/core/premium/useShouldSuggestPremium'
import classNames from 'classnames'
import { CoreRouteManager } from 'common/core/router/CoreRouteManager'
import s from './InterstitalAdAlert.module.scss'

interface Props {
  allowAdAlert?: boolean
  allowAdsSettingsButton?: boolean
}

export const InterstitalAdAlert: FC<PropsWithChildren<Props>> = ({
  allowAdAlert = true,
  allowAdsSettingsButton = true,
}) => {
  const {
    common: {
      isGplayBillingAllowed,
      services: { clientAppInfo, interstitialAdsManager, ga4Service },
    },
  } = useBrandConfigContext()
  const shouldSuggestPremium = useShouldSuggestPremium()
  const intl = useIntl()
  const { uiPrefs } = useUiPrefsContext()
  const premiumManagerContext = usePremiumManagerContext()
  const nodeRef = useRef(null)

  const [isInterstitialAdPending, setIsInterstitialAdPending] = useState(
    interstitialAdsManager.isPeriodAdPending(),
  )

  const handleInterstitialAdStateChange = useCallback(() => {
    setIsInterstitialAdPending(interstitialAdsManager.isPeriodAdPending())
  }, [interstitialAdsManager])

  useEffect(() => {
    interstitialAdsManager.onStateChange.addListener(
      handleInterstitialAdStateChange,
    )
    return () => {
      interstitialAdsManager.onStateChange.removeListener(
        handleInterstitialAdStateChange,
      )
    }
  }, [handleInterstitialAdStateChange, interstitialAdsManager])

  function handleShowAdClick() {
    interstitialAdsManager.showInterstitialAdForcibly()
    ga4Service.sendEvent('showInterstitialAdForcibly')
  }

  function handleGetPremiumClick() {
    ga4Service.sendEvent('getPremiumClick:alert')
  }

  function doesPremiumEntitlementExist() {
    return (
      premiumManagerContext.enabled &&
      premiumManagerContext.entitlementCalculator?.isEntitled()
    )
  }

  const isGplayBillingAvailable =
    clientAppInfo.areGplayBillingSubsSupported() && isGplayBillingAllowed()

  const shouldShowTopAlert =
    !isGplayBillingAvailable &&
    clientAppInfo.areInterstitialAdsSupported() &&
    allowAdAlert &&
    !!uiPrefs.allowInterstitialAdAlert &&
    !doesPremiumEntitlementExist() &&
    !!isInterstitialAdPending

  function renderAdsSettingsButton() {
    return (
      <InstantlyActive
        as={Link}
        to={CoreRouteManager.getAdsSettingsUrl()}
        className={s.helpButton}
        onClick={() => ga4Service.sendEvent('adsSettingsButton')}
      >
        <InfoIcon className={s.icon} />
      </InstantlyActive>
    )
  }

  function renderGetPremiumButton() {
    return (
      <InstantlyActive
        as={Link}
        to={CoreRouteManager.getPremiumCatalogPageUrl()}
        className={classNames(s.alertButton, s.premium)}
        onClick={handleGetPremiumClick}
      >
        {intl.formatMessage({
          id: 'TODO',
          defaultMessage: 'Quitar publi',
        })}
      </InstantlyActive>
    )
  }

  function renderShowAdButton() {
    return (
      <InstantlyActive
        as="button"
        type="button"
        className={s.alertButton}
        onClick={handleShowAdClick}
      >
        {intl.formatMessage({
          id: 'ads_interstitial_ad_alert_button',
          defaultMessage: 'Ver ahora',
        })}
      </InstantlyActive>
    )
  }

  function renderShowAdLink() {
    return (
      <InstantlyActive
        className={s.alertLink}
        as="button"
        type="button"
        onClick={handleShowAdClick}
      >
        {intl.formatMessage({
          id: 'ads_interstitial_ad_alert_button',
          defaultMessage: 'Ver ahora',
        })}
      </InstantlyActive>
    )
  }

  function renderTopAlert() {
    return (
      <div className={s.topAlertBg} ref={nodeRef}>
        <div className={s.topAlert}>
          <Container>
            <div className={s.topAlertContainer}>
              <div className={s.textSection}>
                <div>
                  <div className={s.text}>
                    {intl.formatMessage({
                      id: 'ads_interstitial_ad_alert_title',
                      defaultMessage: 'Pronto se mostrará un anuncio',
                    })}{' '}
                  </div>
                  {shouldSuggestPremium && renderShowAdLink()}
                </div>
              </div>
              <div className={s.buttonsSection}>
                {allowAdsSettingsButton && renderAdsSettingsButton()}
                {shouldSuggestPremium
                  ? renderGetPremiumButton()
                  : renderShowAdButton()}
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  }

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={shouldShowTopAlert}
      timeout={300}
      unmountOnExit
      classNames={{
        appear: s.appear,
        appearActive: s.appearActive,
        enter: s.enter,
        enterActive: s.enterActive,
        exit: s.exit,
        exitActive: s.exitActive,
      }}
    >
      {renderTopAlert()}
    </CSSTransition>
  )
}
