export enum AppThemeName {
  LightGreen = 'V2_LightGreen',
  LightBlue = 'V2_LightBlue',
  LightCyan = 'V2_LightCyan',
  LightOrange = 'V2_LightOrange',
  LightFuchsia = 'V2_LightFuchsia',
  LightPurple = 'V2_LightPurple',
  LightBlueB = 'V2_LightBlueB',
  LightGreenB = 'V2_LightGreenB',
  LightBlack = 'V2_LightBlack',
  LightRed = 'V2_LightRed',
  LightWhite = 'V2_LightWhite',
  LightPink = 'V2_LightPink',
  DarkBlue = 'V2_DarkBlue',
  DarkRed = 'V2_DarkRed',
  DarkPink = 'V2_DarkPink',
  DarkPurple = 'V2_DarkPurple',
  DarkBlack = 'V2_DarkBlack',
  DarkGreen = 'V2_DarkGreen',
  DarkBlueB = 'V2_DarkBlueB',
  DarkBrown = 'V2_DarkBrown',
}

export interface AppThemeContextModel {
  themeNameToUse: AppThemeName
  themeNameSelectedByUser: AppThemeName | undefined
  changeTheme: (themeName: AppThemeName | undefined) => void
  previewTheme: (themeName: AppThemeName) => void
}
